import React from 'react';
import {  KadaFilter } from 'kada-component-library';

const LevelFilter = props => {

  const {
    levelFilter,
    setLevelFilter,
    // loadHistory,
  } = props;


  const onChangeLevelFilter = (value) => {
    if (value === levelFilter) return;
    setLevelFilter(value);
  }

  return (
    <KadaFilter
      placeholder='All'
      value={levelFilter}
      isHidePlaceholderMenuItem
      isHideSearch
      valueDisplayPrefix='Assessment Level: '
      options={
        [
          { text: 'All', value: 'All' },
          // { text: 'Table', value: 'TABLE' },
          // { text: 'Column', value: 'COLUMN' }
        ]
      }
      onChange={e => {
        onChangeLevelFilter(e.detail.value)
      }}
    />
  )
}

export default LevelFilter;
