import axiosCerebrum from "../../../../axios-cerebrum";
import axiosSolr from "../../../../axios-solr";
import theme from "../../../../theme";
import { getDispFields, getLabelPlural, mapSearchObjectName, sendMessage } from "../../../../utilities";
import { generateTags } from "../../KLineageFramework/utils";
import { getBackgroundColour, getLineageRelation, graphPalette, lineageObjectsMap } from "../utils";

export const nodeWidth = 300;
export const bodyButtonHeight  = 42;
export const nodeOneLineHeight = 42;
// const nodeTwoLineHeight = 56;
const buttonNodeHeight = 116;

export const groupInitHeight = 62;
export const groupWidth = 300;

export const checkIsReferencePlaceHolder = l => {
  if(!l)return false;
  return l.isReferecenPlaceholder
}

export const getCerebrumLabel = (data) => {
  if(!data)return undefined;
  if(data.type)return data.type;
  if(!data.object_type_txt)return undefined;
  return mapSearchObjectName(data.object_type_txt, data.code_type_txt)
}

export const thirdLevelObjects = ['COLUMN','DATASET_FIELD','CONTENT_CHILD','SHEET']

const dedupeList = list => {
  let newList = [];
  list.forEach(el=>{
    if(!newList.find(n=>n.id===el.id))newList.push(el)
  })
  return newList;
}

export const enrichData = async ({list, currentList=[], abortController, target, direction, isRoot}) => {
  if(!list || list.length===0)return []
  let ids = list.map(el=>el.id);
  if(ids.length===0)return [];
  let newList = [];
  await axiosSolr
    .post(
      `/solr/search/select`,{
        query:"*",
        filter:`id:(${ids.join(' OR ')})`,
        limit:ids.length
      },{
        signal:abortController?.signal
      }
    )
    .then(response=>{
      newList = list.map(l=>({
        ...l,
        shouldConstructLink:true,
        ...(response.data.response.docs.find(r=>r.id===l.id)||{}),
        nodeOneLineHeight,
      }))
    })
    .catch(error=>{
      console.log(error)
    })

  if(newList.find(n=>n.reference_txt==='YES') && !isRoot){
    let referenceItems = []
    newList.forEach(el=>{
      if(el.reference_txt==='YES' && !referenceItems.find(r=>r.id===el.id))referenceItems.push(el)
    })
    newList.push({
      id:`${target.id}_${direction}_reference`,
      isReferecenPlaceholder:true,
      referenceCount:referenceItems.length,
      shouldConstructLink:true,
    })
  }

  let loadChildLineageList = newList.filter(n=>['DATASET','DATASET_TABLE'].includes(n.object_type_txt) && n.child_object_count_srt && !checkHasLineage({node:{...n,data:{obj:n}}, direction, inactive:n.active_txt==='NO'}) )

  if(loadChildLineageList.length>0){
    let facet = {};
    loadChildLineageList.forEach(el=>{
      let lineageFilter;
      if(direction==='upstream'){
        lineageFilter = `active_lineage_inc_reference_srt:(UPSTREAM_DOWNSTREAM OR UPSTREAM_ONLY)`
      }else{
        lineageFilter = `active_lineage_inc_reference_srt:(UPSTREAM_DOWNSTREAM OR DOWNSTREAM_ONLY)`
      }
      facet[el.id] = {
        type:'query',
        q:`hierarchy_parent_id_srt:${el.id} AND active_srt:YES AND ${lineageFilter}`,
      }
    })
    await axiosSolr
      .post(
        `/solr/search/select`,{params:{
          q:"*",
          fq:"*",
          rows:0,
          'json.facet':facet
        }}
      )
      .then(response=>{
        Object.keys(response.data.facets).forEach(k=>{
          let count = response.data.facets[k]?.count || 0;
          if(count>0){
            if(newList.find(n=>n.id===k))newList.find(n=>n.id===k).shouldLoadChildLineage = true;
          }
        })
      })
      .catch(error=>{
        console.log(error)
      })
  }

  let thirdLevelListItems = newList.filter(n=>thirdLevelObjects.includes(getCerebrumLabel(n)))
  let thirdLevelItemsMissingHierarchy = thirdLevelListItems.filter(n=>!n.hierarchy_parent_id_txt)
  let thirdLevelItemIDs = thirdLevelListItems.filter(el=>el.hierarchy_parent_id_txt).map(el=>el.hierarchy_parent_id_txt.toLowerCase()).filter(id=>![...list,currentList].find(c=>c.id===id));
  let additionalParentList = []
  if(thirdLevelItemIDs.length!==0){
    await axiosSolr
      .post(
        `/solr/search/select`,{
          query:"*",
          filter:`id:(${thirdLevelItemIDs.join(' OR ')})`,
          limit:thirdLevelItemIDs.length
        }
      )
      .then(response=>{
        thirdLevelItemIDs.forEach(id=>{
          // if has detail, add detail
          if(response.data.response.docs.find(r=>r.id===id)){
            additionalParentList.push(response.data.response.docs.find(r=>r.id===id))
          }else{
            additionalParentList.push({
              id,
              name_txt:'Unknown',
              source_txt:'Unknown',
              source_type_txt:'Unknown',
              object_type_txt:'Unknown',
            })
          }
        })
      })
      .catch(error=>{
        console.log(error)
      })
  }
  thirdLevelItemsMissingHierarchy.forEach(el=>{
    el.hierarchy_parent_id_txt = el.id + '_parent_placeholder';
    additionalParentList.push({
      id:el.hierarchy_parent_id_txt,
      name_txt:'Unknown',
      source_txt:'Unknown',
      source_type_txt:'Unknown',
      object_type_txt:'Unknown',
    })
  })
  return [...additionalParentList,...newList];
}

export const generateGroups = async ({newList, currentNodes=[], onNodeClick, onNodeExpandClick, direction, inactive}) => {
  let detail = {};
  let ids = [];
  let sourceIds = [];
  let groups = [];
  let idsMissing= [];
  newList.forEach(el=>{
    if(el.parentNode && !ids.includes(el.parentNode) && ![...newList,...currentNodes].find(n=>n.id===el.parentNode)){
      if(['DATABASE','TOOL'].includes(getCerebrumLabel(el.data.obj)) || el.parentNode.split('_')[0].length<=4 ){
        if(!sourceIds.includes(el.parentNode))sourceIds.push(el.parentNode)
      }
      else if(getCerebrumLabel(el.data.obj)==='FILE'){
        if(!groups.find(g=>g.id===el.parentNode)){
          groups.push({
            id:el.parentNode,
            type:'KGroup',
            width:groupWidth,
            height:groupInitHeight,
            data:{
              label: 'Files',
              borderColour: graphPalette.FILE,
              subTitle: '',
              icon:'storage',
            },
          })
        }
      }
      else{
        ids.push(el.parentNode)
      }
    }
  })

  if(sourceIds.length!==0){
    for(let i=0; i<sourceIds.length; i++){
      let id = sourceIds[i].split('_')[0]
      let db;
      if(!id){
        idsMissing.push(sourceIds[i]);
        continue;
      }
      if(detail[id]){
        db = detail[id]
      }else{
        await axiosCerebrum
          .get(`/api/sources/${id}`)
          .then(response=>{
            db = response.data
            detail[id] = db;
          })
      }
      if(!db){
        idsMissing.push(sourceIds[i]);
        continue;
      }
      groups.push({
        id:sourceIds[i],
        type:'KGroup',
        width:groupWidth,
        height:groupInitHeight,
        data:{
          label: db.name.toUpperCase(),
          borderColour: db.source_template.type==='DATABASE'?graphPalette.TABLE:graphPalette.CONTENT,
          subTitle: '',
          onTitleClick: ()=>window.open(`/profile/source/${db.host_ids[0]}`,'_blank'),
          icon:db.source_template.type,
          rightIcon:db.source_template.name,
          rightIconColour:db.source_template.type==='DATABASE'?graphPalette.TABLE:graphPalette.CONTENT,
        },
      })
    }
  }

  if(ids.length!==0){
    await axiosSolr
      .post(
        `/solr/search/select`,
        {
          query:'*',
          filter:`id:(${ids.map(el=>el.split('_')[0].toLowerCase()).join(' OR ')})`,
          limit:ids.length
        }
      )
      .then(response=>{
        ids.forEach(i=>{
          let detail = response.data.response.docs.find(r=>r.id===i.split('_')[0])
          if(!detail){
            idsMissing.push(i);
            return;
          }
          let g = {
            id:i,
            type:'KGroup',
            width:groupWidth,
            height:groupInitHeight,
            direction,
            data:{
              obj:detail,
              label: getDispFields(detail, 'dispTitle'),
              subTitle: getDispFields(detail, 'dispSubtitle'),
              subTitleStyled: getDispFields(detail, 'dispSubtitle', true),
              icon: detail.object_type_txt,
              borderColour: detail.reference_txt==='YES'?theme.palette.primaryText.light:graphPalette[getCerebrumLabel(detail)],
              iconColour:detail.reference_txt==='YES'?theme.palette.primaryText.light:graphPalette[getCerebrumLabel(detail)],
              rightIcon:detail.source_type_txt,
              rightIconColour:detail.reference_txt==='YES'?theme.palette.primaryText.light:graphPalette[getCerebrumLabel(detail)],
              // getBottomComponent: getBottomComponentFunc,
              // onTitleClick:()=>onClickResultItem({item:detail,id:detail.id,newWindow:true,label:getCerebrumLabel(detail)}),
            },
          }
          let fieldName = inactive?'lineage_inc_reference_txt':'active_lineage_inc_reference_txt';
          if(detail[fieldName]==='UPSTREAM_DOWNSTREAM'){
            if(direction==='both'){
              g.data['leftActionIcon'] = 'add'
              g.data['leftAction'] = onNodeExpandClick.bind(g, {direction:'upstream'})
              g.data['rightActionIcon'] = 'add'
              g.data['rightAction'] = onNodeExpandClick.bind(g, {direction:'downstream'})
            }else{
              g.data[direction==='upstream'?'leftActionIcon':'rightActionIcon'] = 'add'
              g.data[direction==='upstream'?'leftAction':'rightAction'] = onNodeExpandClick.bind(g, {direction:direction})
            }
          }
          if(detail[fieldName]==='UPSTREAM_ONLY' && ['both','upstream'].includes(direction)){
            g.data['leftActionIcon'] = 'add'
            g.data['leftAction'] = onNodeExpandClick.bind(g, {direction:'upstream'})
          }
          if(['DOWNSTREAM_ONLY','UPSTREAM_QUERY_DOWNSTREAM'].includes(detail[fieldName]) && ['both','downstream'].includes(direction)){
            g.data['rightActionIcon'] = 'add'
            g.data['rightAction'] = onNodeExpandClick.bind(g, {direction:'downstream'})
          }
          if(onNodeClick){g.data.onTitleClick = onNodeClick.bind(g)}
          if(detail.object_type_txt==='HOST'){
            g.data.icon = detail.source_type_txt;
            g.data.rightIcon = undefined;
            g.data.onTitleClick = ()=>window.open(`/profile/source/${detail.source_id_srt}`,'_blank')
          }
          groups.push(g)
        })
      })
    }

  idsMissing.forEach(i=>{
    groups.push({
      id:i,
      type:'KGroup',
      width:groupWidth,
      height:groupInitHeight,
      data:{
        label: 'Unknown',
        icon:'unknown',
        borderColour:theme.palette.primaryText.light,
        subTitle: 'Unknown',
        rightIcon:'unknown',
        rightIconColour:theme.palette.primaryText.light,
      },
    })
  })
  return groups
}

export const processRawData = ({list, target, direction}) => {
  let processedList = [];
  list = dedupeList(list)
  for(let i=0; i<list.length; i++){
    let rawData = list[i]
    let parentId;
    let isThirdLevel = thirdLevelObjects.includes(getCerebrumLabel(rawData))
    if(isThirdLevel && rawData.hierarchy_parent_id_txt){
      parentId = rawData.hierarchy_parent_id_txt + ''
    }
    else if(['DATABASE','TOOL'].includes(getCerebrumLabel(rawData))){
      parentId = rawData.source_id_srt + '_' + target.id + '_' + direction;
    }
    else if(checkIsReferencePlaceHolder(rawData)){
      parentId = undefined;
    }else{
      parentId = rawData.parent_id_txt || rawData.parent_id || rawData.source_id_srt;
      parentId = parentId + '_' + target.id + '_' + direction;
    }
    let newData;

    if(checkIsReferencePlaceHolder(rawData)){
      newData = {
        id:rawData.id,
        type:'KButtonNode',
        extent:parentId,
        isReferecenPlaceholder:true,
        shouldConstructLink:rawData.shouldConstructLink,
        draggable:true,
        direction,
        width:nodeWidth,
        height:buttonNodeHeight,
        data:{
          text:'SHOW REFERENCES',
          header:rawData.referenceCount + ' Reference items',
          onClick:()=>sendMessage({setShowReference:true, groupId:rawData.id}),
        }
      };
    }
    else{
      newData = {
        id:rawData.id,
        type:'KLineageNode',
        extent:parentId?'parent':undefined,
        parentNode:parentId?parentId.toLowerCase():undefined,
        shouldConstructLink:rawData.shouldConstructLink,
        shouldLoadChildLineage:rawData.shouldLoadChildLineage,
        relationship:rawData.relationship,
        draggable:false,
        direction,
        width:nodeWidth,
        height:nodeOneLineHeight,
        data:{
          id:rawData.id,
          label:getDispFields(rawData,'dispTitle'),
          tags:generateTags(rawData, true),
          indentSize:isThirdLevel?12:0,
          obj:rawData,
          titleColour: rawData.relationship && rawData.relationship.includes('REPLACE')?'#000':undefined,
          iconColour: rawData.reference_txt==='YES'? theme.palette.primaryText.light:graphPalette[getCerebrumLabel(rawData)],
          backgroundColour: rawData.reference_txt==='YES'?
                              undefined:
                              rawData.relationship && rawData.relationship.includes('REPLACE')?
                                '#FFDFE7':
                                getBackgroundColour(getCerebrumLabel(rawData),true)
                                ,
          borderColour: rawData.reference_txt==='YES'?
                          theme.palette.primaryText.light:
                          graphPalette[getCerebrumLabel(rawData)]
        }
      };
    }

    processedList.push(newData)
  }
  return processedList
}

export const loadNodes = async ({target, direction, checkChildren, forceObjectTypes, forceRelation, hideEmptyMessage, inactive, sendAlert}) => {
  const objectTypes = forceObjectTypes || lineageObjectsMap[getCerebrumLabel(target.data.obj)]
  let relationship = forceRelation || getLineageRelation(getCerebrumLabel(target.data.obj),direction);
  let finalData = []
  const maxPerPage = 50;
  const load = async ({prevData=[], page=1}) => {
    await axiosCerebrum
      .get(
        `/api/${getLabelPlural(getCerebrumLabel(target.data.obj))}/${target.data.obj.id}/related`,
        {params:{
          relationship: relationship.join(','),
          per_page: maxPerPage,
          page,
          object_name:objectTypes.join(','),
          sort:'TRUST_SCORE_DESC',
          // children_check:checkChildren,
          active_flag:inactive?undefined:true,
          object_active_flag:inactive?undefined:true,
          // object_reference: showReference?undefined:false
        }}
      )
      .then(async response=>{
        let data = [...prevData,...response.data.items];
        if(response.data.page<response.data.pages){
          if(data.length>=maxPerPage){
            finalData = data.slice(0,maxPerPage);
            sendAlert({type:'info',message:`${response.data.total} items found, displaying first ${maxPerPage} items based on trust score`})
          }else{
            await load({prevData:data, page:response.data.page+1})
          }
        }else{
          finalData = data;
        }
      })
  }
  await load({});
  if(finalData.length===0 && !hideEmptyMessage){
    sendAlert({type:'info',message:`No ${direction} items found`})
  }
  return finalData
}

export const checkHasLineage = ({node, direction, inactive}) => {
  if(['COLLECTION_INSTANCE','COLLECTION'].includes(node.data?.obj?.object_type_txt)){
    return node.data?.obj?.has_children
  }
  let viewName = inactive?'lineage_inc_reference_txt':'active_lineage_inc_reference_txt';
  let hasLineage = node.data.obj[viewName] && node.data.obj[viewName]==='UPSTREAM_DOWNSTREAM';
  if(node.data.obj[viewName]==='UPSTREAM_ONLY' && direction==='upstream')hasLineage = true;
  if(['DOWNSTREAM_ONLY','UPSTREAM_QUERY_DOWNSTREAM'].includes(node.data.obj[viewName]) && direction==='downstream')hasLineage = true;
  return hasLineage
}

export const bindLoadUpstremCodeAction = ({node, onNodeExpandClick, direction = 'upstream', inactive}) => {
  if(['QUERY','COLLECTION','COLLECTION_INSTANCE'].includes(getCerebrumLabel(node.data.obj)))return;
  let viewName = inactive?'lineage_inc_reference_txt':'active_lineage_inc_reference_txt';
  if(!['UPSTREAM_QUERY_ONLY','UPSTREAM_QUERY_DOWNSTREAM'].includes(node.data.obj[viewName]))return;
  node.data.leftActionIcon = 'more_horiz'
  node.data.leftAction = onNodeExpandClick.bind(node, {direction})
  node.data.leftActionTooltip = 'Looks like there is no further upstream lineage. Click to check if there is upstream code to view'
}

export const bindActionsToNodes = ({nodes, direction ,onNodeExpandClick, onNodeClick, generateCustomRightClickAction, updateFocusedNode, isRestoreCache, loadChildLineage, allNodes }) => {
  for(let i=0; i<nodes.length; i++){
    let node = nodes[i]
    if(node.type!=="KLineageNode")continue;
    if(node.id.includes('_parent_placeholder'))continue;
    if(direction || node.direction){
      let finalDirection = direction || node.direction;
      let hasLineage = checkHasLineage({node, direction:finalDirection})
      if(hasLineage){
        if(!isRestoreCache){
          node.data[finalDirection==='upstream'?'leftActionIcon':'rightActionIcon'] = 'add'
        }
        node.data[finalDirection==='upstream'?'leftAction':'rightAction'] = onNodeExpandClick.bind(node, {direction:finalDirection})
      }else{
        node.data[finalDirection==='upstream'?'leftActionIcon':'rightActionIcon'] = undefined;
        node.data[finalDirection==='upstream'?'leftAction':'rightAction'] = undefined;
        if(node.shouldLoadChildLineage && ![...nodes,...allNodes].find(n=>n.parentNode===node.id) ){
          node.data['bodyActions'] = [{action:loadChildLineage.bind(node, {direction:finalDirection}),text:'VIEW CHILD LINEAGE' }];
          node.height = nodeOneLineHeight + bodyButtonHeight;
        }
      }
      if(!hasLineage && finalDirection==='upstream' && ( !isRestoreCache || node['leftAction'] )){
        bindLoadUpstremCodeAction({node, onNodeExpandClick, direction:finalDirection})
      }
    }
    // if(node.isRoot){
    //   forceBindActionToNodes({node, direction:'upstream', onNodeClick, onNodeExpandClick,hasLineage:checkHasLineage({node, direction:'upstream'}), generateCustomRightClickAction, updateFocusedNode})
    //   forceBindActionToNodes({node, direction:'downstream', onNodeClick, onNodeExpandClick,hasLineage:checkHasLineage({node, direction:'downstream'}), generateCustomRightClickAction, updateFocusedNode})
    // }
    node.data.onContextMenu = ()=>updateFocusedNode([node.id])
    node.data.customContextMenuItems = generateCustomRightClickAction(node)
    node.data.onClick = onNodeClick.bind(node)
    if(updateFocusedNode)node.data.onChildListOpenClick = ()=>updateFocusedNode([node.data.id]);
  }
}

export const forceBindActionToNodes = ({node, direction, onNodeClick, onNodeExpandClick, hasLineage, generateCustomRightClickAction, updateFocusedNode}) => {
  if(onNodeExpandClick){
    if(hasLineage){
      node.data[direction==='upstream'?'leftActionIcon':'rightActionIcon'] = 'add'
      node.data[direction==='upstream'?'leftAction':'rightAction'] = onNodeExpandClick.bind(node, {direction})
    }
    if(!hasLineage && direction==='upstream'){
      bindLoadUpstremCodeAction({node, onNodeExpandClick, direction})
    }
  }
  if(generateCustomRightClickAction && updateFocusedNode){
    node.data.onContextMenu = ()=>updateFocusedNode([node.id])
    node.data.customContextMenuItems = generateCustomRightClickAction(node)
  }
  if(onNodeClick){
    node.data.onClick = onNodeClick.bind(node)
  }
}

const checkShouldLoadQuery = (labelOne, labelTwo,relationship) => {
  if(!Boolean(relationship?.includes('REFERENCE')))return false;
  return (
    ['TABLE','COLUMN'].every(l=>[labelOne, labelTwo].includes(l)) ||
    ['TABLE','DATASET_TABLE'].every(l=>[labelOne, labelTwo].includes(l)) ||
    ['TABLE','DATASET'].every(l=>[labelOne, labelTwo].includes(l)) ||
    [labelOne, labelTwo].every(l=>l==='TABLE') ||
    [labelOne, labelTwo].every(l=>l==='DATASET_TABLE') ||
    [labelOne, labelTwo].every(l=>l==='COLUMN')
  )
}

export const constructLinks = ({target, children, direction, currentLinks, defaultSelected}) => {
  if(!children || children.length===0)return [];
  let links = []
  children.forEach(c=>{
    let link = {
      source:direction==='upstream'?c.id:target.id,
      target:direction==='upstream'?target.id:c.id,
      type:'KLineageEdge',
      selected:defaultSelected,
      data:{
        relationship: c.relationship,
        lineType:c.relationship?.includes('SOURCE')?'dashed':'solid',
        isAction:c.type==='KButtonNode' || target.type==='KButtonNode' || c.isReferecenPlaceholder || target.isReferecenPlaceholder,
        isManual: Boolean(c.relationship?.includes('SOURCE')),
        isAuto:Boolean(c.relationship?.includes('REFERENCE')||c.relationship?.includes('SYNONYM')),
        isTableToTable:((c.type||c.object_type_txt)==='TABLE' && (target.data.obj?.type||target.data.obj?.object_type_txt)==='TABLE') || ((c.type||c.object_type_txt)==='DATASET_TABLE' && (target.data.obj?.type||target.data.obj?.object_type_txt)==='DATASET_TABLE'),
        shouldLoadQuery:checkShouldLoadQuery(c.type||c.object_type_txt,target.data.obj?.type||target.data.obj?.object_type_txt,c.relationship),
      }
    }
    link.data.colour = c.relationship?.includes('REPLACE')?theme.palette.error.light:theme.palette.primaryText.light;
    link.id = link.source + '_' + link.target+'_'+link.data.relationship;
    let samePathLinks = [...currentLinks,...links].filter(l=>l.source===link.source && l.target===link.target)
    if(samePathLinks.length>0){
      let shouldPushLink = false;
      samePathLinks.forEach(p=>{
        if(link.data.relationship?.includes('REFERENCE') && p.data.relationship?.includes('REFERENCE'))return;
        if(isNaN(link.data.curveOffset))link.data.curveOffset = 0;
        link.data.curveOffset -= 15;
        shouldPushLink = true;
      })
      if(shouldPushLink)links.push(link)
    }else{
      links.push(link)
    }
  })
  return links;
}

export const postCheckLinks = ({links, nodes}) => {

  const getParentX = (n) => {
    let parent = nodes.find(p=>p.id===n.parentNode);
    if(parent){
      if(parent.type==='KGroup')return parent.position.x;
      return getParentX(parent)
    }
  }

  links.forEach(l=>{
    if(l.source===l.target)return;
    let target = nodes.find(n=>n.id===l.target)
    if(target.data?.obj?.object_type_txt==='DATASET' && target.type==='KLineageNode'){
      let datasetGroupNode = nodes.find(n=>n.type==='KGroup' && n.data?.obj?.id===target.data?.obj?.id && n.id!==target.id && n.position.x===getParentX(target))
      if(datasetGroupNode)l.target = datasetGroupNode.id;
    }
    let source = nodes.find(n=>n.id===l.source)
    if(source.data?.obj?.object_type_txt==='DATASET' && source.type==='KLineageNode'){
      let datasetGroupNode = nodes.find(n=>n.type==='KGroup' && n.data?.obj?.id===source.data?.obj?.id && n.id!==source.id && n.position.x===getParentX(source))
      if(datasetGroupNode)l.source = datasetGroupNode.id;
    }
  })
}

export const bindActionsToLinks = ({links, nodes, setLinkedItem,updateFocusedNode, updateFocusLink, setSelectedItem, setLineageDrawerOpen, setObjectDrawerOpen }) => {
  for(let i=0; i<links.length; i++){
    let link = links[i]
    if(link.data.relationship?.includes('REPLACE'))continue;
    if(link.data.isAction)continue;
    // if(link.data.isManual || link.data.relationship?.includes('SYNONYM') || !link.data.relationship?.includes('SHORTCUT'))continue;
    link.data.onClick = () => {
      setObjectDrawerOpen(false)
      setSelectedItem(nodes.find(n=>n.id===link.source))
      updateFocusedNode([link.source, link.target])
      setLinkedItem(nodes.find(n=>n.id===link.target))
      setLineageDrawerOpen(link)
      updateFocusLink([link.id])
    }
  }


}
