import React, { useContext } from 'react';
import { IAContext } from '../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import { KadaSelect } from 'kada-component-library';
import 'url-search-params-polyfill';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getDispFields } from '../../../utilities';

const SelectTarget = props => {

  const {
    config,
    setConfig,
  } = props;

  const {
    // history,
    state,
  } = useContext(IAContext);


  return (
    <table>
      <tbody>
        <tr style={{ verticalAlign: 'top', height: '100px' }}>
          <td className="w-50">
            Target
          </td>
          <td className="w-100">
            <div className='border border-(--color-base-border) rounded'>
              <SimpleResultItem
                item={state.targetObject}
                label={state.targetObject.object_type_txt}
                title={getDispFields(state.targetObject, 'dispTitle')}
                subTitle={getDispFields(state.targetObject, 'dispSubtitle')}
                hideRight
              />
            </div>
          </td>
        </tr>
        <tr style={{ height: '80px' }}>
          <td className="w-50">
            Assessment Level
          </td>
          <td className="w-100">
            <KadaSelect
              options={[
                {text: 'Table', value: 'TABLE'},
                {text: 'Column', value: 'COLUMN'},
              ]}
              value={config.assessment_level}
              onChange={e=>{
                setConfig({
                  ...config,
                  assessment_level: e.detail.value
                })
              }}
              placeholder='Select level'
              isHideSearch
              required
            />
          </td>
        </tr>
        <tr style={{ height: '80px' }}>
          <td className="w-50">
            Direction
          </td>
          <td className="w-100">
            <KadaSelect
              options={[
                // {text: 'Upstream', value: 'UPSTREAM'},
                {text: 'Assess downstream impact', value: 'DOWNSTREAM'},
              ]}
              value={config.assessment_direction}
              onChange={e=>{
                setConfig({
                  ...config,
                  assessment_direction: e.detail.value
                })
              }}
              placeholder='Select direction'
              isHideSearch
              required
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default SelectTarget;
