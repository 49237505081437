import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KadaFormEntryLayout, KadaInput, KadaSelect } from 'kada-component-library';
import {  getDispFields, getPartialMatchSearchString, titleCaseObjectName } from '../../../utilities';
import axiosSolr from '../../../axios-solr';


function ObjectSelector(props) {

  const {
    onSelectObject,
    addedObjects,
  } = props;

  const [objectType, setObjectType] = useState('');
  const [dbSuggestionSearch, setDbSuggestionSearch] = useState('');
  const [dbSuggestions, setDbSuggestions] = useState([]);
  const [selectedDB, setSelectedDB] = useState('');

  const [schemaSuggestions, setSchemaSuggestions] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState('');
  const [schemaSuggestionSearch, setSchemaSuggestionSearch] = useState('');

  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locationSuggestionSearch, setLocationSuggestionSearch] = useState('');

  const [tableSuggestions, setTableSuggestions] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [tableSuggestionSearch, setTableSuggestionSearch] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [objectSuggestions, setObjectSuggestions] = useState();
  const [loadingObjectSuggestions, setLoadingObjectSuggestions] = useState(false);

  const dataObjects = ['COLUMN', 'TABLE'];
  const contentObjects = ['CONTENT_APP', 'REPORT', 'SHEET', 'DATA_PIPELINE', 'DATASET', 'DATASET_TABLE', 'DATASET_FIELD', 'FILE', 'ML_MODEL', 'CODE'];

  const facetPerPage = 50;

  const loadDBs = ({ type = objectType, offset = dbSuggestions.length, search = dbSuggestionSearch }) => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq: `object_type_srt:${type}`,
          rows: 0,
          facet:'on',
          'facet.field':dataObjects.includes(type)?'database_srt':'source_srt',
          'facet.contains':search,
          'facet.contains.ignoreCase':true,
          'facet.sort':'index',
          'facet.mincount':1,
          'facet.limit':facetPerPage,
          'facet.offset':offset,
        }}
      )
      .then(response=>{
        const dbSuggestions = response.data.facet_counts?.facet_fields[dataObjects.includes(type)?'database_srt':'source_srt'].filter((_,i)=>i%2===0);
        setDbSuggestions(dbSuggestions || []);
      })
      .catch(error=>{
        console.error(error);
      });
  }

  const loadSchemas = ({ type = objectType, offset = schemaSuggestions.length, search = schemaSuggestionSearch, db = selectedDB }) => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq: `object_type_srt:${type} AND database_srt:"${db}"`,
          rows: 0,
          facet:'on',
          'facet.field':'schema_srt',
          'facet.contains':search,
          'facet.contains.ignoreCase':true,
          'facet.sort':'index',
          'facet.mincount':1,
          'facet.limit':facetPerPage,
          'facet.offset':offset,
        }}
      )
      .then(response=>{
        const newSuggestions = response.data.facet_counts?.facet_fields.schema_srt.filter((_,i)=>i%2===0);
        setSchemaSuggestions([
          ...(offset === 0 ? [] : schemaSuggestions),
          ...(newSuggestions || [])
        ])
      })
      .catch(error=>{
        console.error(error);
      });
  }

  const loadLocations = ({ type = objectType, offset = locationSuggestions.length, search = locationSuggestionSearch, db = selectedDB }) => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq: `object_type_srt:${type} AND source_srt:"${db}"`,
          rows: 0,
          facet:'on',
          'facet.field':'location_srt',
          'facet.contains':search,
          'facet.contains.ignoreCase':true,
          'facet.sort':'index',
          'facet.mincount':1,
          'facet.limit':facetPerPage,
          'facet.offset':offset,
        }}
      )
      .then(response=>{
        const newSuggestions = response.data.facet_counts?.facet_fields.location_srt.filter((_,i)=>i%2===0);
        setLocationSuggestions([
          ...(offset === 0 ? [] : locationSuggestions),
          ...(newSuggestions || [])
        ])
      })
      .catch(error=>{
        console.error(error);
      });
  }

  const loadTables = ({ type = objectType, offset = tableSuggestions.length, search = tableSuggestionSearch, schema = selectedSchema }) => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq: `object_type_srt:${type} AND database_srt:"${selectedDB}" AND schema_srt:"${schema}"`,
          rows: 0,
          facet:'on',
          'facet.field':'table_srt',
          'facet.contains':search,
          'facet.contains.ignoreCase':true,
          'facet.sort':'index',
          'facet.mincount':1,
          'facet.limit':facetPerPage,
          'facet.offset':offset,
        }}
      )
      .then(response=>{
        const newSuggestions = response.data.facet_counts?.facet_fields.table_srt.filter((_,i)=>i%2===0);
        setTableSuggestions([
          ...(offset === 0 ? [] : tableSuggestions),
          ...(newSuggestions || [])
        ]);
      })
      .catch(error=>{
        console.error(error);
      });
  }

  const onSearchObjects = ({ type = objectType, search = searchValue, row = objectSuggestions?.length || 0, db = selectedDB, schema = selectedSchema, table = selectedTable, location = selectedLocation }) => {

    if(row === 0)setObjectSuggestions();
    if(search.trim()=== '') {
      setLoadingObjectSuggestions(false);
      return;
    }

    setLoadingObjectSuggestions(true);

    let params = {
      q: getPartialMatchSearchString(search, true, true),
      rows: facetPerPage,
      start: row
    };
    if(dataObjects.includes(type)){
      params.fq = `object_type_srt:${type}`
      if(db) params.fq += ` AND database_srt:"${db}"`;
      if(schema) params.fq += ` AND schema_srt:"${schema}"`;
      if(table) params.fq += ` AND table_srt:"${table}"`;
    }
    if(contentObjects.includes(type)){
      params.fq = `object_type_srt:${type}`
      if(db) params.fq += ` AND source_srt:"${db}"`;
      if(location) params.fq += ` AND location_srt:"${location}"`;
    }
    if(addedObjects.length>0){
      params.fq += ` AND -id:(${addedObjects.map(o=>o.id).join(' OR ')})`;
    }

    axiosSolr
      .get(
        `/solr/search/select`,{params}
      )
      .then(response=>{
        const data = response.data.response.docs;
        setObjectSuggestions([...(objectSuggestions||[]), ...data]);
        setLoadingObjectSuggestions(false);
      })
      .catch(error=>{
        console.error(error);
        setLoadingObjectSuggestions(false);
      });
  }

  return (
    <div>
      <h3 className="mb-6">Link Data / Content to new issue</h3>
      <KadaFormEntryLayout fieldName="TYPE" isCentered style={{width:'100%'}}>
        <div slot="content">
          <KadaSelect
            value={objectType}
            placeholder='Select an object type'
            required
            enableWidthHandler
            isHideSearch
            options={
              [...dataObjects, ...contentObjects].map(o=>({
                value: o,
                text: titleCaseObjectName(o)
              }))
            }
            onChange={e=>{
              setObjectType(e.detail.value)
              setSelectedDB('');
              setDbSuggestions([]);
              setDbSuggestionSearch('');
              loadDBs({ type: e.detail.value, offset: 0 });
              setSearchValue('');
              setObjectSuggestions();
            }}
          />
        </div>
      </KadaFormEntryLayout>
      <KadaFormEntryLayout fieldName={dataObjects.includes(objectType)?'DATABASE':'TOOL'} isCentered>
        <div slot="content">
          <KadaSelect
            value={selectedDB}
            placeholder={`Select a  Database / Tool`}
            enableWidthHandler
            disabled={!objectType}
            options={dbSuggestions.map(db=>({
              value: db,
              text: db
            }))}
            autoInputDebounce
            onInput={e=>{
              loadDBs({ search: e.detail.value, offset: 0 });
              setDbSuggestionSearch(e.detail.value);
            }}
            onlyTriggerScrollOnBottom
            onScroll={()=>{
              loadDBs({ offset: dbSuggestions.length });
            }}
            onChange={e=>{
              setSelectedDB(e.detail.value);
              if(dataObjects.includes(objectType) && e.detail.value){
                loadSchemas({ search: '', offset: 0, db: e.detail.value });
                setSelectedSchema('');
              }
              if(contentObjects.includes(objectType) && e.detail.value){
                loadLocations({ search: '', offset: 0, db: e.detail.value });
                setSelectedLocation('');
              }
              setSearchValue('');
              setObjectSuggestions();
            }}
          />
        </div>
      </KadaFormEntryLayout>

      {
        objectType && dataObjects.includes(objectType) && selectedDB &&
        <KadaFormEntryLayout fieldName="SCHEMA" isCentered>
          <div slot="content">
            <KadaSelect
              value={selectedSchema}
              placeholder='Select a Schema'
              enableWidthHandler
              disabled={!selectedDB}
              options={schemaSuggestions.map(schema=>({
                value: schema,
                text: schema
              }))}
              autoInputDebounce
              onInput={e=>{
                loadSchemas({ search: e.detail.value, offset: 0 });
                setSchemaSuggestionSearch(e.detail.value);
              }}
              onlyTriggerScrollOnBottom
              onScroll={()=>{
                loadSchemas({ offset: schemaSuggestions.length });
              }}
              onChange={e=>{
                setSelectedSchema(e.detail.value);
                if(objectType === 'COLUMN' && e.detail.value){
                  loadTables({ search: '', offset: 0, schema: e.detail.value });
                  setSelectedTable('');
                }
                setSearchValue('');
                setObjectSuggestions();
              }}
            />
          </div>
        </KadaFormEntryLayout>
      }
      {
        objectType === 'COLUMN' && selectedSchema &&
        <KadaFormEntryLayout fieldName="TABLE" isCentered>
          <div slot="content">
            <KadaSelect
              value={selectedTable}
              placeholder='Select a Table'
              enableWidthHandler
              disabled={!selectedSchema}
              options={tableSuggestions.map(table=>({
                value: table,
                text: table
              }))}
              autoInputDebounce
              onInput={e=>{
                loadTables({ search: e.detail.value, offset: 0 });
                setTableSuggestionSearch(e.detail.value);
              }}
              onlyTriggerScrollOnBottom
              onScroll={()=>{
                loadTables({ offset: tableSuggestions.length });
              }}
              onChange={e=>{
                setSelectedTable(e.detail.value);
                setSearchValue('');
                setObjectSuggestions();
              }}
            />
          </div>
        </KadaFormEntryLayout>
      }
      {
        objectType && contentObjects.includes(objectType) && selectedDB &&
        <KadaFormEntryLayout fieldName="LOCATION" isCentered>
          <div slot="content">
            <KadaSelect
              value={selectedLocation}
              placeholder='Select a Location'
              enableWidthHandler
              disabled={!selectedDB}
              options={locationSuggestions.map(location=>({
                value: location,
                text: location
              }))}
              autoInputDebounce
              onInput={e=>{
                loadLocations({ search: e.detail.value, offset: 0 });
                setLocationSuggestionSearch(e.detail.value);
              }}
              onlyTriggerScrollOnBottom
              onScroll={()=>{
                loadLocations({ offset: locationSuggestions.length });
              }}
              onChange={e=>{
                setSelectedLocation(e.detail.value);
                setSearchValue('');
                setObjectSuggestions();
              }}
            />
          </div>
        </KadaFormEntryLayout>
      }
      <div>
        <KadaInput
          placeholder='Search for an object'
          disabled={!objectType}
          onInput={e=>{
            setSearchValue(e.detail.value);
            onSearchObjects({ search: e.detail.value, row: 0 });
          }}
          loadingSuggestions={loadingObjectSuggestions}
          // onScroll={(e)=>{
          //   onSearchObjects({ row: objectSuggestions.length });
          // }}
          autoInputDebounce
          onlyTriggerScrollOnBottom
          suggestions={objectSuggestions?.map(object=>({
            id: object.id,
            text: object.name_txt,
            subText: getDispFields(object, 'dispSubtitle'),
            icon: object.object_type_txt,
            onClick: e => {
              onSelectObject(object);
              setObjectSuggestions();
            },
            object
          }))}
        />
      </div>
    </div>
  )
}

ObjectSelector.propTypes = {
  history: PropTypes.object,
}

export default ObjectSelector;
