import React, { useEffect, useReducer, useState } from 'react';
import { withStyles, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useGetSolr from '../../hooks/useGetSolr';
import Body from '../../components/DataQualityInsights/Body/Body';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { removeUrlQueryArg, setInitialState } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import { addHistory } from '../../HistoryManager';
import axiosSolr from '../../axios-solr';
import SearchResultModal from '../../components/UI/SearchResultModal/SearchResultModal';
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkIsDataGov, checkIsDataManager } from '../../permissionChecker';
import { searchComponentNameMap } from '../../components/BasicSearch/MainSearch/Utils/Utils';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText: {
    color: theme.palette.primaryText.main
  }
});


const initialState = {
  tabState: 0,
  bySourcePeriod: 30,
  bySourceTabState: 0,
  bySourceTrendCache: {},
  byDomainPeriod: 30,
  byDomainTabState: 0,
  byDomainTrendCache: {},
  byCategoryPeriod: 30,
  byCategoryTabState: 0,
  byCategoryTrendCache: {},
  byUseCasePeriod: 30,
  byUseCaseTabState: 0,
  byUseCaseTrendCache: {},
  byCollectionPeriod: 30,
  byCollectionTabState: 0,
  byCollectionTrendCache: {},
  byToolTabState: 0,
  selectedScoreBand: '100',
  byCollectionInstancePageNum: 1
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_is_data_gov_manager':
      return {
        ...state,
        isDataGovManager: action.isDataGovManager,
      }
    case "set_db_list":
      return {
        ...state,
        dbList: action.dbList,
        dbListLoading: action.dbListLoading,
        dbListError: action.dbListError,
      }
    case "set_obeject_count_by_source":
      return {
        ...state,
        objectCountBySource: action.objectCountBySource,
      }
    case 'set_dq_by_sources':
      return {
        ...state,
        dqBySources: action.dqBySources,
        dqBySourcesLoading: action.dqBySourcesLoading,
        dqBySourcesError: action.dqBySourcesError,
      }
    case 'set_by_source_period':
      return {
        ...state,
        bySourcePeriod: action.bySourcePeriod,
      }
    case "set_selected_source":
      return {
        ...state,
        selectedSource: action.selectedSource,
      }
    case 'set_by_source_tab_state':
      return {
        ...state,
        bySourceTabState: action.bySourceTabState,
      }
    case 'set_by_source_selected_dimension':
      return {
        ...state,
        bySourceSelectedDimension: action.bySourceSelectedDimension,
      }
    case 'set_by_source_trend': {
      return {
        ...state,
        bySourceTrend: action.bySourceTrend,
        bySourceTrendLoading: action.bySourceTrendLoading,
        bySourceTrendError: action.bySourceTrendError,
      }
    }
    case 'set_by_source_trend_cache': {
      return {
        ...state,
        bySourceTrendCache: action.bySourceTrendCache,
      }
    }
    case 'set_by_source_selected_object':
      return {
        ...state,
        bySourceSelectedObject: action.bySourceSelectedObject,
      }
    /////////////////////////
    case 'set_by_domain_tab_state':
      return {
        ...state,
        byDomainTabState: action.byDomainTabState,
      }
    case 'set_by_domain_period':
      return {
        ...state,
        byDomainPeriod: action.byDomainPeriod,
      }
    case 'set_dq_by_domain':
      return {
        ...state,
        dqByDomain: action.dqByDomain,
        dqByDomainLoading: action.dqByDomainLoading,
        dqByDomainError: action.dqByDomainError,
      }
    case 'set_selected_domain':
      return {
        ...state,
        selectedDomain: action.selectedDomain,
      }
    case 'set_by_domain_trend':
      return {
        ...state,
        byDomainTrend: action.byDomainTrend,
        byDomainTrendLoading: action.byDomainTrendLoading,
        byDomainTrendError: action.byDomainTrendError,
      }
    case 'set_by_domain_trend_cache':
      return {
        ...state,
        byDomainTrendCache: action.byDomainTrendCache,
      }
    case 'set_by_domain_selected_dimension':
      return {
        ...state,
        byDomainSelectedDimension: action.byDomainSelectedDimension,
      }
    case 'set_by_domain_collections':
      return {
        ...state,
        byDomainCollections: action.byDomainCollections,
      }
    case 'set_by_domain_instance_list':
      return {
        ...state,
        byDomainInstanceList: action.byDomainInstanceList,
        byDomainInstanceListLoading: action.byDomainInstanceListLoading,
        byDomainInstanceListError: action.byDomainInstanceListError,
      }
    case 'set_by_domain_sort': {
      return {
        ...state,
        byDomainSort: action.byDomainSort,
      }
    }
    case 'set_by_domain_instance_page_num':
      return {
        ...state,
        byDomainInstancePageNum: action.byDomainInstancePageNum,
      }
    case 'set_by_domain_selected_object':
      return {
        ...state,
        byDomainSelectedObject: action.byDomainSelectedObject,
      }
    /////////////////////////
    // create same as domain for category
    case 'set_by_category_tab_state':
      return {
        ...state,
        byCategoryTabState: action.byCategoryTabState,
      }
    case 'set_by_category_period':
      return {
        ...state,
        byCategoryPeriod: action.byCategoryPeriod,
      }
    case 'set_dq_by_category':
      return {
        ...state,
        dqByCategory: action.dqByCategory,
        dqByCategoryLoading: action.dqByCategoryLoading,
        dqByCategoryError: action.dqByCategoryError,
      }
    case 'set_selected_category':
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      }
    case 'set_by_category_trend':
      return {
        ...state,
        byCategoryTrend: action.byCategoryTrend,
        byCategoryTrendLoading: action.byCategoryTrendLoading,
        byCategoryTrendError: action.byCategoryTrendError,
      }
    case 'set_by_category_trend_cache':
      return {
        ...state,
        byCategoryTrendCache: action.byCategoryTrendCache,
      }
    case 'set_by_category_selected_dimension':
      return {
        ...state,
        byCategorySelectedDimension: action.byCategorySelectedDimension,
      }
    case 'set_by_category_collections':
      return {
        ...state,
        byCategoryCollections: action.byCategoryCollections,
      }
    case 'set_by_category_instance_list':
      return {
        ...state,
        byCategoryInstanceList: action.byCategoryInstanceList,
        byCategoryInstanceListLoading: action.byCategoryInstanceListLoading,
        byCategoryInstanceListError: action.byCategoryInstanceListError,
      }
    case 'set_by_category_sort': {
      return {
        ...state,
        byCategorySort: action.byCategorySort,
      }
    }
    case 'set_by_category_instance_page_num':
      return {
        ...state,
        byCategoryInstancePageNum: action.byCategoryInstancePageNum,
      }
    case 'set_by_category_selected_object':
      return {
        ...state,
        byCategorySelectedObject: action.byCategorySelectedObject,
      }
    /////////////////////////
    // create same as domain for use case
    case 'set_by_use_case_tab_state':
      return {
        ...state,
        byUseCaseTabState: action.byUseCaseTabState,
      }
    case 'set_by_use_case_period':
      return {
        ...state,
        byUseCasePeriod: action.byUseCasePeriod,
      }
    case 'set_dq_by_use_case':
      return {
        ...state,
        dqByUseCase: action.dqByUseCase,
        dqByUseCaseLoading: action.dqByUseCaseLoading,
        dqByUseCaseError: action.dqByUseCaseError,
      }
    case 'set_selected_use_case':
      return {
        ...state,
        selectedUseCase: action.selectedUseCase,
      }
    case 'set_by_use_case_trend':
      return {
        ...state,
        byUseCaseTrend: action.byUseCaseTrend,
        byUseCaseTrendLoading: action.byUseCaseTrendLoading,
        byUseCaseTrendError: action.byUseCaseTrendError,
      }
    case 'set_by_use_case_trend_cache':
      return {
        ...state,
        byUseCaseTrendCache: action.byUseCaseTrendCache,
      }
    case 'set_by_use_case_selected_dimension':
      return {
        ...state,
        byUseCaseSelectedDimension: action.byUseCaseSelectedDimension,
      }
    case 'set_by_use_case_collections':
      return {
        ...state,
        byUseCaseCollections: action.byUseCaseCollections,
      }
    case 'set_by_use_case_instance_list':
      return {
        ...state,
        byUseCaseInstanceList: action.byUseCaseInstanceList,
        byUseCaseInstanceListLoading: action.byUseCaseInstanceListLoading,
        byUseCaseInstanceListError: action.byUseCaseInstanceListError,
      }
    case 'set_by_use_case_sort': {
      return {
        ...state,
        byUseCaseSort: action.byUseCaseSort,
      }
    }
    case 'set_by_use_case_instance_page_num':
      return {
        ...state,
        byUseCaseInstancePageNum: action.byUseCaseInstancePageNum,
      }
    case 'set_by_use_case_selected_object':
      return {
        ...state,
        byUseCaseSelectedObject: action.byUseCaseSelectedObject,
      }
    /////////////////////////
    case 'set_by_collection_tab_state':
      return {
        ...state,
        byCollectionTabState: action.byCollectionTabState,
      }
    case 'set_by_collection_period':
      return {
        ...state,
        byCollectionPeriod: action.byCollectionPeriod,
      }
    case 'set_dq_collection_list':
      return {
        ...state,
        dqCollectionList: action.dqCollectionList,
        dqCollectionListLoading: action.dqCollectionListLoading,
        dqCollectionListError: action.dqCollectionListError,
      }
    case 'set_by_collection_selected_instance':
      return {
        ...state,
        byCollectionSelectedInstance: action.byCollectionSelectedInstance,
      }
    case 'set_by_collection_tiles':
      return {
        ...state,
        byCollectionTiles: action.byCollectionTiles,
        byCollectionTilesLoading: action.byCollectionTilesLoading,
        byCollectionTilesError: action.byCollectionTilesError,
      }
    case 'set_by_collection_selected_dimension':
      return {
        ...state,
        byCollectionSelectedDimension: action.byCollectionSelectedDimension,
      }
    case 'set_by_collection_trend':
      return {
        ...state,
        byCollectionTrend: action.byCollectionTrend,
        byCollectionTrendLoading: action.byCollectionTrendLoading,
        byCollectionTrendError: action.byCollectionTrendError,
      }
    case 'set_by_collection_trend_cache':
      return {
        ...state,
        byCollectionTrendCache: action.byCollectionTrendCache,
      }
    case 'set_by_collection_objects':
      return {
        ...state,
        byCollectionObjects: action.byCollectionObjects,
        byCollectionObjectsLoading: action.byCollectionObjectsLoading,
        byCollectionObjectsError: action.byCollectionObjectsError,
      }
    case 'set_by_collection_selected_object':
      return {
        ...state,
        byCollectionSelectedObject: action.byCollectionSelectedObject,
      }
    // case 'set_by_collection_sort':{
    //   return {
    //     ...state,
    //     byCollectionSort: action.byCollectionSort,
    //   }
    // }
    // case 'set_by_collection_instance_page_num':
    //   return {
    //     ...state,
    //     byCollectionInstancePageNum: action.byCollectionInstancePageNum,
    //   }
    ////////////////////////
    case 'set_by_tool_tab_state':
      return {
        ...state,
        byToolTabState: action.byToolTabState,
      }
    case 'set_dq_by_tool':
      return {
        ...state,
        dqByTool: action.dqByTool,
        dqByToolLoading: action.dqByToolLoading,
        dqByToolError: action.dqByToolError,
      }
    case 'set_selected_tool':
      return {
        ...state,
        selectedTool: action.selectedTool,
      }
    case 'set_selected_tool_dimension':
      return {
        ...state,
        selectedToolDimension: action.selectedToolDimension,
      }
    case 'set_selected_score_band':
      return {
        ...state,
        selectedScoreBand: action.selectedScoreBand,
      }
    case 'set_dq_by_tool_test_page':
      return {
        ...state,
        dqByToolTestPage: action.dqByToolTestPage,
      }
    case 'set_dq_by_tool_test_list':
      return {
        ...state,
        dqByToolTestList: action.dqByToolTestList,
        dqByToolTestListLoading: action.dqByToolTestListLoading,
        dqByToolTestListError: action.dqByToolTestListError,
      }
    case 'set_by_tool_sort':
      return {
        ...state,
        byToolSort: action.byToolSort,
      }
    default:
      throw new Error("Action type not supported");
  }
}

const DataQualityInsights = props => {


  const {
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;


  let storedInitialState = { ...setInitialState(pageCache, initialState) };
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  const [modalQuery, setModalQuery] = useState(undefined);
  const [modalFilter, setModalFilter] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalObjectType, setModalObjectType] = useState(undefined);


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');

  useEffect(() => {
    if (!state) return;
    storePageCache({ cacheID: window.location.href, ...state })
    // eslint-disable-next-line
  }, [state])

  let tabOptions = (checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData })) ? ['BY SOURCE', 'BY DOMAIN', 'BY CATEGORY', 'BY USE CASE', 'BY COLLECTION', 'BY TOOL'] : ['BY DOMAIN']

  useEffect(() => {
    if (tabName && state.dbList) {
      if (!tabOptions.includes(tabName.toUpperCase())) return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({ type: 'set_tab_state', tabState: presetTabState })
      window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['tabName'] }));
    }
    // eslint-disable-next-line
  }, [state.dbList])

  const {
    data: dbList,
    loading: dbListLoading,
    error: dbListError,
    fetchList: dbListFetch
  } = useGetSolr({
    url: '/api/sources',
    params: {
      per_page: 200,
      types: 'TOOL,DATABASE,INTERNAL',
      sort: 'ALPHABETICAL',
      active_flag: true,
    },
    preventAuto: true
  })

  useEffect(() => {
    let validList = dbList ? dbList.items.filter(el => el.id !== 995) : state.dbList
    dispatch({
      type: 'set_db_list',
      dbList: validList,
      dbListError: dbListError,
      dbListLoading: dbListLoading,
    })
    if (dbList) {
      let validSources = validList;
      dispatch({
        type: 'set_db_list',
        dbList: validSources,
      })
      loadObjectCountPerSource()
    }
    // eslint-disable-next-line
  }, [dbList, dbListLoading, dbListError])

  useEffect(() => {
    const onMsgReceived = (msg) => {
      if (msg.data.modalQuery) {
        setModalQuery(msg.data.modalQuery)
      }
      if (msg.data.modalFilter) {
        setModalFilter(msg.data.modalFilter)
      }
      if (msg.data.modalObject) {
        setModalObjectType(msg.data.modalObject)
      }
      if (msg.data.modalQuery || msg.data.modalFilter || msg.data.modalObject) setModalOpen(true)
    }
    window.removeEventListener('message', globalListenerRef.gridMsgListener);
    globalListenerRef.gridMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.gridMsgListener);
    return (() => { window.removeEventListener('message', globalListenerRef.gridMsgListener); })
    // eslint-disable-next-line
  }, [])


  const loadObjectCountPerSource = () => {
    axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: '*:*',
          fq: `active_srt:YES AND -source_id_srt:998`,
          rows: 0,
          'json.facet': {
            "sources": {
              "type": "terms",
              "field": "source_srt",
              "mincount": 1,
              "limit": 200,
              "facet": {
                "source_id": {
                  "type": "terms",
                  "field": "source_id_srt",
                  "mincount": 1,
                  "limit": 100,
                },
                "object_types": {
                  "type": "terms",
                  "field": "object_type_srt",
                  "mincount": 1,
                  "limit": 100,
                  "facet": {
                    "usage": {
                      "type": "terms",
                      "field": "usage_srt",
                      "mincount": 1,
                      "limit": 10,
                      "missing": true,
                    }
                  }
                }
              }
            }
          }
        }
      }
      )
      .then(response => {
        let countObj = {};
        response.data.facets?.sources?.buckets?.forEach(el => {
          let sourceId = el.source_id?.buckets[0]?.val;
          countObj[el.val] = { total: el.count, id: sourceId };
          countObj[el.val].objects = el.object_types?.buckets || [];
        })
        dispatch({ type: 'set_obeject_count_by_source', objectCountBySource: countObj })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.tabState])

  useEffect(() => {
    dispatch({
      type: 'set_is_data_gov_manager',
      isDataGovManager: checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData })
    });
    if (!state.dbList) dbListFetch()
    addHistory({ url: window.location.pathname, iconLabel: 'data_quality_test', title: 'Data Quality Insights', subTitle: 'Data Quality Insights', type: 'application' })
    // eslint-disable-next-line
  }, [])


  if (state.dbListLoading) {
    return (
      <div style={{ textAlign: 'center', width: '18.75rem', margin: '20vh auto' }}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.dbListError) {
    return (
      <DeadEnd />
    )
  }

  if (!state.dbList) {
    return <div></div>
  }

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={'Data Quality Insights'}
              subtitle={' '}
              minHeight={100}
              label={'data_quality_test'}
              buttons={[<div style={{ height: 48, margin: 8 }}></div>]}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            state={state}
            dispatch={dispatch}
            tabOptions={tabOptions}
            sessionData={sessionData}
            history={history}
          />
        }
      />
      <SearchResultModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        propObjectType={modalObjectType}
        propQuery={modalQuery}
        propFilter={modalFilter}
        resultItemVariant={"simplified"}
        hiddenComponents={[
          searchComponentNameMap.columnSelector,
          searchComponentNameMap.filterEdit,
          searchComponentNameMap.filter,
          searchComponentNameMap.listTitle,
          searchComponentNameMap.filterReset,
          searchComponentNameMap.searchBar,
          searchComponentNameMap.header,
          searchComponentNameMap.tab,
          searchComponentNameMap.cartButton,
          searchComponentNameMap.downloadButton
        ]}
      />
    </div>
  )
}


DataQualityInsights.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataQualityInsights));
