import React, { useEffect, useState, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import theme from '../../../theme';

function KTooltip(props) {
  const { children, title, interactive, ...other } = props;
  const [visible, setVisible] = useState(false);
  const hideTimeout = useRef(null);
  const showTimeout = useRef(null);
  const tooltipRef = useRef(null);
  const tooltipContainer = useRef(document.createElement('div'));

  const formatTitle = t =>{
    if (typeof t !== 'string') return t;
    if (t.includes('https://')) {
      const titleArr = t.split('https://');
      const formattedTitleArr = titleArr.map((item, index) => {
        if (index === 0) return item;
        let link = item.split(' ')[0];
        let rest = ' ' + item.split(' ').slice(1).join(' ');
        let lastChar = link[link.length - 1];
        if (['.', ','].includes(lastChar)) {
          link = link.slice(0, -1);
          rest = lastChar + rest;
        }
        return (
          <span key={index}>
            <a href={`https://${link}`} style={{ color: theme.palette.hyperLink.main }} target="_blank" rel="noopener noreferrer">https://{link}</a>{rest}
          </span>
        );
      });
      return formattedTitleArr;
    }
    return t;
  }


  useEffect(() => {
    if (visible) {
      document.body.appendChild(tooltipContainer.current);
    } else {
      if (tooltipContainer.current.parentNode) {
        tooltipContainer.current.parentNode.removeChild(tooltipContainer.current);
      }
    }
    return () => {
      if (tooltipContainer.current.parentNode) {
        // eslint-disable-next-line
        tooltipContainer.current.parentNode.removeChild(tooltipContainer.current);
      }
    };
  }, [visible]);

  const showTooltip = (e) => {
    if (!title) return;
    clearTimeout(hideTimeout.current);

    const rect = e.currentTarget.getBoundingClientRect();
    const tooltipWidth = tooltipRef.current ? tooltipRef.current.offsetWidth : 0;
    const tooltipHeight = tooltipRef.current ? tooltipRef.current.offsetHeight : 0;

    const x = rect.left + window.scrollX;
    const y = rect.top + window.scrollY;

    const position = {
      top: y - tooltipHeight,
      bottom: y + rect.height,
      left: x - tooltipWidth,
      right: x + rect.width + 5,
    };

    const isLeft = x < window.innerWidth / 2;

    let pos = {};
    if (isLeft) {
      pos.left = position.right;
      pos.top = y - tooltipHeight / 2;
    } else {
      pos.right = window.innerWidth - position.left - 5 ;
      pos.top = y - tooltipHeight / 2;
    }

    if (interactive) {
      showTimeout.current = setTimeout(() => setVisible(pos), 300);
    } else {
      showTimeout.current = setTimeout(() => setVisible(pos), 80);
    }
  };

  const hideTooltip = () => {
    clearTimeout(showTimeout.current);
    if (interactive) hideTimeout.current = setTimeout(() => setVisible(false), 500)
    else hideTimeout.current = setTimeout(() => setVisible(false), 10);
  };

  const cancelHideTooltip = () => {
    clearTimeout(hideTimeout.current);
  };

  const tooltip = (
    <div
      className={`absolute bg-(--tt-bg) text-(--tt-content) !text-sm p-1 rounded z-[999999] max-w-[400px]`}
      ref={tooltipRef}
      style={visible || {}}
      onMouseEnter={cancelHideTooltip}
      onMouseLeave={hideTooltip}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {formatTitle(title) || ''}
    </div>
  );

  const child = React.Children.only(children);
  const childProps = {
    onMouseEnter: (e) => {
      if (child.props.onMouseEnter) child.props.onMouseEnter(e);
      showTooltip(e);
    },
    onMouseLeave: (e) => {
      if (child.props.onMouseLeave) child.props.onMouseLeave(e);
      hideTooltip(e);
    },
    ...other
  };

  return (
    <>
      {cloneElement(child, childProps)}
      {ReactDOM.createPortal(tooltip, tooltipContainer.current)}
    </>
  );
}

KTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default KTooltip;
