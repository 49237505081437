import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, CircularProgress, Select, MenuItem, Button } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import SummaryTile from '../../UI/DQInsights/UI/SummaryTile';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { collectionIds, getJobResult, removeUrlQueryArg } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import timezoneDetect from 'jstimezonedetect';
import TrendChart from '../Components/TrendChart';
import { defaultFilterMap, searchComponentNameMap } from '../../BasicSearch/MainSearch/Utils/Utils';
import PageController from '../../BasicSearch/MainSearch/PageController/PageController';
import { getIconComponent, getTestScoreColor, onClickResultItem } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import theme from '../../../theme';
import { getIconLabel } from '../../UI/SearchResults/utils';
import useAlert from '../../../hooks/useAlert';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  root: {

  },
  titleSelector: {
    ...theme.components.titleSelector,
    marginRight: 8,
    maxWidth: '80vw',
  },
  header: {
    fontSize: 20,
    color: theme.palette.header.main,
  },
  caption: {
    fontSize: 12,
    marginTop: 4,
    color: theme.palette.primaryText.light,
    marginBottom: 24,
    whiteSpace: 'pre-wrap'
  },

  columnHeader: {
    color: theme.palette.primary.main,
    letterSpacing: 2,
    fontSize: 12,
    marginRight: 16,
  },
  itemText: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
    marginRight: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: 47,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover': {
      background: theme.palette.hovered.main,
      cursor: 'pointer'
    }
  }
})

const ByUseCase = props => {

  const {
    classes,
    state,
    dispatch,
    sessionData,
    history,
    useCaseRole
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(() => {
    return () => {
      isCancelledRef.current = true
    }
  }, [])


  const urlSearch = new URLSearchParams(window.location.search);
  const usecaseName = urlSearch.get('usecase')?.toUpperCase();

  const getScoreByUseCase = async () => {
    dispatch({ type: 'set_dq_by_use_case', dqByUseCaseLoading: true })
    let fq = `${collectionIds.verifiedUseCase}_kc_msrt:*`;
    let ownedStewardedUseCases = []
    if (!state.isDataGovManager) {
      let isError;
      let relationship = 'STEWARD_OF,OWNER_OF';
      if (useCaseRole === 'owner') relationship = 'OWNER_OF';
      if (useCaseRole === 'steward') relationship = 'STEWARD_OF';
      await axiosCerebrum
        .get(
          `/api/users/${sessionData.id}/related/collections`, {
            params: {
              category: 'PLATFORM',
              parent_name: 'verified use case',
              relationship: relationship,
              per_page: 200
            }
        }
        )
        .then(response => {
          ownedStewardedUseCases = response.data.items;
        })
        .catch(error => {
          console.log(error)
          isError = true
          dispatch({ type: 'set_dq_by_use_case', dqByUseCaseError: true })
        })
      if (isError) return;
      if (ownedStewardedUseCases.length === 0) {
        dispatch({ type: 'set_dq_by_use_case', dqByUseCase: [] })
        return;
      }
      fq = `${collectionIds.verifiedUseCase}_kc_msrt:(${ownedStewardedUseCases.map(el => `"${el.name}"`).join(' OR ')})`;
    }
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`, {
          params: {
            q: "*",
            fq,
            rows: 0,
            'json.facet': {
              'useCases': {
                type: 'terms',
                field: `${collectionIds.verifiedUseCase}_kc_msrt`,
                sort: 'index',
                limit: 200,
                'facet': {
                  'dimensions': {
                    type: 'terms',
                    field: 'data_quality_dimension_srt',
                    limit: 200,
                    mincount: 0,
                    "facet": {
                      "avg": "avg(last_data_quality_score_srt)",
                    }
                  },
                  'overall_avg': "avg(last_data_quality_score_srt)",
                  'object_type': {
                    type: 'terms',
                    field: 'object_type_srt',
                    sort: 'index',
                    limit: 100,
                  }
                }
              }
            }
          }
      }
      )
      .then(response => {
        let useCases = []
        response.data.facets?.useCases?.buckets?.forEach(el => {
          if (ownedStewardedUseCases.length > 0 && !ownedStewardedUseCases.map(el => el.name.toUpperCase()).includes(el.val.toUpperCase())) return;
          let data = {
            useCase: el.val.toUpperCase(),
            tiles: [],
            objectTypes: (el.object_type?.buckets || []).map(el => el.val.toUpperCase()),
          }
          data.tiles.push({
            name: 'overall',
            score: el.overall_avg,
            count: el.count,
          })
          el.dimensions?.buckets?.sort((a, b) => a.val < b.val ? -1 : 1).forEach(d => {
            data.tiles.push({
              name: d.val,
              score: d.avg,
              count: d.count,
            })
          })
          useCases.push(data)
        })
        dispatch({ type: 'set_dq_by_use_case', dqByUseCase: useCases })
        if (useCases.length === 0) return;
        if (usecaseName) {
          dispatch({ type: 'set_selected_use_case', selectedUseCase: usecaseName })
          dispatch({ type: 'set_by_use_case_selected_dimension', byUseCaseSelectedDimension: 'overall' })
          loadTrendChart({ useCaseName: usecaseName, dimension: 'overall' })
          loadRelatedInstances({ useCase: usecaseName })
          window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['usecase'] }));
        } else {
          dispatch({ type: 'set_selected_use_case', selectedUseCase: useCases[0].useCase })
          dispatch({ type: 'set_by_use_case_selected_dimension', byUseCaseSelectedDimension: 'overall' })
          loadTrendChart({ useCaseName: useCases[0].useCase, dimension: 'overall' })
          loadRelatedInstances({ useCase: useCases[0].useCase })
        }
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_dq_by_use_case', dqByUseCaseError: true })
      })
  }

  const loadTrendChart = ({ useCaseName, dimension = state.byUseCaseSelectedDimension, period = state.byUseCasePeriod }) => {
    let cacheID = `${useCaseName}_${dimension}_${period}`
    if (state.byUseCaseTrendCache?.[cacheID]) {
      dispatch({ type: 'set_by_use_case_trend', byUseCaseTrend: state.byUseCaseTrendCache[cacheID] })
      return;
    }

    dispatch({ type: 'set_by_use_case_trend', byUseCaseTrendLoading: true })
    axiosSolr
      .get(
        `/solr/search/select`, {
          params: {
            q: "*",
            fq: `name_srt:"${useCaseName}" AND collection_srt:"VERIFIED USE CASE" and collection_type_srt:PLATFORM`,
            rows: 1
          }
      }
      )
      .then(response => {
        let useCase = response.data.response.docs[0];
        if (!useCase) {
          dispatch({ type: 'set_by_use_case_trend', byUseCaseTrendError: true })
          return;
        }
        axiosCerebrum
          .post(
            '/api/platformbatches/0',
            {
              "adhoc_args": {
                "job": "_0060_metrics",
                'agg_method': ['AVG'],
                "tz": timezoneDetect.determine().name(),
                'object_id': useCase.id,
                'days': period,
                "metric_type": `DATA_QUALITY_${dimension.toUpperCase()}_SCORE`
              }
            }
          )
          .then(response => {
            let id = response.data.id;
            getJobResult(
              id,
              data => {
                dispatch({ type: 'set_by_use_case_trend', byUseCaseTrend: data.result.data })
                dispatch({ type: 'set_by_use_case_trend_cache', byUseCaseTrendCache: { ...state.byUseCaseTrendCache, [cacheID]: data.result.data } })
              },
              () => {
                dispatch({ type: 'set_by_use_case_trend', byUseCaseTrendError: true })
              }
            )
          })
          .catch(error => {
            console.log(error);
            dispatch({ type: 'set_by_use_case_trend', byUseCaseTrendError: true })
          })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_by_use_case_trend', byUseCaseTrendError: true })
      })
  }

  const loadInstanceResults = ({ collection_id, collection_name, sort = state.byUseCaseSort, page = 1 }) => {
    dispatch({ type: 'set_by_use_case_instance_list', byUseCaseInstanceListLoading: true })
    dispatch({ type: 'set_by_use_case_instance_page_num', byUseCaseInstancePageNum: page })
    return axiosSolr
      .get(
        `/solr/data_quality_test_result/select`, {
          params: {
            q: "*",
            fq: `${collection_id}_kc_msrt:*`,
            rows: 0,
            'json.facet': {
              'instances': {
                type: 'terms',
                sort: sort,
                field: `${collection_id}_kc_msrt`,
                // limit:10,
                // offset:(page-1)*10,
                limit: 1000,
                facet: {
                  'overall_avg': "avg(last_data_quality_score_srt)",
                }
              }
            }
          }
      }
      )
      .then(response => {
        let instances = []
        response.data.facets?.instances?.buckets?.forEach(el => {
          instances.push({
            name: el.val,
            score: el.overall_avg,
            collection_txt: collection_name,
          })
        });
        if (instances.length === 0) {
          dispatch({ type: 'set_by_use_case_instance_list', byUseCaseInstanceList: instances })
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`, {
            params: {
              q: "*",
              fq: `collection_srt:"${collection_name}" AND verified_kc_msrt:"${state.selectedUseCase}" AND name_srt:(${instances.map(el => `"${el.name}"`).join(' OR ')})`,
              rows: instances.length,
              fl: 'stewards_txts,owners_txts,name_txt,id'
            }
          }
          )
          .then(details => {
            instances = instances.filter(el => {
              return details.data.response.docs.find(d => d.name_txt.toUpperCase() === el.name.toUpperCase())
            })
            instances.forEach((el, i) => {
              let item = details.data.response.docs.find(d => d.name_txt.toUpperCase() === el.name.toUpperCase())
              instances[i].stewards = item.stewards_txts
              instances[i].owners = item.owners_txts
              instances[i].id = item.id
            })
            dispatch({ type: 'set_by_use_case_instance_list', byUseCaseInstanceList: instances })
          })
          .catch(error => {
            console.log(error)
            dispatch({ type: 'set_by_use_case_instance_list', byUseCaseInstanceList: instances })
          })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_by_use_case_instance_list', byUseCaseInstanceListError: true })
      })
  }

  const loadRelatedInstances = async ({ useCase }) => {
    if (state.byUseCaseCollections?.[useCase]) return;
    let collections = []
    await axiosSolr
      .get(
        `/solr/search/select`, {
          params: {
            q: "*",
            fq: `name_srt:"${useCase}" AND collection_srt:"VERIFIED USE CASE" and collection_type_srt:PLATFORM`,
            rows: 1
          }
      }
      )
      .then(async response => {
        let useCase = response.data.response.docs[0];
        if (!useCase) return;
        await axiosCerebrum
          .get(
            `/api/collectioninstances/${useCase.id}/related/collections`, {
            params: {
              category: 'DATA_MANAGEMENT,DATA_GOVERNANCE',
              sort: 'ALPHABETICAL',
              page: 1,
              parent_flag: true,
              per_page: 500,
            }
          }
          )
          .then(response => {
            collections = response.data.items;
          })
        await axiosCerebrum
          .get(
            `/api/collectioninstances/${useCase.id}/related/collections`, {
            params: {
              category: 'GLOSSARY',
              sort: 'ALPHABETICAL',
              page: 1,
              parent_flag: true,
              per_page: 500,
            }
          }
          )
          .then(response => {
            collections = collections.concat(response.data.items.map(el => ({ ...el, collection_type: 'GLOSSARY' })));
          })
        collections.sort((a, b) => a.name < b.name ? -1 : 1)
      })
      .catch(error => {
        console.log(error)
      })

    if (collections.length === 0) return;
    let collectionFacet = {}
    collections.forEach(el => {
      collectionFacet[`${el.id}`] = {
        type: 'query',
        q: `${el.id}_kc_msrt:*`
      }
    })
    let validCollections = []
    await axiosSolr
      .post(
        `/solr/data_quality_test_result/select`, {
        query: "*",
        filter: `${collectionIds.verifiedUseCase}_kc_msrt:*`,
        limit: 0,
        facet: collectionFacet
      }
      )
      .then(response => {
        collections.forEach(c => {
          // if(response.data.facets[c.id]?.count>0){
          validCollections.push(c)
          // }
        })
      })
      .catch(error => {
        console.log(error)
      })
    dispatch({
      type: "set_by_use_case_collections",
      byUseCaseCollections: {
        ...(state.byUseCaseCollections || {}),
        [useCase]: validCollections
      }
    })
  }

  const onClickInstance = el => {
    if (el.id) {
      onClickResultItem({ item: el, id: el.id, label: 'collection_instance', newWindow: true })
      return;
    };
    axiosSolr
      .get(
        '/solr/search/select', {
          params: {
            q: "*",
            fq: `collection_srt:"${el.collection_txt}" AND name_srt:"${el.name}"`,
            rows: 1
          }
      }
      )
      .then(response => {
        let item = response.data.response.docs[0]
        onClickResultItem({ item: item, id: item.id, label: 'collection_instance', newWindow: true })
      })
      .catch(error => {
        console.log(error)
        sendAlert({ message: 'Error occurred opening profile', type: 'error' })
      })
  }

  const getSortIcon = (field) => {
    if (!state.byUseCaseSort) return <></>
    if (state.byUseCaseSort.includes(field)) return <span style={{ position: 'relative', bottom: -2 }}>{getIconComponent({ label: state.byUseCaseSort === `${field} asc` ? 'up' : 'down', size: 13.75, colour: theme.palette.primary.main })}</span>
  }

  const onClickSort = (field) => {
    let newValue;
    if (state.byUseCaseSort === `${field} asc`) {
      newValue = `${field} desc`
    } else if (state.byUseCaseSort === `${field} desc`) {
      newValue = ''
    } else {
      newValue = `${field} asc`
    }
    dispatch({ type: 'set_by_use_case_sort', byUseCaseSort: newValue })
    loadInstanceResults({
      collection_id: state.byUseCaseSelectedObject,
      collection_name: state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject).name,
      sort: newValue,
      page: 1
    })
  }

  useEffect(() => {
    if (!state.dqByUseCase && !state.dqByUseCaseLoading) {
      getScoreByUseCase();
    } else if (state.dqByUseCase && usecaseName) {
      if (state.dqByUseCase.map(el => el.useCase).includes(usecaseName)) {
        dispatch({ type: 'set_selected_use_case', selectedUseCase: usecaseName })
        dispatch({ type: 'set_by_use_case_selected_dimension', byUseCaseSelectedDimension: 'overall' })
        loadTrendChart({ useCaseName: usecaseName, dimension: 'overall' })
        loadRelatedInstances({ useCase: usecaseName })
        window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['usecase'] }));
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!state.selectedUseCase) return;
    dispatch({ type: 'set_by_use_case_selected_object', byUseCaseSelectedObject: state.dqByUseCase?.find(el => el.useCase === state.selectedUseCase).objectTypes[0] })
    // eslint-disable-next-line
  }, [state.selectedUseCase])

  const sectionHeader = (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
      <Select
        className={classes.titleSelector}
        value={state.selectedUseCase}
        disableUnderline
        onChange={event => {
          dispatch({ type: 'set_selected_use_case', selectedUseCase: event.target.value })
          dispatch({ type: 'set_by_use_case_selected_dimension', byUseCaseSelectedDimension: 'overall' })
          loadTrendChart({ useCaseName: event.target.value, dimension: 'overall' })
          loadRelatedInstances({ useCase: event.target.value })
        }}
      >
        {
          state.dqByUseCase?.map((el, i) => {
            return (
              <MenuItem value={el.useCase}>
                {el.useCase}
              </MenuItem>
            )
          })
        }
      </Select>
      <Typography className={classes.header}>
        DATA QUALITY DASHBOARD
      </Typography>
    </div>
  )

  let propFilter = `verified_kc_msrt:${state.selectedUseCase} AND active_srt:YES AND -dq_score_overall_srt:NO DQ SCORE OVERALL`
  let propColumn = 'dq_score_overall_srt,trust,usage_srt,owner_msrt,stewards_msrt'
  let propSort = 'dq_score_overall_srt desc'
  let collectionType = state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject)?.collection_type
  let instanceNaming = collectionType === 'GLOSSARY' ? "terms" : 'instances'


  const getBody = tabState => {
    if (tabState === 0) {
      return (
        <>
          {
            sectionHeader
          }
          <Typography className={classes.header} style={{ marginRight: 10 }}>
            LATEST {state.selectedUseCase} DQ SCORE BY DIMENSION
          </Typography>
          <Typography className={classes.caption}>
            Average Data quality score for all assets linked to this use case over the last 120 days
          </Typography>
          <div style={{ display: 'flex', marginBottom: 40, flexWrap: 'wrap' }}>
            {
              state.dqByUseCase.find(el => el.useCase === state.selectedUseCase).tiles.map((el, i) => {
                return (
                  <SummaryTile
                    key={i}
                    id={el.name}
                    name={el.name}
                    value={el.score}
                    testCount={el.count}
                    onClick={el.score && !state.byUseCaseTrendLoading ? () => {
                      dispatch({ type: 'set_by_use_case_selected_dimension', byUseCaseSelectedDimension: el.name })
                      loadTrendChart({ useCaseName: state.selectedUseCase, dimension: el.name })
                    } : undefined}
                    isSelected={el.name === state.byUseCaseSelectedDimension}
                  />
                )
              })
            }
          </div>

          <div style={{ marginBottom: 40 }}>
            <Typography className={classes.header}>
              {state.selectedUseCase.toUpperCase()} DQ SCORE OVER
              <Select
                className={classes.titleSelector}
                style={{ marginLeft: 12 }}
                value={state.byUseCasePeriod}
                disableUnderline
                onChange={event => {
                  dispatch({ type: 'set_by_use_case_period', byUseCasePeriod: event.target.value })
                  loadTrendChart({ useCaseName: state.selectedUseCase, dimension: state.byUseCaseSelectedDimension, period: event.target.value })
                }}
              >
                {
                  [30, 90, 365].map(el => (
                    <MenuItem disabled={state.byUseCaseTrendLoading} className={classes.menuItem} value={el}>
                      {el} DAYS
                    </MenuItem>
                  ))
                }
              </Select>
            </Typography>
            <Typography className={classes.caption}>
              Average DQ score for {state.selectedUseCase} over the last {state.byUseCasePeriod} days
            </Typography>
            {
              state.byUseCaseTrendLoading &&
              <div style={{ textAlign: 'center', height: 140 }}>
                <CircularProgress color='secondary' />
              </div>
            }
            {
              state.byUseCaseTrendError &&
              <Typography>Error occurred loading chart</Typography>
            }
            {
              state.byUseCaseTrend &&
              <TrendChart
                data={state.byUseCaseTrend}
              />
            }
          </div>
        </>
      )
    }
    if (tabState === 1) {
      return (
        <>
          {
            sectionHeader
          }
          {
            state.byUseCaseSelectedObject &&
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                <Typography className={classes.header} style={{ marginRight: 10 }}>
                  BREAKDOWN DQ SCORE BY {state.selectedUseCase}
                </Typography>
                <Select
                  className={classes.titleSelector}
                  value={state.byUseCaseSelectedObject}
                  disableUnderline
                  onChange={event => {
                    dispatch({ type: 'set_by_use_case_selected_object', byUseCaseSelectedObject: event.target.value })
                    if (state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === event.target.value)) {
                      loadInstanceResults({
                        collection_id: event.target.value,
                        collection_name: state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === event.target.value).name,
                        page: 1
                      })
                      return;
                    }
                  }}
                >
                  {
                    state.dqByUseCase.find(el => el.useCase === state.selectedUseCase).objectTypes.map((el, i) => {
                      return (
                        <MenuItem value={el}>
                          {el}
                        </MenuItem>
                      )
                    })
                  }
                  {
                    state.byUseCaseCollections?.[state.selectedUseCase]?.map((el, i) => {
                      return (
                        <MenuItem value={el.id}>
                          {el.name.toUpperCase()}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
                <div style={{ flexGrow: 1 }}></div>
                {
                  state.byUseCaseSelectedObject && !state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject) &&
                  <Button
                    color='primary'
                    variant="outlined"
                    onClick={() => history.push(`/basic_search?query=*&object=${state.byUseCaseSelectedObject}&presetFilter=${propFilter}&presetColumn=${propColumn}`)}
                  >
                    OPEN IN SEARCH
                  </Button>
                }
              </div>
              {
                state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject) ?
                  <>
                    <Typography className={classes.caption}>
                      DQ score for {state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject).name} {instanceNaming} linked to this use case. {'\n'}
                    </Typography>
                    {
                      state.byUseCaseInstanceListLoading &&
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress color='secondary' />
                      </div>
                    }
                    {
                      state.byUseCaseInstanceListError &&
                      <Typography>Error occurred loading tests</Typography>
                    }
                    {
                      state.byUseCaseInstanceList?.length === 0 &&
                      <Typography>
                        No {state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject).name} {instanceNaming} with a DQ score.
                        <span style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 6 }} onClick={() => history.push(`/profile/collection/${state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject).id}`)}>Link data or content with DQ score to an {instanceNaming} in the {state.byUseCaseCollections?.[state.selectedUseCase]?.find(el => el.id === state.byUseCaseSelectedObject).name}.</span>
                      </Typography>
                    }
                    {
                      state.byUseCaseInstanceList?.length > 0 &&
                      <>
                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', marginBottom: 8, marginTop: 24 }}>
                          <div style={{ flexGrow: 0, flexShrink: 0, width: 24, marginLeft: 16, marginRight: 16 }}> </div>
                          <Typography className={classes.columnHeader} style={{ flex: '1 1 200px', cursor: 'pointer' }} onClick={() => onClickSort('index')}>
                            NAME {getSortIcon('index')}
                          </Typography>
                          <Typography className={classes.columnHeader} style={{ flex: '0 0 200px', cursor: 'pointer' }} onClick={() => onClickSort('{overall}_avg')}>
                            DQ SCORE OVERALL {getSortIcon('overall_avg')}
                          </Typography>
                          <Typography className={classes.columnHeader} style={{ flex: '0 0 200px' }}>
                            OWNER
                          </Typography>
                          <Typography className={classes.columnHeader} style={{ flex: '0 0 200px' }}>
                            STEWARDS
                          </Typography>
                        </div>
                        {
                          state.byUseCaseInstanceList
                            ?.slice((state.byUseCaseInstancePageNum - 1) * 10, state.byUseCaseInstancePageNum * 10)
                            .map((el, i) => {
                              return (
                                <div className={classes.listItem} onClick={() => onClickInstance(el)}>
                                  <div style={{ flexGrow: 0, flexShrink: 0, width: 24, marginLeft: 16, marginRight: 16 }}>
                                    {getIconComponent({ label: getIconLabel({ label: 'collection_instance', item: el }), size: 24, colour: theme.palette.primaryText.light })}
                                  </div>
                                  <Typography className={classes.itemText} style={{ flex: '1 1 200px' }}>
                                    {el.name}
                                  </Typography>
                                  <Typography className={classes.itemText} style={{ flex: '0 0 200px', color: getTestScoreColor(el.score, true) }}>
                                    {Math.round(el.score)}
                                  </Typography>
                                  <KTooltip title={el.owners?.join(', ')}>
                                    <Typography className={classes.itemText} style={{ flex: '0 0 200px' }}>
                                      {el.owners?.join(', ')}
                                    </Typography>
                                  </KTooltip>
                                  <KTooltip title={el.stewards?.join(', ')}>
                                    <Typography className={classes.itemText} style={{ flex: '0 0 200px' }}>
                                      {el.stewards?.join(', ')}
                                    </Typography>
                                  </KTooltip>
                                </div>
                              )
                            })
                        }
                        <PageController
                          state={{
                            pageNum: state.byUseCaseInstancePageNum,
                            resultsTotal: state.byUseCaseInstanceList?.length,
                          }}
                          onChangePage={page => {
                            // window.scrollTo({top:0,behavior:'smooth'})
                            dispatch({
                              type: 'set_by_use_case_instance_page_num',
                              byUseCaseInstancePageNum: page
                            })
                            // loadInstanceResults({
                            //   collection_id:state.dqbyUseCase.find(el=>el.collection===state.selectedCollection).collection_id,
                            //   collection_name:state.selectedCollection,
                            //   page:page
                            // })
                          }}
                        />
                      </>
                    }
                  </>
                  :
                  <>
                    <Typography className={classes.caption}>
                      DQ score for {state.byUseCaseSelectedObject} linked to this use case. {'\n'}
                    </Typography>
                    <BasicSearch
                      key={state.byUseCaseSelectedObject + state.selectedUseCase}
                      customID={'byUseCaseSearch'}
                      initialView={'main_search'}
                      history={history}
                      alwaysOpenNewTab={true}
                      removeContainerStyle={true}
                      propObjectType={state.byUseCaseSelectedObject}
                      propQuery={`*`}
                      propFilter={propFilter}
                      propSort={propSort}
                      propColumn={propColumn}
                      propSelectedFilters={defaultFilterMap[state.byUseCaseSelectedObject]?.filter(el => !['source_type_srt', 'source_srt'].includes(el)).join(',')}
                      resultItemVariant={"simplified"}
                      hiddenComponents={[
                        searchComponentNameMap.columnSelector,
                        searchComponentNameMap.filterEdit,
                        searchComponentNameMap.listTitle,
                        searchComponentNameMap.searchBar,
                        searchComponentNameMap.header,
                        searchComponentNameMap.tab,
                        searchComponentNameMap.cartButton,
                        searchComponentNameMap.downloadButton
                      ]}
                    />
                  </>
              }
            </>
          }
        </>
      )
    }
  }

  return (
    <div className={classes.root}>
      {
        state.dqByUseCaseLoading &&
        <CircularProgress color='secondary' />
      }
      {
        state.dqByUseCaseError &&
        <Typography>Error occurred loading summary</Typography>
      }
      {
        state.dqByUseCase?.length === 0 &&
        <Typography>No data quality scores available</Typography>
      }
      {
        state.dqByUseCase?.length > 0 && state.selectedUseCase &&
        <div>
          <div style={{ float: 'left', position: 'sticky', top: 186 }}>
            <VerticalTabBar
              tabOptions={['SUMMARY', 'DETAILS']}
              tabState={state.byUseCaseTabState}
              setTabState={value => dispatch({ type: 'set_by_use_case_tab_state', byUseCaseTabState: value })}
            />
          </div>
          <div style={{ marginLeft: 280 }}>
            {getBody(state.byUseCaseTabState)}
          </div>
        </div>
      }
    </div>
  )
}

ByUseCase.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(ByUseCase);
