import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { searchComponentNameMap } from '../../BasicSearch/MainSearch/Utils/Utils';

const styles = theme => ({
  clickableText: {
    ...theme.components.infoBoxFocusTxt
  }
})

function UpdatedItemsList(props) {

  const {
    classes,
    // theme,
    state,
    // dispatch,
    history,
  } = props;

  let params = { ...(state.configs?.params || {}) };
  if (state.configs?.objectIds) {
    params.q = "*";
    params.fq = `id:(${state.configs?.objectIds.join(' OR ')})`
  }
  let filterId = state.configs?.filterId;
  let indexName = 'search';
  if (state.configs?.url) indexName = state.configs.url.split('/')[2];

  let hiddenComponents = [
    searchComponentNameMap.columnSelector,
    searchComponentNameMap.filter,
    searchComponentNameMap.listTitle,
    searchComponentNameMap.searchBar,
    searchComponentNameMap.header,
    searchComponentNameMap.cartButton,
    searchComponentNameMap.downloadButton
  ];
  if (filterId) hiddenComponents.push(searchComponentNameMap.tab)

  return (
    <div className={classes.root}>
      {
        ((typeof (params?.fq) === 'string' && typeof (params?.q) === 'string') || filterId) &&
        <BasicSearch
          key={'updated-item-list'}
          customID={'updated-item-list'}
          initialView={'main_search'}
          indexName={indexName}
          history={history}
          alwaysOpenNewTab={true}
          removeContainerStyle={true}
          propObjectType={'ALL'}
          propSelectedFilters={''}
          isNotSearchPage={true}
          propQuery={'*'}
          forceQ={params?.q}
          forceFq={params?.fq}
          propFilterlistId={filterId}
          disableAutoScrollOnTabChange
          disableDefaultFilters
          resultItemVariant={"simplified"}
          hiddenComponents={hiddenComponents}
        />
      }
      {
        state.configs.instanceId &&
        <div>
          <Typography>
            {state.numberOfItemsToUpdate} asset(s) linked to the instance <span style={{ fontWeight: 700 }}>{state.instanceDetail.name}</span> will be updated.
            You can <span className={classes.clickableText} onClick={() => window.open(`/profile/collection_instance/${state.configs.instanceId}?tabName=RELATED`, '_blank')}>view details</span> in the instance profile page.
          </Typography>
        </div>
      }
    </div>
  )
}

UpdatedItemsList.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(UpdatedItemsList));
