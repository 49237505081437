import React, { useState } from 'react';
import { Typography, Button, } from '@material-ui/core';
import { getIconComponent, getTrustScoreColor, getTrustScoreBand, getUserRoles, getFontColourByBackground } from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../ContextMenu/ContextMenu'
import { useStore } from 'react-redux'
import { addBadgeToIcon, getIconLabel, isShowTrust } from './utils'
import PropTypes from 'prop-types';
import CodeDisplayModal from './CodeDisplayModal';
import KTooltip from '../KTooltip/KTooltip';
import moment from 'moment';
import { palette } from '../../../theme';



function SimpleResultItem(props) {

  const {
    item,
    height,
    label = '',
    iconColour,
    title = '',
    titleColour,
    onTitleClick,
    subTitle,
    rightTitle = 'Trust',
    rightText,
    rightColour,
    onClick,
    showUnderline,
    headObject,
    bodyObject,
    tailObject,
    hideRight,
    footer,
    removeHorizontalPadding,
    iconLabel,
    disableContextMenu,
    keepPropagation,
    alwaysEnableHoverEffect,
    hideIcon
  } = props;

  const store = useStore();
  const sessionData = store.getState().auth?.session_user;
  let roles = sessionData ? getUserRoles(sessionData.user_role) : [];
  const [viewCodeModalOpen, setViewCodeModalOpen] = useState(false)


  if (!item) return <></>

  let shownTitle = title
  if (['column', 'table', 'data_quality_test'].includes(label.toLowerCase()) || (label.toLowerCase() === 'code' && item.code_type_txt === 'QUERY')) {
    let businessName = item.alternate_name_txt || item.alternate_name;
    if (businessName && businessName.trim() !== '') {
      if (!roles.every(el => el === '90')) shownTitle = `${title} (${businessName})`
      else { shownTitle = `${businessName} (${title})` }
    }
  }
  if (shownTitle === undefined) shownTitle = 'Untitled'

  let icon = iconLabel || getIconLabel({ label, item })

  let isDisplayCode = (label.toLowerCase() === 'query' || (label.toLowerCase() === 'code' && item && item.code_type_txt === 'QUERY'))

  let expiry = ['collection_instance', 'warning_notice'].includes(label.toLowerCase()) ? (item.expiry || item.expiry_srt) : undefined
  let isExpired = expiry && moment(expiry).isBefore(moment())
  let expiryBackground = isExpired ? '#BDBDBD' : palette.success.main

  return (
    <div style={{ width: "100%" }}>
      <ContextMenuTrigger id={item.id}>
        <div
          data-test-classname="simple-result-item"
          data-test-id={`simple-result-item-${title.replace(/\s+/g, '-').toLowerCase()}`}
          className={'flex pt-2 pb-2 w-full ' + (onClick ? ' !cursor-pointer hover:bg-(--color-base-200) ' : '') + (alwaysEnableHoverEffect ? ' hover:bg-(--color-base-200)' : '')}
          style={{ height: height || (subTitle && subTitle.trim() !== '' ? undefined : 47), borderBottom: showUnderline ? `1px solid var(--color-base-border)` : undefined, alignItems: subTitle ? 'flex-start' : 'center' }} onClick={event => { if (!keepPropagation) { event.stopPropagation() }; onClick && onClick(event) }}
        >
          {
            !hideIcon &&
            <div style={{ marginLeft: removeHorizontalPadding ? 0 : 16, marginRight: 16, height: 24 }}>
              {
                addBadgeToIcon({
                  icon: getIconComponent({ label: icon, size: 24, colour: iconColour || palette.primary.main }),
                  modification_badge: item.modification_badge_txt,
                  active: item.active_txt || item.active_flag,
                  obj: item
                })
              }
            </div>
          }
          {
            headObject &&
            <div style={{ alignSelf: 'center', flexShrink: 0 }}>
              {headObject}
            </div>
          }
          <div style={{ flexGrow: 1, overflow: 'hidden', marginRight: 16, marginLeft: hideIcon && !removeHorizontalPadding ? 16 : 0 }}>

            <Typography
              data-test-classname="simple-result-item-title"
              style={{
                fontSize: 16,
                color: titleColour || palette.header.main,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textDecoration: onTitleClick ? 'underline' : 'none',
                cursor: onTitleClick ? 'pointer' : 'inherit',
              }}
              onClick={onTitleClick}
            >
              <KTooltip title={shownTitle}>
                <span>
                  {shownTitle}
                </span>
              </KTooltip>
            </Typography>

            <Typography data-test-classname="simple-result-item-subtitle" style={{ fontSize: 13.75, color: palette.primaryText.light, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
              <KTooltip title={subTitle}><span>{subTitle}</span></KTooltip>
            </Typography>

            {
              footer &&
              <KTooltip title={footer}>
                <Typography data-test-classname="simple-result-item-footer" style={{ fontSize: 13.75, color: palette.primaryText.light, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>{footer}</Typography>
              </KTooltip>
            }
          </div>
          {
            bodyObject &&
            <div style={{ marginRight: removeHorizontalPadding ? 0 : 8, alignSelf: 'center', flexShrink: 0 }}>
              {bodyObject}
            </div>
          }
          {
            isDisplayCode &&
            <Button
              variant="outlined"
              color='primary'
              style={{ height: 28, width: 112, flexShrink: 0, marginRight: 16 }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setViewCodeModalOpen(true);
              }}
            >
              VIEW CODE
            </Button>
          }
          {
            !hideRight && (isShowTrust(label) || rightTitle !== 'Trust') &&
            <div style={{ width: 60, textAlign: 'center', flexShrink: 0, marginRight: removeHorizontalPadding ? 0 : 16 }}>
              <Typography style={{ fontSize: 12, color: palette.primaryText.main }}>{rightTitle}</Typography>
              <KTooltip title={rightText}>
                <Typography style={{ fontSize: 13.75, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: rightColour || getTrustScoreColor(rightText) }}>{rightTitle === 'Trust' ? getTrustScoreBand({ trustScore: rightText }) : rightText}</Typography>
              </KTooltip>
            </div>
          }
          {
            expiry &&
            <div className={'h-6 rounded-full flex items-center justify-center mr-2'} style={{ fontSize: 13, padding: '0px 8px', background: expiryBackground, color: getFontColourByBackground(expiryBackground) }}>
              {isExpired ? 'Expired' : 'Active'}
            </div>
          }
          {
            tailObject &&
            <div style={{ marginRight: removeHorizontalPadding ? 0 : 8, alignSelf: 'center', flexShrink: 0 }}>
              {tailObject}
            </div>
          }
        </div>
      </ContextMenuTrigger>

      {
        !disableContextMenu && sessionData &&
        <ContextMenu id={item.id}>
          <CustomMenu
            item={item}
            actions={[
              'open_new_tab',
              'bookmark'
            ]}
          />
        </ContextMenu>
      }

      {
        viewCodeModalOpen && sessionData &&
        <CodeDisplayModal
          item={item}
          modalOpen={viewCodeModalOpen}
          setModalOpen={setViewCodeModalOpen}
          onOpen={onClick}
        />
      }
    </div>
  );

}

SimpleResultItem.propTypes = {
  item: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  height: PropTypes.number,
  iconColour: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColour: PropTypes.string,
  onTitleClick: PropTypes.func,
  subTitle: PropTypes.string.isRequired,
  rightText: PropTypes.string,
  rightColour: PropTypes.string,
  iconLabel: PropTypes.string,
  showUnderline: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClick: PropTypes.func,
  headObject: PropTypes.object,
  bodyObject: PropTypes.object,
  tailObject: PropTypes.object,
  hideRight: PropTypes.bool,
  footer: PropTypes.string,
  removeHorizontalPadding: PropTypes.bool,
  disableContextMenu: PropTypes.bool,
  keepPropagation: PropTypes.bool,
  hideIcon: PropTypes.bool
}

export default SimpleResultItem;
