import React, { useState, useRef, useEffect } from 'react';
import { Typography, withStyles, Select, Button, CircularProgress, MenuItem, Radio } from '@material-ui/core';
import theme from '../../../../theme';
import axiosSolr from '../../../../axios-solr'
import { getSearchQuery, processObjectType } from '../Utils/Utils';
// import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import { formatNumber } from '../../../../utilities';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { formatFilterValue } from './utils';

const styles = theme => ({
  selector: {
    ...theme.components.selector,
    height: 28,
    background: `${theme.palette.background.main} !important`,
    '& div div': {
      paddingLeft: 12,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: 13.75,
      background: `${theme.palette.background.main} !important`,
    },
  },
  rangeTypeSelector: {
    ...theme.components.selector,
    height: 24,
    borderRadius: 18,
    background: `${theme.palette.primary.dark} !important`,
    color: theme.palette.background.main,
    '& svg': {
      color: theme.palette.background.main
    },
    '& div div': {
      fontSize: 12,
      textTransform: "uppercase",
      padding: '0px 28px 0px 12px'
    },
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error.main}`
  },
  selectorRoot: {
    display: 'flex'
  },
  selectMenu: {
    width: 450,
  },
  menu: {
    // marginTop:90,
    marginTop: 58,
    '& ul': {
      paddingTop: 0,
      minWidth: 450,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 390,
      maxWidth: '50vw',
    },
  },
  menuItem: {
    display: 'block',
    padding: '0px 16px',
    minHeight: 51,
    color: theme.palette.primaryText.main,
    '&:hover': {
      background: theme.palette.hovered.main
    },
  },
  checkbox: {
    paddingLeft: 0
  },
  valueList: {
    flexGrow: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0px'
    },
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  selectedMenuItem: {
    backgroundColor: `${theme.palette.background.main} !important`,
    '&:hover': {
      background: `${theme.palette.hovered.main} !important`
    },
  },
  dateSelector: {
    ...theme.components.inputBase,
    height: 48,
    '& div': {
      backgroundColor: 'transparent',
    },
    '& label': {
      color: theme.palette.primaryText.light
    },
  },
  inputBase: {
    height: 48,
    width: 130,
    '& input': {
      padding: "8px 16px",
      color: theme.palette.primaryText.main
    }
  }
});



const DateFilter = props => {

  const {
    classes,
    state,
    dispatch,
    fieldName,
    name,
    type,
    onUpdateFilter,
    width,
    bodyOnly,
    getDefaultParams,
    indexName = 'search',
    forceGlobalFilterStr,
    joinFqs,
    forceGlobalQueryStr
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const changeTimeout = useRef(null);

  const data = state.filterStatus[fieldName];
  const setData = data => dispatch({ type: 'set_filter_status', filterStatus: { ...state.filterStatus, [fieldName]: data } })

  // const loadFilterDetail = async ({
  //   selected,
  //   searchStr,
  //   allFilterFq,
  // }) => {
  //   // get min and max value using facet
  //   let facetObj = {
  //     "max":`max(${fieldName})`,
  //     "min":`min(${fieldName})`
  //   }

  //   let allFilterResponse = await axiosSolr
  //     .post(
  //       `/solr/${indexName}/select`,{
  //         params:{
  //           q:getSearchQuery({queryInput:searchStr, searchMode:state.searchMode}),
  //           ...getDefaultParams(),
  //           fq:allFilterFq,
  //           rows:0,
  //         },
  //         facet:facetObj
  //       }
  //     )
  //   let min = allFilterResponse.data.facets.min;
  //   let max = allFilterResponse.data.facets.max;

  //   setData({
  //     allLoaded:true,
  //     values:[min,max],
  //     selected:selected?.length>0?selected:['between',min,max]
  //   })
  // }


  const loadDateFilter = async ({ selected, searchStr, allFilterFq }) => {
    const queryMap = {
      'LAST DAY': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-1DAY TO NOW/DAY+1DAY]`
      },
      'LAST 7 DAYS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-7DAYS TO NOW/DAY+1DAY]`
      },
      'LAST 30 DAYS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-30DAYS TO NOW/DAY+1DAY]`
      },
      'LAST 90 DAYS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-90DAYS TO NOW/DAY+1DAY]`
      },
      'LAST 6 MONTHS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-6MONTH TO NOW/DAY+1DAY]`
      },
      'LAST YEAR': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-1YEAR TO NOW/DAY+1DAY]`
      },
      'ALL TIME': {
        type: 'query',
        q: `*:*`
      },
      "MIN": `min(${fieldName})`,
      "MAX": `max(${fieldName})`
    }
    let values = [];
    let allFilterResponse = await axiosSolr
      .post(
        `/solr/${indexName}/select`, {
        params: {
          q: forceGlobalQueryStr || getSearchQuery({ queryInput: searchStr, searchMode: state.searchMode }),
          ...getDefaultParams(),
          fq: allFilterFq,
          rows: 0,
        },
        facet: queryMap
      }
      )
    let allFacets = allFilterResponse.data.facets
    if (allFacets) {
      ['LAST DAY', 'LAST 7 DAYS', 'LAST 30 DAYS', 'LAST 90 DAYS', 'LAST 6 MONTHS', 'LAST YEAR', 'ALL TIME'].forEach(el => {
        // if(values.find(v=>v.dispValue===el) || !allFacets[el] || allFacets[el].count===0)return;
        if (values.find(v => v.dispValue === el)) return;
        // if(state.hideEmptyFilterValue && allFacets[el].count===0)return;
        values.push({
          value: el === 'ALL TIME' ? '*' : queryMap[el].q.split(`${fieldName}:`)[1],
          dispValue: el,
          count: allFacets[el]?.count || 0,
          applied: false
        })
      })
      // add min and max
      values.push({
        value: allFacets.MIN,
        dispValue: 'MIN'
      })
      values.push({
        value: allFacets.MAX,
        dispValue: 'MAX'
      })
    }
    setData({
      allLoaded: true,
      values: values,
      selected: selected?.length > 0 ? selected : ['from_today', undefined, undefined],
      type
    })
  }

  const loadFilterData = async ({
    objectType = state.selectedObjectType,
    searchStr = state.mainSearchQuery,
    mainSearchFilters = state.mainSearchFilters,
    tabs = state.searchTabs,
    currentData = data
  }) => {
    let selected = currentData?.selected || [];
    let isCollection = tabs && tabs.find(el => el.objectType === objectType).isCollection;
    let isCollectionType = tabs && tabs.find(el => el.objectType === objectType).isCollectionType;
    let isCodeType = tabs && tabs.find(el => el.objectType === objectType).isCodeType;
    let collectionType;
    if (isCollection) {
      collectionType = tabs && tabs.find(el => el.objectType === objectType).collectionType;
    }
    if (isCollectionType) {
      collectionType = tabs && processObjectType(tabs.find(el => el.objectType === objectType).objectType);
    }
    setData({
      ...currentData,
      loading: true,
      selected
    })

    let fq = '';
    if (indexName === 'search') {
      fq = `object_type_srt:${processObjectType(objectType)}`;
      if (objectType === 'ALL') {
        fq = `object_type_srt:*`
      }
      if (isCollectionType) {
        fq = `collection_type_srt:${processObjectType(objectType)} AND object_type_srt:COLLECTION_INSTANCE`
      }
      if (isCollection) {
        fq = `collection_srt:"${processObjectType(objectType)}" AND collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION_INSTANCE`
      }
      if (isCodeType) {
        fq = `code_type_srt:"${objectType}"  AND object_type_srt:CODE`
      }
    }

    if (forceGlobalFilterStr) {
      fq += `${fq ? ' AND ' : ''}${forceGlobalFilterStr}`
    }
    let allFilterFq = fq;

    let filters = mainSearchFilters.filter(el => el.match(new RegExp(`\\b[-(]?${fieldName}:`)) === null)
    if (filters.length > 0) allFilterFq += `${fq ? ' AND ' : ''}${filters.join(' AND ')}`;

    if (joinFqs) {
      allFilterFq = [
        ...joinFqs,
        allFilterFq
      ]
    }

    try {
      await loadDateFilter({
        selected,
        searchStr,
        allFilterFq
      })
    } catch (error) {
      console.log(error)
      setData({
        error: true
      })
    }
  }

  const onClear = () => {
    if (!data) return;
    if (data.selected.length === 0) return;
    onUpdateFilter({ updatedFieldName: fieldName, updatedSelected: ['from_today', '*'] });
  }

  const onChangeRangeType = type => {
    if (type === data.selected[0]) return;
    let newData = {
      ...data,
      selected: [type, data.values[1]]
    }
    if (type === 'from_today') {
      newData.selected = [type, '*']
    }
    if (type === 'between') {
      let defaultValue = `[${data.values[6].value} TO ${data.values[7].value}]`;
      newData.selected = [type, defaultValue]
    }
    setData(newData)
    onUpdateFilter({
      updatedFieldName: fieldName,
      updatedSelected: newData.selected
    })
  }

  const onChangeRangeValue = (values) => {
    let newData = {
      ...data,
      selected: values
    }
    setData(newData)
    clearTimeout(changeTimeout.current);
    let newValues = [...values]
    changeTimeout.current = setTimeout(() => {
      onUpdateFilter({
        updatedFieldName: fieldName,
        updatedSelected: newValues
      })
    }, 350)
  }


  useEffect(() => {
    if (!bodyOnly) return;
    if (data && data.loading) return;
    loadFilterData({})
    // eslint-disable-next-line
  }, [])

  const getBarPercentage = (values, count) => {
    const maxValue = Math.max(...values.map(el => el.count))
    if (count === 0) return '0%'
    return `${Math.floor(count * 100 / maxValue)}%`
  }

  const getParticalBar = percentage => {
    return (
      <div style={{ width: '100%', height: 3, borderRadius: 1, background: `${theme.palette.primary.main}50` }}>
        <div style={{ height: 3, width: percentage, backgroundColor: theme.palette.primary.main }}></div>
      </div>
    )
  }

  const getUpdatedValue = (value, position) => {
    if (position === 'start') {
      let startDate = moment(value).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      let endDate = data.selected[1].split(' TO ')[1].replace(']', '');
      return `[${startDate} TO ${endDate}]`
    }
    if (position === 'end') {
      let startDate = data.selected[1].split(' TO ')[0].replace('[', '');
      let endDate = moment(value).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      return `[${startDate} TO ${endDate}]`
    }
  }

  const formatCustomValue = (position) => {
    if (position === 'start') {
      let startDate = data.selected[1].split(' TO ')[0]?.replace('[', '');
      return moment(startDate).format('ll')
    }
    if (position === 'end') {
      let endDate = data.selected[1].split(' TO ')[1]?.replace(']', '');
      return moment(endDate).format('ll')
    }
  }

  let isSelected = data && data.selected && data.selected.length > 0

  let options = [
    { dispValue: "Range from today", value: "from_today" },
    { dispValue: "Between", value: "between" }
  ]


  let dropdownBody = (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      {
        data &&
        <div className={classes.valueList}>
          {
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}>
              <Typography color='primary' style={{ fontSize: 13.75, letterSpacing: 2, marginRight: 4 }}>FILTER BY:</Typography>
              <Select
                value={data.selected?.[0] || 'from_today'}
                className={classes.rangeTypeSelector}
                disableUnderline
                renderValue={value => {
                  return options.find(el => el.value === value).dispValue
                }}
                onChange={e => {
                  onChangeRangeType(e.target.value)
                }}
              >
                {
                  options.map(el => (
                    <MenuItem value={el.value}>
                      <span style={{ flexGrow: 1, marginRight: 16 }}>{el.dispValue}</span>
                      {/* {getTypeIcon(el.value)} */}
                    </MenuItem>
                  ))
                }
              </Select>
              <div style={{ flexGrow: 1 }}></div>
              <Button color='secondary' onClick={onClear} style={{ fontSize: 12, padding: 0, minWidth: 0 }}>RESET</Button>
            </div>
          }
          {
            data.error && <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.main, marginTop: 16, paddingLeft: 24 }}>Error occurred loading values</Typography>
          }
          {
            data.values &&
            <div style={{ width: '100%', padding: '0px 16px', boxSizing: "border-box" }}>
              {
                data.selected[0] === 'between' &&
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        className={classes.dateSelector}
                        style={{ margin: 0 }}
                        format='ll'
                        emptyLabel='Select a start date'
                        value={formatCustomValue('start')}
                        placeholder={"Select a start date"}
                        onChange={value => onChangeRangeValue([data.selected[0], getUpdatedValue(value, 'start')])}
                        InputProps={{ disableUnderline: true, className: classes.inputBase }}
                        clearable
                        variant="filled"
                      />
                    </MuiPickersUtilsProvider>
                    <Typography style={{ marginLeft: 16, marginRight: 16 }}>TO</Typography>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        className={classes.dateSelector}
                        style={{ margin: 0 }}
                        format='ll'
                        value={formatCustomValue('end')}
                        placeholder={"Select an end date"}
                        onChange={value => onChangeRangeValue([data.selected[0], getUpdatedValue(value, 'end')])}
                        InputProps={{ disableUnderline: true, className: classes.inputBase }}
                        clearable
                        variant="filled"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {/* <Slider
                    range
                    max={data.values[1]}
                    min={data.values[0]}
                    step={1}
                    defaultValue={[data.values[0],data.values[1]]}
                    onChangeComplete={value=>{
                      setData({
                        ...data,
                        selected:value
                      })
                    }}
                  /> */}
                </>
              }
              {
                data.selected[0] === 'from_today' &&
                data.values.slice(0, -2).map(el => (
                  <MenuItem data-test-id={`search-filter-menuitem-${el.value}`} onClick={() => { if (data.selected.includes(el.value)) return; onChangeRangeValue([data.selected[0], el.value]) }} disableRipple classes={{ selected: classes.selectedMenuItem }} selected={data.selected.includes(el.value)} value={el.value} className={classes.menuItem}>
                    <KTooltip title={`${el.dispValue || el.value} (${formatNumber(el.count).toUpperCase()})`}>
                      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                        <Radio className={classes.checkbox} checked={data.selected.includes(el.value)} color='primary' />
                        <Typography className={classes.ellipsisText} style={{ fontSize: 16, color: theme.palette.primaryText.main }}>{el.dispValue || el.value} ({formatNumber(el.count).toUpperCase()})</Typography>
                      </div>
                    </KTooltip>
                    {getParticalBar(getBarPercentage(data.values, el.count))}
                  </MenuItem>
                ))
              }
            </div>
          }
          <div style={{ textAlign: 'center', marginBottom: 16, marginTop: 8 }}>
            {
              (data.loading) &&
              <CircularProgress color='secondary' />
            }
          </div>
        </div>
      }
    </div>
  )

  if (bodyOnly) return dropdownBody;

  return (
    <div style={{ width }}>
      <Typography style={{ fontSize: 12, marginBottom: -4, marginLeft: 8, position: 'relative', zIndex: 20, width: 'max-content', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: width - 24, background: theme.palette.background.main, color: isSelected ? theme.palette.primary.main : theme.palette.primaryText.light, padding: '0 6px' }}>
        {`${name}`}
      </Typography>
      <Select
        data-test-classname="search-filter"
        data-test-id={`search-filter-${name.toLowerCase()}`}
        className={classes.selector}
        style={{ width: width - 4, borderColor: isSelected ? theme.palette.primary.main : theme.palette.primaryText.light }}
        value={['all']}
        multiple
        classes={{ selectMenu: classes.selectMenu, root: classes.selectorRoot }}
        renderValue={() => {
          let selected = data?.selected;
          let rangeType = selected?.[0]?.toLowerCase();
          if (!selected || !selected[1]) {
            return 'All'
          }
          if (rangeType === 'between') {
            return `${formatCustomValue('start')} TO ${formatCustomValue('end')}`
          }
          if (rangeType === 'from_today') {
            return formatFilterValue({value: selected[1]})
          }
        }}
        disableUnderline
        open={menuOpen}
        onOpen={() => {
          setMenuOpen(true);
          if (!data || !data.values) loadFilterData({})
        }}
        onClose={() => {
          setMenuOpen(false);
          setData({ selected: data ? data.selected : undefined, type })
        }}
        MenuProps={{
          className: classes.menu,
          style: {
            marginTop: type === 'pdate' ? 58 : 80
          }
        }}
      >
        {dropdownBody}
      </Select>
    </div>
  )
}

export default withStyles(styles)(DateFilter);
