import React, { useState, useEffect } from 'react';
import { closeKadaModal, openKadaModal } from '../../../../utilities'
import { defaultColumnMap, formatColumnNames, processObjectType } from '../Utils/Utils'
import { KadaBtn, KadaIconBtn, KadaInput, KadaModal, kButtonVariants } from 'kada-component-library';


const ColumnSelector = props => {

  const {
    state,
    dispatch,
    loadSearchResults
  } = props;

  const [columnSearchValue, setColumnSearchValue] = useState('')
  const [tmpSelectedColumn, setTmpSelectedColumn] = useState([...state.selectedColumns])

  useEffect(() => {
    setTmpSelectedColumn([...state.selectedColumns])
  }, [state.selectedColumns])

  const onSelectColumn = column => {
    let newColumns;
    if (tmpSelectedColumn.includes(column)) {
      newColumns = tmpSelectedColumn.filter(el => el !== column)
    } else {
      newColumns = [...tmpSelectedColumn, column]
    }
    setTmpSelectedColumn(newColumns)
  }

  const onRestoreColumns = () => {

    let tabs = state.searchTabs;
    let objectType = state.selectedObjectType;

    let isCollection = tabs && tabs.find(el => el.objectType === objectType).isCollection;
    let isCollectionType = tabs && tabs.find(el => el.objectType === objectType).isCollectionType;
    let collectionType;
    if (isCollection) {
      collectionType = tabs && tabs.find(el => el.objectType === objectType).collectionType;
    }
    if (isCollectionType) {
      collectionType = tabs && processObjectType(tabs.find(el => el.objectType === objectType).objectType);
    }

    let columnObjType = isCollection ? collectionType === 'PLATFORM' ? 'PLATFORM' : 'COLLECTION_INSTANCE' : isCollectionType ? 'COLLECTION' : objectType;

    if (processObjectType(objectType) === 'WARNING NOTICE') {
      columnObjType = 'WARNING_NOTICE'
    }

    let defaultValue = defaultColumnMap[columnObjType] || defaultColumnMap.DEFAULT

    dispatch({ type: 'set_selected_columns', selectedColumns: defaultValue })
    loadSearchResults({ page: 1, isReload: true, selectedColumns: defaultValue })
    setTmpSelectedColumn(defaultValue)
  }

  const onApplyColumnChange = () => {
    if (tmpSelectedColumn.length === state.selectedColumns.length && tmpSelectedColumn.every(el => state.selectedColumns.includes(el))) {
      closeKadaModal('search-edit-column-modal')
      return;
    }
    dispatch({ type: 'set_selected_columns', selectedColumns: tmpSelectedColumn })
    loadSearchResults({ page: 1, isReload: true, selectedColumns: tmpSelectedColumn })
    dispatch({ type: 'set_is_filter_or_column_changed', isFilterOrColumnChanged: true })
    closeKadaModal('search-edit-column-modal')
  }

  const onCancelColumnChange = () => {
    setTmpSelectedColumn([...state.selectedColumns])
    closeKadaModal('search-edit-column-modal')
  }

  return (
    <>
      <KadaIconBtn
        iconName='display_settings'
        size={32}
        iconColor='var(--color-light-text)'
        id="search-edit-column-dropdown"
        data-test-id="search-edit-column-dropdown"
        onClick={()=>{
          openKadaModal('search-edit-column-modal')
        }}
      />

      <KadaModal id="search-edit-column-modal">
        <div slot="content" className='overflow-auto'>
          <div style={{ width: '100%' }}>
            <KadaInput
              value={columnSearchValue}
              onInput={event => setColumnSearchValue(event.detail.value)}
              placeholder='Search columns'
              isSearch
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
            <p className='text-(--color-primary)' style={{ letterSpacing: 2 }}>{state.selectedColumns.length} SELECTED</p>
            <KadaBtn
              variant={kButtonVariants.secondaryBorderless}
              onClick={onRestoreColumns}
              text='RESET'
            />
          </div>
          <div className='h-[50vh] max-h-[500px] overflow-auto'>
            {
              state.allColumns.filter(el => columnSearchValue === '' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).length === 0 &&
              <p style={{ fontSize: 13.75, marginLeft: 24 }}>No column found</p>
            }
            {
              state.selectedColumns && state.selectedColumns.filter(el => columnSearchValue === '' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).length > 0 &&
              <>
                {
                  [...state.selectedColumns].sort((a, b) => a < b ? -1 : 1).filter(el => columnSearchValue === '' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).map(el => (
                    <div
                      className='flex items-center gap-2 px-2 py-3 cursor-pointer hover:bg-base-200'
                      data-test-classname="search-edit-column-menuitem"
                      onClick={() => onSelectColumn(el)}
                    >
                      <input type="checkbox" checked={tmpSelectedColumn.includes(el)} className="checkbox checkbox-primary checkbox-sm" />
                      {formatColumnNames(el)}
                    </div>
                  ))
                }
                <div className="divider mt-2 mb-2"></div>
              </>
            }
            {
              state.allColumns.filter(el => el !== 'name_txt' && !state.selectedColumns.includes(el)).filter(el => columnSearchValue === '' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).map(el => (
                <div
                  className='flex items-center gap-2 px-2 py-3 cursor-pointer hover:bg-base-200'
                  data-test-classname="search-edit-column-menuitem"
                  onClick={() => onSelectColumn(el)}
                >
                  <input type="checkbox" checked={tmpSelectedColumn.includes(el)} className="checkbox checkbox-primary checkbox-sm" />
                  {formatColumnNames(el)}
                </div>
              ))
            }
          </div>
        </div>
        <div slot='actions'>
          <KadaBtn
            text="APPLY"
            variant={kButtonVariants.primaryBorderless}
            onClick={onApplyColumnChange}
            style={{ marginRight: 12 }}
          >
          </KadaBtn>
          <KadaBtn
            text="CANCEL"
            variant={kButtonVariants.primaryBorderless}
            onClick={onCancelColumnChange}
          >
          </KadaBtn>
        </div>
      </KadaModal>
    </>
  )
}

export default ColumnSelector;
