import React, { useContext } from 'react';
import { BasicSearchContext } from '../../../../containers/BasicSearch/BasicSearch';
import { KadaIconBtn } from 'kada-component-library';
import axiosSolr from '../../../../axios-solr';
import { formatColumnNames, generateSearchFq, getDefaultSearchParams, getSearchParams, getSearchQuery, searchComponentNameMap } from '../Utils/Utils';
import useAlert from '../../../../hooks/useAlert';
import fileDownloader from 'js-file-download';
import ColumnSelector from '../ColumnSelector/ColumnSelector';

const ActionButtons = props => {

  const {
    state,
    indexName,
    forceGlobalFilterStr,
    joinFqs,
    joinVarParams,
    hiddenComponents,
    dispatch,
    loadSearchResults
  } = useContext(BasicSearchContext);

  const {
    sendAlert,
  } = useAlert({});


  const onDownloadAll = () => {
    let q = getSearchQuery({ queryInput: state.mainSearchQuery, searchMode: state.searchMode, indexName });
    let fq = generateSearchFq({
      tabs: state.searchTabs,
      filters: state.mainSearchFilters,
      objectType: state.selectedObjectType,
      urlFilter: state.mainSearchUrlFilter,
      indexName: indexName,
      forceFq: forceGlobalFilterStr,
      joinFqs: joinFqs
    })

    if (state.mainSearchFilters.length > 0) fq += ` AND ${state.mainSearchFilters.join(' AND ')}`;

    let flList = ['NAME:name_txt', 'DESCRIPTION:description', 'LOCATION:location_txt']
    state.selectedColumns.forEach(el => {
      flList.push(`${formatColumnNames(el).replace(/\s/g, '_')}:${el}`)
    })

    if(indexName!=='search'){
      flList = ['*']
    }

    let promises = [];
    sendAlert({ type: 'info', message: 'Download started' })

    promises = [
      axiosSolr.post(`/solr/${indexName}/select`, {
        params: {
          ...getSearchParams({ queryInput: state.mainSearchQuery, searchMode: state.searchMode }),
          ...getDefaultSearchParams({ indexName }),
          q: q,
          fq: fq,
          fl: flList.join(','),
          ...(joinVarParams || {}),
          rows: Math.min(500000, state.resultsTotal),
          wt: 'csv'
        }
      }).then(response => {
        fileDownloader(response.data, `kada_search_results.csv`);
      })
    ]

    Promise
      .all(promises)
      .then(response => {
        if (state.resultsTotal > 500000) {
          sendAlert({ type: 'info', message: 'Download completed. A limit of 500k items can be downloaded at a time' })
          // sendAlert({type:'info',message:'Download completed'})
        } else {
          sendAlert({ type: 'info', message: 'Download completed' })
        }
      })
      .catch(error => {
        console.log(error)
        sendAlert({ type: 'error', message: 'Error occurred exporting results, please try again' })
      })
  }

  const isEmptyPage = !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError;

  return (
    <div className='flex gap-2 flex-shrink-0 flex-grow-0 pt-4'>
      {
        !hiddenComponents.includes(searchComponentNameMap.downloadButton) &&
        <KadaIconBtn
          iconName='download'
          iconColor='var(--color-light-text)'
          size={32}
          onClick={onDownloadAll}
          disabled={isEmptyPage}
          data-test-id="download-button"
        >
        </KadaIconBtn>
      }
      {
        state.resultsTotal > 0 && !hiddenComponents.includes(searchComponentNameMap.columnSelector) &&
        <ColumnSelector state={state} dispatch={dispatch} loadSearchResults={loadSearchResults} />
      }
    </div>
  )
}

export default ActionButtons;
