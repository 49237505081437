import React from 'react';
import {  KadaFilter } from 'kada-component-library';
import axiosSolr from '../../../../axios-solr';

const TargetTypeFilter = props => {

  const {
    targetTypeFilter,
    setTargetTypeFilter,
    targetTypeFilterList,
    setTargetTypeFilterList,
    loadHistory,
  } = props;

  const onChangeTargetTypeFilter = (value) => {
    if (value === targetTypeFilter) return;
    setTargetTypeFilter(value);
    loadHistory({ start: 0, targetType: value });
  }

  const loadtargetTypeFilterList = ({ start = 0 }) => {
    axiosSolr
      .post(
        `/solr/impact_config/select`, {
        params: {
          q: "*",
          fq: `*`,
          rows: 0,
          'json.facet': {
            "target_object_type": {
              type: "terms",
              field: "target_object_type_srt",
              limit: 50,
              offset: start,
              sort: "index asc",
            }
          }
        }
      }
      )
      .then(async res => {
        let buckets = res.data.facets.target_object_type.buckets;
        let list = [];

        buckets.forEach(el => {
          list.push({
            text: el.val,
            value: el.val,
          })
        })

        setTargetTypeFilterList([...(targetTypeFilterList || []), ...list]);
      })
  }

  return (
    <KadaFilter
      placeholder='All'
      value={targetTypeFilter}
      isHidePlaceholderMenuItem
      isHideSearch
      valueDisplayPrefix='Target Type: '
      options={
        [
          { text: 'All', value: 'All' },
          ...(targetTypeFilterList || [])
        ].filter(el => el !== undefined)
      }
      onClick={() => {
        if (!targetTypeFilterList) {
          loadtargetTypeFilterList({ start: 0 });
        }
      }}
      onlyTriggerScrollOnBottom
      onScroll={() => {
        if (targetTypeFilterList?.length > 0 && targetTypeFilterList?.length % 50 === 0) {
          loadtargetTypeFilterList({ start: targetTypeFilterList.length });
        }
      }}
      onChange={e => {
        onChangeTargetTypeFilter(e.detail.value)
      }}
    />
  )
}

export default TargetTypeFilter;
