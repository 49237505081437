export const formatFilterValue = ({ value, type, fieldName, dispName}) => {
  if (value === '[NOW/DAY-1DAY TO NOW/DAY+1DAY]') return 'LAST DAY'
    if (value === '[NOW/DAY-7DAYS TO NOW/DAY+1DAY]') return 'LAST 7 DAYS'
    if (value === '[NOW/DAY-30DAYS TO NOW/DAY+1DAY]') return 'LAST 30 DAYS'
    if (value === '[NOW/DAY-90DAYS TO NOW/DAY+1DAY]') return 'LAST 90 DAYS'
    if (value === '[NOW/DAY-6MONTH TO NOW/DAY+1DAY]') return 'LAST 6 MONTHS'
    if (value === '[NOW/DAY-1YEAR TO NOW/DAY+1DAY]') return 'LAST YEAR'
    if (value === '[* TO *]') return 'ALL'
    if (fieldName === 'max_level_txt') {
      return value
    }
    if (type === 'pint') {
      if (value === null) return 'UNKNOWN'
      if (value === '0') return 'NONE'
      if (value === '1') return 'ONE'
      if (value === '[2 TO *]') return 'MANY'
    }
    if (type === 'plong') {
      if (value === null) return 'UNKNOWN'
      return value.replace(/[[\]]/g, '')
    }
    if (value === null) {
      if (fieldName === 'masked_txt') return 'UNKNOWN'
      return `NO ${dispName.toUpperCase()}`
    }
    return value;
}
