import React, { useContext, useEffect, useRef, useState } from 'react';
import { IAActionTypes, IAContext, viewEnum } from '../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import { KadaBtn, KadaLoadingSpinner, KadaTableHeader, kButtonVariants, kLoadingSpinnerVariants } from 'kada-component-library';
import moment from 'moment';
import axiosSolr from '../../../axios-solr';
import { isInViewport } from '../../../utilities';
import TargetFilter from './Filters/TargetFilter';
import LevelFilter from './Filters/LevelFilter';
import TargetTypeFilter from './Filters/TargetTypeFilter';
import TargetSourceFilter from './Filters/TargetSourceFilter';

const History = props => {

  const {
    state,
    dispatch,
  } = useContext(IAContext);

  const [targetSourceFilter, setTargetSourceFilter] = useState(state.historyStates.targetSource || 'All');
  const [targetSourceFilterList, setTargetSourceFilterList] = useState(state.historyStates.targetSourceFilterList);
  const [targetTypeFilter, setTargetTypeFilter] = useState(state.historyStates.targetTypeFilter || 'All');
  const [targetTypeFilterList, setTargetTypeFilterList] = useState(state.historyStates.targetTypeFilterList);
  const [targetFilter, setTargetFilter] = useState(state.targetObject?.id || state.historyStates.targetFilter || 'All');
  const [targetFilterList, setTargetFilterList] = useState(state.historyStates.targetFilterList);
  const [levelFilter, setLevelFilter] = useState(state.historyStates.levelFilter || 'All');
  const [listSort, setListSort] = useState(state.historyStates.listSort || 'last_completed_at_srt,desc');
  const [historyData, setHistoryData] = useState(state.historyStates.historyData);

  const scrollRef = useRef();

  useEffect(() => {
    return () => {
      dispatch({
        type: IAActionTypes.SET_HISTORY_STATE,
        historyStates: {
          ...state.historyStates,
          targetSource: targetSourceFilter,
          targetSourceFilterList: targetSourceFilterList,
          targetTypeFilter: targetTypeFilter,
          targetTypeFilterList: targetTypeFilterList,
          targetFilter: targetFilter,
          targetFilterList: targetFilterList,
          levelFilter: levelFilter,
          listSort: listSort,
          historyData: historyData,
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const loadHistory = ({
    start = 0,
    level = levelFilter,
    targetSource = targetSourceFilter,
    targetType = targetTypeFilter,
    targetID = targetFilter,
    sort = listSort
  }) => {
    setHistoryData({
      loading: true,
      data: start === 0 ? undefined : historyData?.data,
    })

    let fqArr = []
    if (targetSource !== 'All') {
      fqArr.push(`target_object_source_srt:"${targetSource}"`);
    }
    if (targetType !== 'All') {
      fqArr.push(`target_object_type_srt:"${targetType}"`);
    }
    if (targetID !== 'All') {
      fqArr.push(`target_object_id:${targetID}`);
    }
    if (level !== 'All') {
      fqArr.push(`assessment_level_txt:${level}`);
    }

    axiosSolr
      .post(
        `/solr/impact_config/select`, {
        params: {
          q: "*",
          fq: fqArr.join(' AND '),
          rows: 50,
          start,
          sort: sort.includes(',none') ? undefined : sort.replace(',', ' '),
        }
      }
      )
      .then((res) => {
        setHistoryData({
          loading: false,
          data: {
            ...res.data.response,
            docs: [
              ...(start === 0 ? [] : historyData?.data?.docs || []),
              ...(res.data.response.docs || [])
            ]
          }
        })
      })
      .catch((err) => {
        setHistoryData({
          loading: false,
          error: true
        })
        console.error(err);
      })
  }

  useEffect(() => {
    loadHistory({ start: 0 })
    // eslint-disable-next-line
  }, [])

  const onChangeListSort = (value) => {
    setListSort(value);
    loadHistory({ start: 0, sort: value });
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && historyData?.data?.docs?.length < historyData?.data?.numFound && !historyData?.loading
  }

  window.onscroll = () => {
    if (shouldLoadMore()) {
      loadHistory({ start: historyData?.data?.docs?.length })
    }
  }

  useEffect(() => {
    if (shouldLoadMore()) {
      loadHistory({ start: historyData?.data?.docs?.length })
    }
    // eslint-disable-next-line
  }, [historyData])

  return (
    <div>
      <div
        className='rounded p-4 border border-(--color-base-border) flex items-start mb-6'
      >
        <div
          className='flex-grow'
        >
          <h3 className='mb-2'>About Impact Asssessment</h3>
          <p className='text-(--color-light-text)'>
            blah
          </p>
        </div>

        <KadaBtn
          text="NEW IMPACT ASSESSMENT"
          variant={kButtonVariants.successContained}
          disabled={!state.targetObject}
          tooltip={!state.targetObject ? 'A target filter needs to be selected to run an new IA' : `Configure a new assessment for ${state.targetObject?.name_txt}`}
          onClick={() => {
            if (!state.targetObject) return;
            dispatch({
              type: IAActionTypes.SET_TAB_STATE,
              tabState: viewEnum.configuration
            })
          }}
        />
      </div>

      <div className='flex items-center gap-2 mb-4'>
        <LevelFilter
          levelFilter={levelFilter}
          setLevelFilter={setLevelFilter}
          loadHistory={loadHistory}
        />
        <TargetSourceFilter
          targetSourceFilter={targetSourceFilter}
          setTargetSourceFilter={setTargetSourceFilter}
          targetSourceFilterList={targetSourceFilterList}
          setTargetSourceFilterList={setTargetSourceFilterList}
          loadHistory={loadHistory}
        />
        <TargetTypeFilter
          targetTypeFilter={targetTypeFilter}
          setTargetTypeFilter={setTargetTypeFilter}
          targetTypeFilterList={targetTypeFilterList}
          setTargetTypeFilterList={setTargetTypeFilterList}
          loadHistory={loadHistory}
        />
        <TargetFilter
          targetFilter={targetFilter}
          setTargetFilter={setTargetFilter}
          targetFilterList={targetFilterList}
          setTargetFilterList={setTargetFilterList}
          loadHistory={loadHistory}
        />
      </div>

      {
        historyData?.data?.docs?.length > 0 &&
        <>
          <KadaTableHeader
            tableId='ia-history-table'
            options={[
              { name: 'Assessment Level', sortable: false },
              { name: 'Target', sortable: false },
              { name: 'Location', sortable: false },
              { name: 'Last refreshed', value: "last_completed_at_srt", sortable: true },
              { name: 'Depth limit', sortable: false },
              { name: 'Exp limit', sortable: false },
              { name: '', sortable: false },
            ]}
            onChange={e => {
              onChangeListSort(e.detail.value)
            }}
            value={listSort}
          />
          <table className="table table-wrap" id="ia-history-table">
            <tbody>
              {
                historyData?.data?.docs?.map((el, index) => {
                  return (
                    <tr
                      key={el.id}
                      className="hover:bg-(--color-base-200) cursor-pointer"
                      onClick={() => {
                        dispatch({
                          type: IAActionTypes.SET_SELECTED_CONFIG,
                          selectedConfig: el
                        })
                        dispatch({
                          type: IAActionTypes.SET_TAB_STATE,
                          tabState: viewEnum.result
                        })
                      }}
                    >
                      <td className='w-40'>
                        {el.assessment_level}
                      </td>
                      <td className='w-50'>
                        {el.target_object_name_txt}
                      </td>
                      <td>
                        <p className='w-max max-w-full' data-tip={el.location}>
                          {el.target_object_location_txt}
                        </p>
                      </td>
                      <td className='w-50'>
                        {moment(el.last_completed_at_srt).format('DD/MM/YYYY')}
                      </td>
                      <td className='w-30'>
                        {el.assessment_limit_nsrt}
                      </td>
                      <td className='w-30'>
                        {el.expansion_limit_nsrt}
                      </td>
                      <td className='w-6'>
                        {/* <KadaDropdownBtn
                          iconName='more_horiz'
                          iconOnly
                          variant={kDropdownBtnVariants.roundedIconButton}
                          options={[
                            {
                              text: 'View results',
                              onClick: () => {
                              }
                            }
                          ]}
                        /> */}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </>
      }
      {
        historyData?.data?.docs?.length === 0 &&
        <p>
          No history found
        </p>
      }
      {
        historyData?.error &&
        <p>
          Error occurred loading history
        </p>
      }
      <div ref={scrollRef} className='flex justify-center items-center mt-10'>
        {
          historyData?.loading &&
          <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
        }
      </div>
    </div>
  )
}

export default History;
