import React, { useEffect, useReducer, useState } from 'react';
import { withStyles, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body from '../../components/DataComplexityInsights/Body/Body';
// import axiosCerebrum from '../../axios-cerebrum';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { setInitialState } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import axiosSolr from '../../axios-solr';
import SearchResultModal from '../../components/UI/SearchResultModal/SearchResultModal';
import { globalListenerRef } from '../../GlobalListenerRef';
import { CerebrumLongListLoader } from '../../LongListLoader';
import { defaultFilter } from '../../components/DataComplexityInsights/BySource/loadUtils';
import { searchComponentNameMap } from '../../components/BasicSearch/MainSearch/Utils/Utils';

const styles = theme => ({
});


const initialState = {
  tabState: 0,
  bySourceTabState: 0,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case "set_db_list":
      return {
        ...state,
        dbList: action.dbList,
        dbListLoading: action.dbListLoading,
        dbListError: action.dbListError
      }
    case 'set_object_usage_by_source_map':
      return {
        ...state,
        objectUsageBySourceMap: action.objectUsageBySourceMap
      }
    case 'set_by_source_tab_state':
      return {
        ...state,
        bySourceTabState: action.bySourceTabState
      }
    case 'set_by_source_selected_source':
      return {
        ...state,
        bySourceSelectedSource: action.bySourceSelectedSource
      }
    case 'set_by_source_selected_usage':
      return {
        ...state,
        bySourceSelectedUsage: action.bySourceSelectedUsage
      }
    case 'set_by_source_selected_object':
      return {
        ...state,
        bySourceSelectedObject: action.bySourceSelectedObject
      }
    case 'set_by_source_range_editor_open':
      return {
        ...state,
        bySourceRangeEditorOpen: action.bySourceRangeEditorOpen
      }
    case 'set_graph_data':
      return {
        ...state,
        graphData: action.graphData,
        graphDataLoading: false,
        graphDataError: false
      }
    default:
      throw new Error("Action type not supported");
  }
}

const DataComplexityInsights = props => {


  const {
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  let storedInitialState = { ...setInitialState(pageCache, initialState) };
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  const [modalOpen, setModalOpen] = useState(false)
  const [modalQuery, setModalQuery] = useState(null)
  const [modalFilter, setModalFilter] = useState(null)
  const [modalObjectType, setModalObjectType] = useState(null)
  const [modalColumn, setModalColumn] = useState(null)

  useEffect(() => {
    if (!state) return;
    storePageCache({ cacheID: window.location.href, ...state })
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    const onMsgReceived = (msg) => {
      if (msg.data.modalQuery) {
        setModalQuery(msg.data.modalQuery)
      }
      if (msg.data.modalFilter) {
        setModalFilter(msg.data.modalFilter)
      }
      if (msg.data.modalObject) {
        setModalObjectType(msg.data.modalObject)
      }
      if (msg.data.modalColumn) {
        setModalColumn(msg.data.modalColumn)
      }
      if (msg.data.modalQuery || msg.data.modalFilter || msg.data.modalObject) setModalOpen(true)
    }
    window.removeEventListener('message', globalListenerRef.complexityInsightsMsgListener);
    globalListenerRef.complexityInsightsMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.complexityInsightsMsgListener);
    return (() => { window.removeEventListener('message', globalListenerRef.complexityInsightsMsgListener); })
    // eslint-disable-next-line
  }, [])

  const loadAllSources = () => {
    return new Promise((resolve, reject) => {
      CerebrumLongListLoader({
        url: "/api/sources",
        params: {
          types: 'TOOL,DATABASE,INTERNAL',
          sort: 'ALPHABETICAL',
          active_flag: true
        },
        per_page: 50,
        onFinishLoad: ({ data }) => {
          dispatch({ type: 'set_db_list', dbList: data })
          resolve(data)
        },
        onError: (error) => {
          reject()
        }
      })
    })
  }

  const loadMap = () => {
    return axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: "*",
          fq: defaultFilter,
          rows: 0,
          'json.facet': {
            sources: {
              type: 'terms',
              field: 'source_srt',
              limit: 100,
              'facet': {
                'usage': {
                  type: 'terms',
                  field: 'usage_srt',
                  limit: 10,
                  'facet': {
                    'object': {
                      type: 'terms',
                      field: 'object_type_srt',
                      limit: 30
                    }
                  }
                }
              }
            }
          }
        }
      }
      )
      .then(response => {
        let map = {};
        let objectOrder = {
          'TABLE': 10,
          'COLUMN': 20,
          'CONTENT': 30,
          'CONTENT_CHILD': 40,
          'DATA_PIPELINE': 50,
          'DATASET': 60,
          'DATASET_FIELD': 70,
        }
        let usageOrder = {
          'DAILY': 10,
          'WEEKLY': 20,
          'MONTHLY': 30,
          'INFREQUENT': 40,
          'UNKNOWN': 50
        }
        response.data.facets?.sources?.buckets.forEach(source => {
          let usageMap = {};
          source.usage?.buckets?.sort((a, b) => (usageOrder[a.val] || 100) - (usageOrder[b.val] || 100)).forEach(usage => {
            let objectMap = {};
            usage.object?.buckets?.sort((a, b) => (objectOrder[a.val] || 100) - (objectOrder[b.val] || 100)).forEach(object => {
              objectMap[object.val] = object.count
            })
            usageMap[usage.val] = objectMap
          })
          map[source.val] = usageMap
        })
        return map
      })
  }

  const dbListFetch = () => {
    dispatch({ type: 'set_db_list', dbListLoading: true })
    Promise
      .all([loadAllSources(), loadMap()])
      .then(response => {
        let map = response[1]
        dispatch({ type: 'set_object_usage_by_source_map', objectUsageBySourceMap: map })
        let defaultSource = Object.keys(map)[0];
        dispatch({ type: 'set_by_source_selected_source', bySourceSelectedSource: defaultSource })
        let defaultUsage = Object.keys(map[defaultSource])[0];
        dispatch({ type: 'set_by_source_selected_usage', bySourceSelectedUsage: defaultUsage })
        let defaultObject = Object.keys(map[defaultSource][defaultUsage])[0];
        dispatch({ type: 'set_by_source_selected_object', bySourceSelectedObject: defaultObject })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_db_list', dbListError: true })
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.tabState])

  useEffect(() => {
    if (!state.dbList) dbListFetch()
    addHistory({ url: window.location.pathname, iconLabel: 'insights', title: 'Complexity Insights', subTitle: 'Complexity Insights', type: 'application' })
    // eslint-disable-next-line
  }, [])


  if (state.dbListLoading) {
    return (
      <div style={{ textAlign: 'center', width: '18.75rem', margin: '20vh auto' }}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.dbListError) {
    return (
      <DeadEnd />
    )
  }

  if (!state.dbList || !state.objectUsageBySourceMap) {
    return <div></div>
  }

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={'Complexity Insights'}
              subtitle={' '}
              label='complexity'
              minHeight={100}
            />
          </div>
        )}
        tabBar={
          <TabBar
            tabOptions={['BY SOURCE']}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          Object.keys(state.objectUsageBySourceMap).length === 0 ?
            <div style={{ marginTop: 100 }}>
              <Typography>No data available</Typography>
            </div> :
            <Body
              history={history}
              dispatch={dispatch}
              state={state}
              sessionData={sessionData}
            />
        }
      />
      <SearchResultModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        propObjectType={modalObjectType}
        propColumn={modalColumn}
        propQuery={modalQuery}
        propFilter={modalFilter}
        resultItemVariant={"simplified"}
        hiddenComponents={[
          searchComponentNameMap.columnSelector,
          searchComponentNameMap.filterEdit,
          searchComponentNameMap.filter,
          searchComponentNameMap.listTitle,
          searchComponentNameMap.filterReset,
          searchComponentNameMap.searchBar,
          searchComponentNameMap.header,
          searchComponentNameMap.tab,
          searchComponentNameMap.cartButton,
          searchComponentNameMap.downloadButton
        ]}
      />
    </div>
  )
}


DataComplexityInsights.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataComplexityInsights));
