import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, withTheme, withStyles, Typography, Select, MenuItem, Checkbox } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import { collectionIds, getIconComponent, getTestScoreColor, isInViewport } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import { checkIsDataGov, checkIsDataManager } from '../../../permissionChecker';
import HierarchySearchSelector from '../../UI/SearchSelector/HierarchySearchSelector';
import { getIconLabel } from '../../UI/SearchResults/utils';
import KTooltip from '../../UI/KTooltip/KTooltip';

const styles = theme => ({
  root: {

  },
  columnHeader: {
    color: theme.palette.primary.main,
    letterSpacing: 2,
    fontSize: 12,
    paddingRight: 16,
    minWidth: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  sortIconWrapper: {
    marginLeft: 4,
    height: 16,
    width: 16,
  },
  tableText: {
    color: theme.palette.primaryText.main,
    fontSize: 13.75,
    paddingRight: 16,
    minWidth: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  clickableText: {
    cursor: 'pointer',
    "&:hover": {
      textDecoration: 'underline'
    }
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    height: 48,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
  },
  selector: {
    ...theme.components.titleSelector,
    marginRight: 12,
    maxWidth: 300
  },
  header: {
    fontSize: 20,
    color: theme.palette.header.main,
    marginRight: 12
  }
})

const ByInstance = props => {

  const {
    classes,
    state,
    dispatch,
    history,
    collection,
    theme,
    sessionData
  } = props;

  const scrollRef = useRef()

  const [instanceSearchValue, setInstanceSearchValue] = useState('')

  let mainData, setMainData, sort, setSort, selectedInstances, setSelectedInstances, selectedDimensions, setSelectedDimensions;

  if (collection.id === collectionIds.domain) {
    mainData = state.byDomainData;
    setMainData = d => dispatch({ type: 'set_by_domain_data', byDomainData: d })
    sort = state.byDomainSort;
    setSort = s => dispatch({ type: 'set_by_domain_sort', byDomainSort: s })
    selectedInstances = state.selectedDomains;
    setSelectedInstances = instances => dispatch({ type: 'set_selected_domains', selectedDomains: instances })
    selectedDimensions = state.byDomainSelectedDimensions;
    setSelectedDimensions = dimensions => dispatch({ type: 'set_by_domain_selected_dimensions', byDomainSelectedDimensions: dimensions })
  } else if (collection.id === collectionIds.category) {
    mainData = state.byCategoryData;
    setMainData = d => dispatch({ type: 'set_by_category_data', byCategoryData: d })
    sort = state.byCategorySort;
    setSort = s => dispatch({ type: 'set_by_category_sort', byCategorySort: s })
    selectedInstances = state.selectedCategories;
    setSelectedInstances = instances => dispatch({ type: 'set_selected_categories', selectedCategories: instances })
    selectedDimensions = state.byCategorySelectedDimensions;
    setSelectedDimensions = dimensions => dispatch({ type: 'set_by_category_selected_dimensions', byCategorySelectedDimensions: dimensions })
  } else if (collection.id === collectionIds.verifiedUseCase) {
    mainData = state.byUseCaseData;
    setMainData = d => dispatch({ type: 'set_by_use_case_data', byUseCaseData: d })
    sort = state.byUseCaseSort;
    setSort = s => dispatch({ type: 'set_by_use_case_sort', byUseCaseSort: s })
    selectedInstances = state.selectedUseCases;
    setSelectedInstances = instances => dispatch({ type: 'set_selected_use_cases', selectedUseCases: instances })
    selectedDimensions = state.byUseCaseSelectedDimensions;
    setSelectedDimensions = dimensions => dispatch({ type: 'set_by_use_case_selected_dimensions', byUseCaseSelectedDimensions: dimensions })
  }

  const loadInstances = async ({ offset = 0, filterInstances = selectedInstances }) => {

    setMainData({
      data: offset === 0 ? undefined : mainData?.data,
      isAllLoaded: false,
      loading: true
    })

    let fq = `${collection.id}_kc_msrt:*`;
    let ownedStewardedInstances;
    let isDataGovManager = checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData });
    if (!isDataGovManager) {
      let isError;
      let relationship = 'STEWARD_OF,OWNER_OF';
      await axiosCerebrum
        .get(
          `/api/users/${sessionData.id}/related/collections`, {
          params: {
            category: 'PLATFORM',
            parent_name: collection.name,
            relationship: relationship,
            per_page: 500
          }
        }
        )
        .then(response => {
          ownedStewardedInstances = response.data.items;
        })
        .catch(error => {
          console.log(error)
          isError = true

        })
      if (isError) {
        setMainData({
          error: true
        })
        return;
      }
      ownedStewardedInstances = ownedStewardedInstances.map(el => ({ name: el.name }))

      if (ownedStewardedInstances.length === 0) {
        setMainData({
          data: [],
          isAllLoaded: true
        })
        return;
      }
    }
    if (!isDataGovManager) {
      fq = `${collection.id}_kc_msrt:(${ownedStewardedInstances.map(el => `"${el.name}"`).join(' OR ')})`;
    }

    let facetPayload;
    let dimensionFacet = {
      'dimensions': {
          type: 'terms',
          field: 'data_quality_dimension_srt',
          limit: 200,
          mincount: 0,
          "facet": {
            "avg": "avg(last_data_quality_score_srt)",
          }
        },
        'overall_avg': "avg(last_data_quality_score_srt)",
    }

    if (filterInstances.length > 0) {
      facetPayload = {};
      for (const instance of filterInstances) {
        if(!instance.name) instance.name = instance.name_txt;
        facetPayload[instance.name] = {
          type: 'query',
          q: `${collection.id}_kc_msrt:"${instance.name}"`,
          'facet': dimensionFacet
        }
      }
    } else {
      facetPayload = {
        'instances': {
          type: 'terms',
          field: `${collection.id}_kc_msrt`,
          sort: 'index',
          limit: 10,
          offset,
          'facet': dimensionFacet
        }
      }
    }

    axiosSolr
      .post(
        `/solr/data_quality_test_result/select`, {
        params: {
          q: "*",
          fq,
          rows: 0,
          'json.facet': facetPayload
        }
      })
      .then(response => {
        let instances = []

        if ( filterInstances.length > 0) {
          for (const instance of filterInstances) {
            if(!instance.name) instance.name = instance.name_txt;
            let data = {
              name: instance.name.toUpperCase(),
              dimensions: [],
            }
            data.dimensions.push({
              name: 'overall',
              score: response.data.facets[instance.name].overall_avg,
              count: response.data.facets[instance.name].count,
            })
            response.data.facets[instance.name].dimensions?.buckets?.sort((a, b) => a.val < b.val ? -1 : 1).forEach(d => {
              data.dimensions.push({
                name: d.val,
                score: d.avg,
                count: d.count,
              })
            })
            instances.push(data)
          }
        } else {
          response.data.facets?.instances?.buckets?.forEach(el => {
            let data = {
              name: el.val.toUpperCase(),
              dimensions: [],
            }
            data.dimensions.push({
              name: 'overall',
              score: el.overall_avg,
              count: el.count,
            })
            el.dimensions?.buckets?.sort((a, b) => a.val < b.val ? -1 : 1).forEach(d => {
              data.dimensions.push({
                name: d.val,
                score: d.avg,
                count: d.count,
              })
            })
            instances.push(data)
          })
        }
        // setSelectedDimensions(['overall', ...response.data.facets?.instances?.buckets?.[0]?.dimensions?.buckets?.map(el => el.val) || []])

        setMainData({
          data: [
            ...(offset===0 ? [] : (mainData?.data || [])),
            ...instances
          ],
          isAllLoaded: filterInstances.length > 0 ? true : instances.length < 10,
          loading: false
        })

      })
      .catch(error => {
        console.log(error)
        setMainData({
          error: true
        })
      })
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && !mainData?.error && !mainData?.loading && !mainData?.isAllLoaded
  }

  useEffect(() => {
    if (!mainData?.data && !mainData?.loading) {
      loadInstances({})
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (shouldLoadMore()) {
      loadInstances({ offset: mainData?.data.length })
    }
    // eslint-disable-next-line
  }, [mainData])

  window.onscroll = () => {
    if (shouldLoadMore()) {
      loadInstances({ offset: mainData?.data.length })
    }
  }

  const getSortIcon = field => {
    if (sort.includes(field)) {
      if (sort.includes('asc')) {
        return getIconComponent({ label: 'up', size: 16, colour: theme.palette.primary.main })
      } else {
        return getIconComponent({ label: 'down', size: 16, colour: theme.palette.primary.main })
      }
    }
    return null;
  }

  const onChangeSort = field => {
    if (sort.includes(field)) {
      if (sort.includes('asc')) {
        setSort(`${field} desc`)
      } else {
        setSort(`${field} asc`)
      }
    } else {
      setSort(`${field} asc`)
    }
  }

  const sortList = list => {
    return list.sort((a, b) => {
      let field = sort.split(' ')[0];
      let order = sort.split(' ')[1];
      let valueA, valueB;
      if (field === 'name') {
        valueA = a.name;
        valueB = b.name;
      } else {
        valueA = a.dimensions.find(el => el.name === field)?.score || 0;
        valueB = b.dimensions.find(el => el.name === field)?.score || 0;
      }
      if (order === 'asc') {
        return valueA < valueB ? -1 : 1
      }
      return valueA < valueB ? 1 : -1
    })
  }

  const getScore = (d) => {
    if (d.name !== 'overall' && d.count === 0) {
      return (
        <div style={{ width: 'max-content', fontSize: 13.75, maxWidth: '100%', boxSizing: 'border-box', borderRadius: 2, color: theme.palette.primaryText.main, padding: '2px 8px' }}>
          -
        </div>
      )
    }
    return (
      <div style={{ width: 'max-content', fontSize: 13.75, maxWidth: '100%', boxSizing: 'border-box', borderRadius: 2, color: '#000', padding: '2px 8px', background: getTestScoreColor(Math.round(d.score || 0)) }}>
        {Math.round(d.score || 0)}
      </div>
    )
  }

  const getWarningIcon = d => {
    if (Math.round(d.score || 0) < 50) {
      return (
        <KTooltip title={`Overall DQ score for this ${collection.name} is below 50.`}>
          <div style={{ width: 16, height: 16, marginLeft: 8 }}>
            {getIconComponent({ label: 'warning', size: 16, colour: theme.palette.warning.main })}
          </div>
        </KTooltip>
      )
    }
  }

  let validInstances = mainData?.data;
  // if (selectedInstances.length > 0 && validInstances) {
  //   validInstances = validInstances.filter(el => selectedInstances.find(b => (b.name || b.name_txt).toLowerCase() === el.name.toLowerCase()))
  // }

  const getValidDimensions = dimensions => {
    // return dimensions.filter(el => selectedDimensions.includes(el.name))
    return selectedDimensions
            .map(el => dimensions.find(d => d.name === el) || { name: el, score: 0, count: 0 })
            .sort((a, b) => {
              if( a.name === 'overall') return -1 ;
              if( b.name === 'overall') return 1 ;
              return  a.name < b.name ? -1 : 1;
            })
  }

  let dimensions = mainData?.data?.[0]?.dimensions || [{ name: 'overall' }];

  const onUnselectAll = () => {
    if (selectedDimensions.length === 1 && selectedDimensions.includes('overall')) {
      setSelectedDimensions(['overall', ...dimensions.map(el => el.name).filter(el => el !== 'overall')])
    } else {
      setSelectedDimensions(['overall'])
    }
  }

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
        <Select
          className={classes.selector}
          disableUnderline
          value="placeholder"
          renderValue={() => {
            if (selectedInstances.length === 0) return `ALL ${collection.name.toUpperCase()}(S)`;
            return selectedInstances.map(b => b.name).join(', ').toUpperCase();
          }}
          MenuProps={{
            style: {
              marginTop: 72,
            },
          }}
        >
          <HierarchySearchSelector
            searchValue={instanceSearchValue}
            setSearchValue={setInstanceSearchValue}
            rootId={collection.id}
            isPlugin
            fq={
              `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${collection.name}"` +
              (selectedInstances.length > 0 ? ` AND -id:(${selectedInstances.map(b => b.id).join(' OR ')})` : '')
            }
            rootLabel={'collection'}
            height={32}
            onResultClick={item => {
              if (selectedInstances.map(b => b.id).includes(item.id)) {
                setSelectedInstances(selectedInstances.filter(b => b.id !== item.id))
                loadInstances({ filterInstances: selectedInstances.filter(b => b.id !== item.id) })
              } else {
                setSelectedInstances([...selectedInstances, item])
                loadInstances({ filterInstances: [...selectedInstances, item] })
              }
            }}
            clearable
            autoFocus
            testID="instance-search-input"
            placeholder={`Search for a ${collection.name}`}
            isItemSelected={item => {
              return selectedInstances.map(b => b.id).includes(item.id)
            }}
          />
        </Select>
        <Typography className={classes.header}>BY</Typography>
        <Select
          className={classes.selector}
          disableUnderline
          value={selectedDimensions}
          // onChange={(e) => {
          //   let values = ['overall', ...e.target.value.filter(el => el !== 'overall')];
          //   setSelectedDimensions(values)
          // }}
          renderValue={() => {
            return selectedDimensions.join(', ').toUpperCase();
          }}
          multiple
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 8px', marginBottom: 8, minWidth: 200 }}>
            <Typography style={{ color: theme.palette.primary.main, fontSize: 12, letterSpacing: 1 }}>
              {selectedDimensions.length} SELECTED
            </Typography>
            <Typography onClick={onUnselectAll} style={{ color: theme.palette.secondary.main, fontSize: 12, letterSpacing: 1, cursor: 'pointer' }}>
              {selectedDimensions.length === 1 ? 'SELECT' : 'UNSELECT'} ALL
            </Typography>
          </div>
          <div>
            {
              dimensions.map((el, i) =>
                <MenuItem
                  key={i}
                  value={el.name}
                  disabled={el.name === 'overall'}
                  onClick={() => {
                    if (selectedDimensions.includes(el.name)) {
                      setSelectedDimensions(selectedDimensions.filter(u => u !== el.name))
                    } else {
                      setSelectedDimensions([...selectedDimensions, el.name])
                    }
                  }}
                >
                  <Checkbox color='primary' style={{ paddingLeft: 0 }} checked={selectedDimensions.includes(el.name)} />
                  {el.name.toUpperCase()}
                </MenuItem>
              )
            }
          </div>
        </Select>
      </div>
      {
        validInstances?.length > 0 &&
        <div>
          <div style={{ display: 'flex', width: '100%', height: 24, alignItems: 'center', marginBottom: 8 }}>
            <div style={{ flex: '0 0 24px', marginRight: 24, marginLeft: 8 }}></div>
            <Typography className={classes.columnHeader} style={{ flex: '1 1 120px' }} onClick={() => onChangeSort('name')}>
              NAME
              <div className={classes.sortIconWrapper}>
                {getSortIcon('name')}
              </div>
            </Typography>
            {
              getValidDimensions(mainData?.data[0].dimensions).map((el, i) =>
                <Typography key={i} className={classes.columnHeader} style={{ flex: '1 1 80px' }} onClick={() => onChangeSort(el.name)} >
                  {el.name.toUpperCase()}
                  <div className={classes.sortIconWrapper}>
                    {getSortIcon(el.name)}
                  </div>
                </Typography>
              )
            }
          </div>
          {
            sortList(validInstances || []).map((el, i) =>
              <div
                key={i}
                className={classes.tableRow}
              >
                <div style={{ flex: '0 0 24px', marginRight: 24, marginLeft: 8 }}>
                  {getIconComponent({ label: getIconLabel({ label: 'collection', item: collection }), size: 24, colour: theme.palette.primary.main })}
                </div>
                <Typography
                  className={classes.tableText}
                  style={{ flex: '1 1 120px' }}
                >
                  <span
                    className={classes.clickableText}
                    style={{
                      width: 'max-content',
                      maxWidth: '100%',
                    }}
                    onClick={() => {
                      if (collection.id === collectionIds.domain) history.push(`/insights/data_quality?tabName=BY DOMAIN&domain=${el.name}`)
                      if (collection.id === collectionIds.category) history.push(`/insights/data_quality?tabName=BY CATEGORY&category=${el.name}`)
                      if (collection.id === collectionIds.verifiedUseCase) history.push(`/insights/data_quality?tabName=BY USE CASE&usecase=${el.name}`)
                    }}
                  >
                    {el.name}
                  </span>
                </Typography>
                {
                  getValidDimensions(el.dimensions).map((el) =>
                    <Typography key={i} className={classes.tableText} style={{ flex: '1 1 80px', display: 'flex', alignItems: 'center' }}>
                      {getScore(el)}
                      {el.name === 'overall' ? getWarningIcon(el) : null}
                    </Typography>
                  )
                }
              </div>
            )
          }
        </div>
      }
      <div ref={scrollRef} style={{ marginTop: 8, marginBottom: 24 }}>
        {
          mainData?.loading &&
          <CircularProgress color='secondary' />
        }
      </div>
      {
        mainData?.error &&
        <Typography>
          Error occurred loading data
        </Typography>
      }
      {
        validInstances?.length === 0 && !mainData?.loading && !mainData?.error &&
        <Typography>
          No data found
        </Typography>
      }
    </div>
  )
}

ByInstance.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object,
  collection: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ByInstance));
