import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, CircularProgress, Select, MenuItem, Button} from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import SummaryTile from '../../UI/DQInsights/UI/SummaryTile';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { collectionIds, getJobResult, removeUrlQueryArg } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import timezoneDetect from 'jstimezonedetect';
import TrendChart from '../Components/TrendChart';
import { defaultFilterMap, searchComponentNameMap } from '../../BasicSearch/MainSearch/Utils/Utils';
import PageController from '../../BasicSearch/MainSearch/PageController/PageController';
import { getIconComponent, getTestScoreColor, onClickResultItem } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import theme from '../../../theme';
import { getIconLabel } from '../../UI/SearchResults/utils';
import useAlert from '../../../hooks/useAlert';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  root: {

  },
  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  caption:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    marginTop:4,
    whiteSpace:'pre-wrap'
  },

  columnHeader:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    fontSize:12,
    marginRight:16,
  },
  itemText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main,
      cursor:'pointer'
    }
  }
})

const ByDomain = props => {

  const {
    classes,
    state,
    dispatch,
    sessionData,
    history,
    domainRole,
    combineTabs
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  const urlSearch = new URLSearchParams(window.location.search);
  const domainName = urlSearch.get('domain')?.toUpperCase();

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

  const getScoreByDomain = async () => {
    dispatch({type:'set_dq_by_domain',dqByDomainLoading:true})
    let fq = `${collectionIds.domain}_kc_msrt:*`;
    let ownedStewardedDomains = []
    if(!state.isDataGovManager){
      let isError;
      let relationship = 'STEWARD_OF,OWNER_OF';
      if(domainRole==='owner')relationship = 'OWNER_OF';
      if(domainRole==='steward')relationship = 'STEWARD_OF';
      await axiosCerebrum
        .get(
          `/api/users/${sessionData.id}/related/collections`,{params:{
            category:'PLATFORM',
            parent_name:'domain',
            relationship:relationship,
            per_page:200
          }}
        )
        .then(response=>{
          ownedStewardedDomains = response.data.items;
        })
        .catch(error=>{
          console.log(error)
          isError = true
          dispatch({type:'set_dq_by_domain',dqByDomainError:true})
        })
      if(isError)return;
      if(ownedStewardedDomains.length===0){
        dispatch({type:'set_dq_by_domain',dqByDomain:[]})
        return;
      }
      fq = `${collectionIds.domain}_kc_msrt:(${ownedStewardedDomains.map(el=>`"${el.name}"`).join(' OR ')})`;
    }
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:"*",
          fq,
          rows:0,
          'json.facet':{
            'domains':{
              type:'terms',
              field:`${collectionIds.domain}_kc_msrt`,
              sort:'index',
              limit:500,
              'facet':{
                'dimensions':{
                  type:'terms',
                  field:'data_quality_dimension_srt',
                  limit:200,
                  mincount:0,
                  "facet":{
                    "avg":"avg(last_data_quality_score_srt)",
                  }
                },
                'overall_avg':"avg(last_data_quality_score_srt)",
                'object_type':{
                  type:'terms',
                  field:'object_type_srt',
                  sort:'index',
                  limit:100,
                }
              }
            }
          }
        }}
      )
      .then(response=>{
        let domains = []
        response.data.facets?.domains?.buckets?.forEach(el=>{
          if(ownedStewardedDomains.length>0 && !ownedStewardedDomains.map(el=>el.name.toUpperCase()).includes(el.val.toUpperCase()))return;
          let data = {
            domain:el.val.toUpperCase(),
            tiles:[],
            objectTypes:(el.object_type?.buckets || []).map(el=>el.val.toUpperCase()),
          }
          data.tiles.push({
            name:'overall',
            score:el.overall_avg,
            count:el.count,
          })
          el.dimensions?.buckets?.sort((a,b)=>a.val<b.val?-1:1).forEach(d=>{
            data.tiles.push({
              name:d.val,
              score:d.avg,
              count:d.count,
            })
          })
          domains.push(data)
        })
        dispatch({type:'set_dq_by_domain',dqByDomain:domains})
        if(domains.length===0)return;
        if(domainName && domains.map(el=>el.domain).includes(domainName)){
          dispatch({type:'set_selected_domain',selectedDomain:domainName})
          dispatch({type:'set_by_domain_selected_dimension',byDomainSelectedDimension:'overall'})
          loadRelatedInstances({domain:domainName})
          loadTrendChart({domainName:domainName,dimension:'overall'})
          window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['domain']}));
        }else{
          dispatch({type:'set_selected_domain',selectedDomain:domains[0].domain})
          dispatch({type:'set_by_domain_selected_dimension',byDomainSelectedDimension:'overall'})
          loadRelatedInstances({domain:domains[0].domain})
          loadTrendChart({domainName:domains[0].domain,dimension:'overall'})
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_dq_by_domain',dqByDomainError:true})
      })
  }

  const loadTrendChart = ({domainName, dimension=state.byDomainSelectedDimension, period=state.byDomainPeriod}) => {
    let cacheID = `${domainName}_${dimension}_${period}`

    if(state.byDomainTrendCache?.[cacheID]){
      dispatch({type:'set_by_domain_trend',byDomainTrend:state.byDomainTrendCache[cacheID]})
      return;
    }

    dispatch({type:'set_by_domain_trend',byDomainTrendLoading:true})
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`name_srt:"${domainName}" AND collection_srt:"DOMAIN" and collection_type_srt:PLATFORM`,
          rows:1
        }}
      )
      .then(response=>{
        let domain = response.data.response.docs[0];
        if(!domain){
          dispatch({type:'set_by_domain_trend',byDomainTrendError:true})
          return;
        }
        axiosCerebrum
          .post(
            '/api/platformbatches/0',
            {
              "adhoc_args":{
                "job": "_0060_metrics",
                'agg_method':['AVG'],
                "tz": timezoneDetect.determine().name(),
                'object_id':domain.id,
                'days':period,
                "metric_type":`DATA_QUALITY_${dimension.toUpperCase()}_SCORE`
              }
            }
          )
          .then(response=>{
            let id = response.data.id;
            getJobResult(
              id,
              data=>{
                dispatch({type:'set_by_domain_trend',byDomainTrend:data.result.data})
                dispatch({type:'set_by_domain_trend_cache',byDomainTrendCache:{...state.byDomainTrendCache,[cacheID]:data.result.data}})
              },
              ()=>{
                dispatch({type:'set_by_domain_trend',byDomainTrendError:true})
              }
            )
          })
          .catch(error=>{
            console.log(error);
            dispatch({type:'set_by_domain_trend',byDomainTrendError:true})
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_by_domain_trend',byDomainTrendError:true})
      })
  }

  const loadInstanceResults = ({collection_id, collection_name, sort=state.byDomainSort, page=1}) => {
    dispatch({type:'set_by_domain_instance_list',byDomainInstanceListLoading:true})
    dispatch({type:'set_by_domain_instance_page_num',byDomainInstancePageNum:page})
    return axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:"*",
          fq:`${collection_id}_kc_msrt:*`,
          rows:0,
          'json.facet':{
            'instances':{
              type:'terms',
              sort:sort,
              field:`${collection_id}_kc_msrt`,
              limit:1000,
              facet:{
                'overall_avg':"avg(last_data_quality_score_srt)",
              }
            }
          }
        }}
      )
      .then(response=>{
        let instances = []
        response.data.facets?.instances?.buckets?.forEach(el=>{
          instances.push({
            name:el.val,
            score:el.overall_avg,
            collection_txt: collection_name,
          })
        });
        if(instances.length===0){
          dispatch({type:'set_by_domain_instance_list',byDomainInstanceList:instances})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`collection_srt:"${collection_name}" AND domain_kc_msrt:"${state.selectedDomain}" AND name_srt:(${instances.map(el=>`"${el.name}"`).join(' OR ')})`,
                rows:instances.length,
                fl:'stewards_txts,owners_txts,name_txt,id'
              }
            }
          )
          .then(details=>{
            instances = instances.filter(el=>{
              return details.data.response.docs.find(d=>d.name_txt.toUpperCase()===el.name.toUpperCase())
            })
            instances.forEach((el,i)=>{
                let item = details.data.response.docs.find(d=>d.name_txt.toUpperCase()===el.name.toUpperCase())
                instances[i].stewards = item.stewards_txts
                instances[i].owners = item.owners_txts
                instances[i].id = item.id
              })
            dispatch({type:'set_by_domain_instance_list',byDomainInstanceList:instances})
          })
          .catch(error=>{
            console.log(error)
            dispatch({type:'set_by_domain_instance_list',byDomainInstanceList:instances})
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_by_domain_instance_list',byDomainInstanceListError:true})
      })
  }

  const loadRelatedInstances = async ({domain}) => {
    if(state.byDomainCollections?.[domain])return;
    let collections = []
    await axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`name_srt:"${domain}" AND collection_srt:"DOMAIN" and collection_type_srt:PLATFORM`,
          rows:1
        }}
      )
      .then(async response=>{
        let domain = response.data.response.docs[0];
        if(!domain)return;
        await axiosCerebrum
          .get(
            `/api/collectioninstances/${domain.id}/related/collections`,{
              params:{
                category:'DATA_MANAGEMENT,DATA_GOVERNANCE',
                sort:'ALPHABETICAL',
                page:1,
                parent_flag:true,
                per_page:500,
              }
            }
          )
          .then(response=>{
            collections = response.data.items;
          })
        await axiosCerebrum
          .get(
            `/api/collectioninstances/${domain.id}/related/collections`,{
              params:{
                category:'GLOSSARY',
                sort:'ALPHABETICAL',
                page:1,
                parent_flag:true,
                per_page:500,
              }
            }
          )
          .then(response=>{
            collections = collections.concat(response.data.items.map(el=>({...el,collection_type:'GLOSSARY'})));
          })
        collections.sort((a,b)=>a.name<b.name?-1:1)
      })
      .catch(error=>{
        console.log(error)
      })

    if(collections.length===0)return;
    let collectionFacet = {}
    collections.forEach(el=>{
      collectionFacet[`${el.id}`] = {
        type:'query',
        q:`${el.id}_kc_msrt:*`
      }
    })
    let validCollections = []
    await axiosSolr
      .post(
        `/solr/data_quality_test_result/select`,{
          query:"*",
          filter:`${collectionIds.domain}_kc_msrt:*`,
          limit:0,
          facet:collectionFacet
        }
      )
      .then(response=>{
        collections.forEach(c=>{
          // if(response.data.facets[c.id]?.count>0){
            validCollections.push(c)
          // }
        })
      })
      .catch(error=>{
        console.log(error)
      })
    dispatch({
      type:"set_by_domain_collections",
      byDomainCollections:{
        ...(state.byDomainCollections||{}),
        [domain]:validCollections
      }
    })
  }

  const onClickInstance = el => {
    if(el.id){
      onClickResultItem({item:el,id:el.id,label:'collection_instance',newWindow:true})
      return;
    };
    axiosSolr
      .get(
        '/solr/search/select',{params:{
          q:"*",
          fq:`collection_srt:"${el.collection_txt}" AND name_srt:"${el.name}"`,
          rows:1
        }}
      )
      .then(response=>{
        let item = response.data.response.docs[0]
        onClickResultItem({item:item,id:item.id,label:'collection_instance',newWindow:true})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred opening profile',type:'error'})
      })
  }

  const getSortIcon = (field) => {
    if(!state.byDomainSort)return<></>
    if(state.byDomainSort.includes(field))return <span style={{position:'relative',bottom:-2}}>{getIconComponent({label:state.byDomainSort===`${field} asc`?'up':'down',size:13.75,colour:theme.palette.primary.main})}</span>
  }

  const onClickSort = (field) => {
    let newValue;
    if(state.byDomainSort===`${field} asc`){
      newValue = `${field} desc`
    }else if(state.byDomainSort===`${field} desc`){
      newValue = ''
    }else{
      newValue = `${field} asc`
    }
    dispatch({type:'set_by_domain_sort',byDomainSort:newValue})
    loadInstanceResults({
      collection_id:state.byDomainSelectedObject,
      collection_name:state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject).name,
      sort:newValue,
      page:1
    })
  }

  useEffect(()=>{
    if(!state.dqByDomain && !state.dqByDomainLoading){
      getScoreByDomain();
    }else if(state.dqByDomain && domainName){
      if(state.dqByDomain.map(el=>el.domain).includes(domainName)){
        dispatch({type:'set_selected_domain',selectedDomain:domainName})
        dispatch({type:'set_by_domain_selected_dimension',byDomainSelectedDimension:'overall'})
        loadRelatedInstances({domain:domainName})
        loadTrendChart({domainName:domainName,dimension:'overall'})
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['domain']}));
      }
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!state.selectedDomain)return;
    dispatch({type:'set_by_domain_selected_object',byDomainSelectedObject:state.dqByDomain?.find(el=>el.domain===state.selectedDomain).objectTypes[0]})
  // eslint-disable-next-line
  },[state.selectedDomain])

  const sectionHeader = (
    <div style={{display:'flex',alignItems:'center',marginBottom:24}}>
      <Select
        className={classes.titleSelector}
        value={state.selectedDomain}
        disableUnderline
        onChange={event=>{
          dispatch({type:'set_selected_domain',selectedDomain:event.target.value})
          dispatch({type:'set_by_domain_selected_dimension',byDomainSelectedDimension:'overall'})
          loadTrendChart({domainName:event.target.value,dimension:'overall'})
          loadRelatedInstances({domain:event.target.value})
        }}
      >
        {
          state.dqByDomain?.map((el,i)=>{
            return (
              <MenuItem value={el.domain}>
                {el.domain}
              </MenuItem>
            )
          })
        }
      </Select>
      <Typography className={classes.header}>
        DATA QUALITY DASHBOARD
      </Typography>
    </div>
  )


  let propFilter = `domain_kc_msrt:${state.selectedDomain} AND active_srt:YES AND -dq_score_overall_srt:NO DQ SCORE OVERALL`
  let propColumn = 'dq_score_overall_srt,trust,usage_srt,owner_msrt,stewards_msrt'
  let propSort = 'dq_score_overall_srt desc'
  let collectionType = state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject)?.collection_type
  let instanceNaming = collectionType==='GLOSSARY'?"terms":'instances'


  const getBody = tabState => {
    if(tabState===0){
      return (
        <>
          {
            sectionHeader
          }
          <Typography className={classes.header} style={{marginRight:10}}>
            LATEST {state.selectedDomain} DQ SCORE BY DIMENSION
          </Typography>
          <Typography className={classes.caption}>
            Average Data quality score for all assets linked to this Domain over the last 120 days
          </Typography>
          <div style={{display:'flex',marginBottom:40,flexWrap:'wrap'}}>
            {
              state.dqByDomain.find(el=>el.domain===state.selectedDomain).tiles.map((el,i)=>{
                return(
                  <SummaryTile
                    key={i}
                    id={el.name}
                    name={el.name}
                    value={el.score}
                    testCount={el.count}
                    onClick={el.score && !state.byDomainTrendLoading?()=>{
                      dispatch({type:'set_by_domain_selected_dimension',byDomainSelectedDimension:el.name})
                      loadTrendChart({domainName:state.selectedDomain,dimension:el.name})
                    }:undefined}
                    isSelected={el.name===state.byDomainSelectedDimension}
                  />
                )
              })
            }
          </div>

          <div style={{marginBottom:40}}>
            <Typography className={classes.header}>
              {state.selectedDomain.toUpperCase()} DQ SCORE OVER
              <Select
                className={classes.titleSelector}
                style={{marginLeft:12}}
                value={state.byDomainPeriod}
                disableUnderline
                onChange={event=>{
                  dispatch({type:'set_by_domain_period',byDomainPeriod:event.target.value})
                  loadTrendChart({domainName:state.selectedDomain,period:event.target.value})
                }}
              >
                {
                  [30,90,365].map(el=>(
                    <MenuItem disabled={state.byDomainTrendLoading} className={classes.menuItem} value={el}>
                      {el} DAYS
                    </MenuItem>
                  ))
                }
              </Select>
            </Typography>
            <Typography className={classes.caption}>
              Average DQ score for {state.selectedDomain} over the last {state.byDomainPeriod} days
            </Typography>
            {
              state.byDomainTrendLoading &&
              <div style={{textAlign:'center',height:140}}>
                <CircularProgress color='secondary'/>
              </div>
            }
            {
              state.byDomainTrendError &&
              <Typography>Error occurred loading chart</Typography>
            }
            {
              state.byDomainTrend &&
              <TrendChart
                data={state.byDomainTrend}
              />
            }
          </div>
        </>
      )
    }
    if(tabState===1){
      return (
        <>
          {
            sectionHeader
          }
          {
            state.byDomainSelectedObject &&
            <>
              <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
                <Typography className={classes.header} style={{marginRight:10}}>
                  BREAKDOWN DQ SCORE BY {state.selectedDomain}
                </Typography>
                <Select
                  className={classes.titleSelector}
                  value={state.byDomainSelectedObject}
                  disableUnderline
                  onChange={event=>{
                    dispatch({type:'set_by_domain_selected_object',byDomainSelectedObject:event.target.value})
                    if(state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===event.target.value)){
                      loadInstanceResults({
                        collection_id:event.target.value,
                        collection_name:state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===event.target.value).name,
                        page:1
                      })
                      return;
                    }
                  }}
                >
                  {
                    state.dqByDomain.find(el=>el.domain===state.selectedDomain).objectTypes.map((el,i)=>{
                      return (
                        <MenuItem value={el}>
                          {el}
                        </MenuItem>
                      )
                    })
                  }
                  {
                    state.byDomainCollections?.[state.selectedDomain]?.map((el,i)=>{
                      return (
                        <MenuItem value={el.id}>
                          {el.name.toUpperCase()}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
                <div style={{flexGrow:1}}></div>
                {
                  state.byDomainSelectedObject && !state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject) &&
                  <Button
                    color='primary'
                    variant="outlined"
                    onClick={()=>history.push(`/basic_search?query=*&object=${state.byDomainSelectedObject}&presetFilter=${propFilter}&presetColumn=${propColumn}`)}
                  >
                    OPEN IN SEARCH
                  </Button>
                }
              </div>
              {
                state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject)?
                <>
                  <Typography className={classes.caption}>
                    DQ score for {state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject).name} {instanceNaming} linked to this Domain. {'\n'}
                  </Typography>
                  {
                    state.byDomainInstanceListLoading &&
                    <div style={{textAlign:'center'}}>
                      <CircularProgress color='secondary'/>
                    </div>
                  }
                  {
                    state.byDomainInstanceListError &&
                    <Typography>Error occurred loading tests</Typography>
                  }
                  {
                    state.byDomainInstanceList?.length===0 &&
                    <Typography>
                      No {state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject).name} {instanceNaming} with a DQ score.
                      <span style={{cursor:'pointer',textDecoration:'underline',marginLeft:6}} onClick={()=>history.push(`/profile/collection/${state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject).id}`)}>Link data or content with DQ score to an {instanceNaming} in the {state.byDomainCollections?.[state.selectedDomain]?.find(el=>el.id===state.byDomainSelectedObject).name}.</span>
                    </Typography>
                  }
                  {
                    state.byDomainInstanceList?.length>0 &&
                    <>
                      <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginBottom:8,marginTop:24}}>
                        <div style={{flexGrow:0,flexShrink:0,width:24,marginLeft:16,marginRight:16}}> </div>
                        <Typography className={classes.columnHeader} style={{flex:'1 1 200px',cursor:'pointer'}} onClick={()=>onClickSort('index')}>
                          NAME {getSortIcon('index')}
                        </Typography>
                        <Typography className={classes.columnHeader} style={{flex:'0 0 200px',cursor:'pointer'}} onClick={()=>onClickSort('{overall}_avg')}>
                          DQ SCORE OVERALL {getSortIcon('overall_avg')}
                        </Typography>
                        <Typography className={classes.columnHeader} style={{flex:'0 0 200px'}}>
                          OWNER
                        </Typography>
                        <Typography className={classes.columnHeader} style={{flex:'0 0 200px'}}>
                          STEWARDS
                        </Typography>
                      </div>
                      {
                        state.byDomainInstanceList
                          ?.slice((state.byDomainInstancePageNum-1)*10,state.byDomainInstancePageNum*10)
                          .map((el,i)=>{
                          return (
                            <div className={classes.listItem} onClick={()=>onClickInstance(el)}>
                              <div style={{flexGrow:0,flexShrink:0,width:24,marginLeft:16,marginRight:16}}>
                                {getIconComponent({label:getIconLabel({label:'collection_instance',item:el}),size:24,colour:theme.palette.primaryText.light})}
                              </div>
                              <Typography className={classes.itemText} style={{flex:'1 1 200px'}}>
                                {el.name}
                              </Typography>
                              <Typography className={classes.itemText} style={{flex:'0 0 200px',color:getTestScoreColor(el.score,true)}}>
                                {Math.round(el.score)}
                              </Typography>
                              <KTooltip title={el.owners?.join(', ')}>
                                <Typography className={classes.itemText} style={{flex:'0 0 200px'}}>
                                  {el.owners?.join(', ')}
                                </Typography>
                              </KTooltip>
                              <KTooltip title={el.stewards?.join(', ')}>
                                <Typography className={classes.itemText} style={{flex:'0 0 200px'}}>
                                  {el.stewards?.join(', ')}
                                </Typography>
                              </KTooltip>
                            </div>
                          )
                        })
                      }
                      <PageController
                        state={{
                          pageNum:state.byDomainInstancePageNum,
                          resultsTotal:state.byDomainInstanceList?.length,
                        }}
                        onChangePage={page=>{
                          // window.scrollTo({top:0,behavior:'smooth'})
                          dispatch({
                            type:'set_by_domain_instance_page_num',
                            byDomainInstancePageNum:page
                          })
                          // loadInstanceResults({
                          //   collection_id:state.dqbyDomain.find(el=>el.collection===state.selectedCollection).collection_id,
                          //   collection_name:state.selectedCollection,
                          //   page:page
                          // })
                        }}
                      />
                    </>
                  }
                </>
                :
                <>
                  <Typography className={classes.caption}>
                    DQ score for {state.byDomainSelectedObject} linked to this Domain. {'\n'}
                  </Typography>
                  <BasicSearch
                    key={state.byDomainSelectedObject+state.selectedDomain}
                    customID={'byDomainSearch'}
                    initialView={'main_search'}
                    history={history}
                    alwaysOpenNewTab={true}
                    removeContainerStyle={true}
                    propObjectType={state.byDomainSelectedObject}
                    propQuery={`*`}
                    propFilter={propFilter}
                    propSort={propSort}
                    propColumn={propColumn}
                    propSelectedFilters={defaultFilterMap[state.byDomainSelectedObject]?.filter(el=>!['source_type_srt','source_srt'].includes(el)).join(',')}
                    resultItemVariant={"simplified"}
                    hiddenComponents={[
                      searchComponentNameMap.columnSelector,
                      searchComponentNameMap.filterEdit,
                      searchComponentNameMap.listTitle,
                      searchComponentNameMap.searchBar,
                      searchComponentNameMap.header,
                      searchComponentNameMap.tab,
                      searchComponentNameMap.cartButton,
                      searchComponentNameMap.downloadButton
                    ]}
                  />
                </>
              }
            </>
          }
        </>
      )
    }
  }

  return (
    <div className={classes.root}>
      {
        state.dqByDomainLoading &&
        <CircularProgress color='secondary'/>
      }
      {
        state.dqByDomainError &&
        <Typography>Error occurred loading summary</Typography>
      }
      {
        state.dqByDomain?.length===0 &&
        <Typography>No data quality scores available</Typography>
      }
      {
        state.dqByDomain?.length>0 && state.selectedDomain &&
        <div>
          {
            !combineTabs &&
            <div style={{float:'left',position:'sticky',top:186}}>
              <VerticalTabBar
                tabOptions={['SUMMARY','DETAILS']}
                tabState={state.byDomainTabState}
                setTabState={value=>dispatch({type:'set_by_domain_tab_state',byDomainTabState:value})}
              />
            </div>
          }
          {
            combineTabs?
            <div>
              {getBody(0)}
              {getBody(1)}
            </div>
            :
            <div style={{marginLeft:280}}>
              {getBody(state.byDomainTabState)}
            </div>
          }
        </div>
      }
    </div>
  )
}

ByDomain.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(ByDomain);
