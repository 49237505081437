import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Body from '../../components/IssueDashboard/Body/Body';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { setInitialState } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { addHistory } from '../../HistoryManager';
import SearchResultModal from '../../components/UI/SearchResultModal/SearchResultModal';
import { globalListenerRef } from '../../GlobalListenerRef';
import { searchComponentNameMap } from '../../components/BasicSearch/MainSearch/Utils/Utils';


const initialState = {
  tabState: 0,
  domain: 'all',
  status: 'all',
  priority: 'all',
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_issue_data': {
      return { ...state, issueData: action.issueData }
    }
    case 'set_domain': {
      return { ...state, domain: action.domain }
    }
    case 'set_status': {
      return { ...state, status: action.status }
    }
    case 'set_priority': {
      return { ...state, priority: action.priority }
    }
    default:
      throw new Error("Action type not supported");
  }
}

const IssueDashboard = props => {

  const {
    history,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  let storedInitialState = { ...setInitialState(pageCache, initialState) };
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  const [modalQuery, setModalQuery] = useState(undefined);
  const [modalFilter, setModalFilter] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSort, setModalSort] = useState(undefined);
  const [modalObjectType, setModalObjectType] = useState(undefined);
  const [modalColumn, setModalColumn] = useState(undefined);
  const [modalForceFq, setModalForceFq] = useState(undefined);

  useEffect(() => {
    if (!state) return;
    storePageCache({ cacheID: window.location.href, ...state })
    // eslint-disable-next-line
  }, [state])


  useEffect(() => {
    const onMsgReceived = (msg) => {
      if (msg.data.modalQuery) {
        setModalQuery(msg.data.modalQuery)
      }
      if (msg.data.modalSort) {
        setModalSort(msg.data.modalSort)
      } else {
        setModalSort(undefined)
      }
      if (msg.data.modalFilter) {
        setModalFilter(msg.data.modalFilter)
      }
      if (msg.data.modalObject) {
        setModalObjectType(msg.data.modalObject)
      }
      if (msg.data.modalForceFq) {
        setModalForceFq(msg.data.modalForceFq)
      }
      if (msg.data.modalColumn) {
        setModalColumn(msg.data.modalColumn)
      } else {
        setModalColumn(undefined)
      }
      if (msg.data.modalQuery || msg.data.modalFilter || msg.data.modalObject) setModalOpen(true)
    }
    window.removeEventListener('message', globalListenerRef.gridMsgListener);
    globalListenerRef.gridMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.gridMsgListener);
    return (() => { window.removeEventListener('message', globalListenerRef.gridMsgListener); })
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.tabState])


  useEffect(() => {
    addHistory({ url: window.location.pathname, iconLabel: 'issue_dashboard', title: 'Issue Dashboard', subTitle: 'Issue Dashboard', type: 'application' })
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={'Issue Dashboard'}
              description={'Understand all data Issues that have been rasied or linked to the K platform'}
              minHeight={150}
              label={'issue_dashboard'}
            />
          </div>)}
        body={
          <Body
            history={history}
            dispatch={dispatch}
            state={state}
            sessionData={sessionData}
          />
        }
      />
      <SearchResultModal
        key={modalQuery}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        propObjectType={modalObjectType}
        propQuery={modalQuery}
        propSort={modalSort}
        propFilter={modalFilter}
        resultItemVariant={"simplified"}
        propColumn={modalColumn}
        propSearchMode={'exact'}
        forceFq={modalForceFq}
        hiddenComponents={[
          searchComponentNameMap.columnSelector,
          searchComponentNameMap.filterEdit,
          searchComponentNameMap.filter,
          searchComponentNameMap.listTitle,
          searchComponentNameMap.filterReset,
          searchComponentNameMap.searchBar,
          searchComponentNameMap.header,
          searchComponentNameMap.tab,
          searchComponentNameMap.cartButton,
          searchComponentNameMap.downloadButton
        ]}
      />
    </div>
  )
}


IssueDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['usage', 'governance', 'issue', 'data_load']).isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDashboard);
