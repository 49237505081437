import React, { useContext, useEffect } from 'react';
import { IAContext, viewEnum } from '../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import Result from '../Result/Result';
import Config from '../Config/Config';
import History from '../History/History';

const Body = props => {

  const {
    state
  } = useContext(IAContext);;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.tabState])

  let bodyContent;
  switch (state.tabState) {
    case viewEnum.history:
      bodyContent = <History />
      break;
    case viewEnum.result:
      bodyContent = <Result />
      break;
    case viewEnum.configuration:
      bodyContent = <Config />
      break;
    default:
  }

  return bodyContent
}

export default Body;
