import React, { useContext, useEffect, useRef, useState } from 'react';
import { IAContext } from '../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import BasicSearch, { basicSearchInitialState } from '../../../containers/BasicSearch/BasicSearch';
import { searchComponentNameMap } from '../../BasicSearch/MainSearch/Utils/Utils';
import { KadaBtn, KadaLoadingBar, kButtonVariants, kLoadingBarVariants } from 'kada-component-library';
import { onClickResultItem, trimText } from '../../../utilities';
import { formatFilterValue } from '../../BasicSearch/MainSearch/Filter/utils';
import axiosSolr from '../../../axios-solr';

const Result = props => {

  const {
    history,
    state,
    loadStartingObjects
  } = useContext(IAContext);

  const [assetsSearchCache, setAssetsSearchCache] = useState();
  const [childSearchCache, setChildSearchCache] = useState();

  const [assetOpen, setAssetOpen] = useState(true);
  const [userOpen, setUserOpen] = useState(false);

  const [maxImpactLevel, setMaxImpactLevel] = useState(state.selectedConfig.assessment_limit_nsrt);
  const [impactLevel, setImpactLevel] = useState(state.selectedConfig.assessment_limit_nsrt);
  const [impactLevelSearchTrigger, setImpactLevelSearchTrigger] = useState(state.selectedConfig.assessment_limit_nsrt);
  const impactLevelChangeRef = useRef();

  const loadMaxImpactLevel = () => {
    axiosSolr
      .post(
        `/solr/impact/select`, {
          params: {
            q: "*",
            fq: `configuration_id:${state.selectedConfig.id} AND parent:true`,
            rows: 0,
            'json.facet': {
              'max_impact_level': "max(depth_nsrt)",
            }
          }
      }
      )
      .then((res) => {
        setMaxImpactLevel(res.data.facets.max_impact_level)
        if (res.data.facets.max_impact_level < state.selectedConfig.assessment_limit_nsrt) {
          setImpactLevel(res.data.facets.max_impact_level)
          setImpactLevelSearchTrigger(res.data.facets.max_impact_level)
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  useEffect(() => {
    loadMaxImpactLevel()
    loadStartingObjects({ configId: state.selectedConfig.id })

    // window.history.pushState(null, null, window.location.href);
    // console.log(window.history)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (state.selectedStartingObject) {
      setAssetsSearchCache()
      setChildSearchCache()
    }
  }, [state.selectedStartingObject])

  const hiddenComponents = [
    searchComponentNameMap.listTitle,
    searchComponentNameMap.searchBar,
    searchComponentNameMap.header,
    searchComponentNameMap.cartButton,
    searchComponentNameMap.tab
  ]

  if (state.startingObjectsError) {
    return (
      <div className='w-full mt-10'>
        <KadaLoadingBar
          variant={kLoadingBarVariants.error}
          isStatic
          text="Error loading results"
        />
      </div>
    )
  }

  if (state.startingObjectsLoading) {
    return (
      <div className='w-full mt-10'>
        <KadaLoadingBar
          variant={kLoadingBarVariants.secondary}
          text="Loading results"
        />
      </div>
    )
  }

  if (!state.selectedStartingObject) return <></>

  if (!state.selectedStartingObject) return <p className='mt-10'>Please select a starging object to view result</p>

  let parentGenericFilter = `configuration_id:${state.selectedConfig.id} AND starting_object_id:${state.selectedStartingObject.id} AND parent:true AND depth_nsrt:[0 TO ${impactLevelSearchTrigger}]`

  if(process.env.NODE_ENV === 'development') {
    parentGenericFilter = `configuration_id:${state.selectedConfig.id} AND starting_object_id:${state.selectedStartingObject.id} AND parent:true`
  }

  const getFilterText = (cache = {}) => {
    let status = cache.filterStatus || {};
    let allFilters = cache.allFilters || [];

    let dispArr = [];
    Object.keys(status).forEach((key) => {
      let detail = allFilters.find(el => el.field === key) || {};
      let name = detail.name || key;
      let type = detail.type || 'string';
      let selected = (status[key]?.selected || []).map(el => formatFilterValue({ value: el, type, fieldName: key, dispName: name }))
      if (selected.length > 0) dispArr.push(`${name}: ${selected.join(', ')}`)
    })

    return dispArr;
  }

  return (
    <div>
      <div className="flex items-center mb-6 flex-wrap gap-4">
        <p className='ml-4 w-28'>
          Impact view
        </p>
        <KadaBtn
          text="Impacted assets"
          variant={assetOpen ? kButtonVariants.primarySelected : kButtonVariants.primaryOutlined}
          onClick={() => {
            setUserOpen(false)
            setAssetOpen(true)
          }}
          size="s"
        />
        <KadaBtn
          text="Impacted users"
          variant={userOpen ? kButtonVariants.primarySelected : kButtonVariants.primaryOutlined}
          onClick={() => {
            setAssetOpen(false)
            setUserOpen(true)
          }}
          size="s"
          className="mr-10"
        />
        <div className="flex items-center flex-grow gap-4 ml-4">
          <p
            className='mr-4'
          >
            Impact depth
          </p>
          <span>0</span>
          <div className='tooltip min-w-80' data-tip={impactLevel}>
            <input
              type="range"
              min="0"
              max={maxImpactLevel}
              class="range range-primary"
              style={{
                '--range-thumb-size': '0.5rem',
              }}
              value={impactLevel}
              onChange={(e) => {
                let value = e.target.value
                setImpactLevel(value)
                clearTimeout(impactLevelChangeRef.current)
                impactLevelChangeRef.current = setTimeout(() => {
                  setAssetsSearchCache({
                    ...assetsSearchCache,
                    searchResults: {}
                  })
                  setChildSearchCache({
                    ...childSearchCache,
                    searchResults: {}
                  })
                  setImpactLevelSearchTrigger(value)
                }, 800)
              }}
            />
          </div>
          <span>{maxImpactLevel}</span>
        </div>
      </div>

      <div
        className='flex items-center mb-6 flex-wrap gap-4 px-4 py-2'
      >
        {
          !assetOpen ?
            <p className='w-28'>
              Impacted Assets:
            </p>
            :
            <p className='w-28'>
              Impacted Users:
            </p>
        }
        {
          !assetOpen &&
          getFilterText(assetsSearchCache).map(el => (
            <KadaBtn
              variant={kButtonVariants.baseContentOutlined}
              text={trimText(el, 30)}
              tooltip={el}
              disabled
              size="s"
            />
          ))
        }
        {
          !assetOpen &&
          assetsSearchCache?.mainSearchFilters?.length === 0 &&
          <KadaBtn
            variant={kButtonVariants.baseContentOutlined}
            text={'No filters applied'}
            disabled
            size="s"
          />
        }

        {
          !userOpen &&
          getFilterText(childSearchCache).map(el => (
            <KadaBtn
              variant={kButtonVariants.baseContentOutlined}
              text={trimText(el, 30)}
              tooltip={el}
              disabled
              size="s"
            />
          ))
        }
        {
          !userOpen &&
          childSearchCache?.mainSearchFilters?.length === 0 &&
          <KadaBtn
            variant={kButtonVariants.baseContentOutlined}
            text={'No filters applied'}
            disabled
            size="s"
          />
        }
      </div>

      <div
        tabIndex={0}
        className={`collapse overflow-visible ${assetOpen ? "collapse-open" : 'hidden h-0'}`}
      >
        <div className="collapse-title flex items-center !cursor-default">
          <div className="flex items-center flex-wrap gap-4">
            <p>Impacted Assets:</p>
          </div>
        </div>
        <div className="collapse-content overflow-hidden">
          {
            assetOpen &&
            <BasicSearch
              key={childSearchCache?.mainSearchFq + impactLevelSearchTrigger + state.selectedStartingObject?.id}
              initialView={'main_search'}
              indexName={'search'}
              history={history}
              alwaysOpenNewTab={true}
              removeContainerStyle={true}
              propObjectType={'ALL'}
              joinFqs={[
                "{!join fromIndex=impact from=impact_object_id to=id}{!parent which=$parent_filter filters=$child_fq}",
              ]}
              joinVarParams={{
                parent_filter: parentGenericFilter,
                child_fq: childSearchCache?.mainSearchFq || '*',
              }}
              disableAutoScrollOnTabChange
              disableDefaultFilters
              resultItemVariant={"simplified"}
              hiddenComponents={hiddenComponents}
              propQuery="*"
              propCache={assetsSearchCache}
              propStoreCache={cache => {
                setAssetsSearchCache(cache)
                if (cache?.mainSearchFq !== assetsSearchCache?.mainSearchFq) {
                  setChildSearchCache({
                    ...basicSearchInitialState,
                    ...childSearchCache,
                    searchResults: {}
                  })
                }
              }}
              forceOnItemClick={el => {
                console.log(el)
              }}
            />
          }
        </div>
      </div>

      <div
        tabIndex={0}
        className={`collapse overflow-visible ${userOpen ? "collapse-open" : 'hidden h-0'}`}
      >
        <div className="collapse-title flex items-center !cursor-default">
          <div className="flex items-center flex-wrap gap-4">
            <p>Impacted Users:</p>
          </div>
        </div>
        <div className="collapse-content overflow-hidden">
          {
            userOpen &&
            <BasicSearch
              key={assetsSearchCache?.mainSearchFq + impactLevelSearchTrigger + state.selectedStartingObject?.id}
              initialView={'main_search'}
              indexName={'impact'}
              history={history}
              alwaysOpenNewTab={true}
              removeContainerStyle={true}
              propObjectType={'ALL'}
              joinFqs={[
                `{!child of=parent:true filters=$parent_filters}{!join fromIndex=search from=id to=impact_object_id v=$asset_filters}`,
                '{!collapse field=id}'
              ]}
              propColumn="user_teams_msrt"
              joinVarParams={{
                asset_filters: assetsSearchCache?.mainSearchFq || '*',
                parent_filters: parentGenericFilter
              }}
              disableAutoScrollOnTabChange
              disableDefaultFilters
              resultItemVariant={"simplified"}
              hiddenComponents={hiddenComponents}
              propQuery="*"
              propCache={childSearchCache}
              propStoreCache={cache => {
                if (childSearchCache?.mainSearchFq && cache?.mainSearchFq !== childSearchCache?.mainSearchFq) {
                  setAssetsSearchCache({
                    ...basicSearchInitialState,
                    ...assetsSearchCache,
                    searchResults: {}
                  })
                }
                setChildSearchCache(cache)
              }}
              propSelectedFilters="user_job_type_msrt,user_teams_msrt"
              forceOnItemClick={el => {
                onClickResultItem({
                  item: el,
                  id: el.id,
                  label: 'user',
                  newWindow: true
                })
              }}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default Result;
