import React from 'react';
import {  KadaFilter } from 'kada-component-library';
import axiosSolr from '../../../../axios-solr';

const TargetSourceFilter = props => {

  const {
    targetSourceFilter,
    setTargetSourceFilter,
    targetSourceFilterList,
    setTargetSourceFilterList,
    loadHistory,
  } = props;

  const onChangeTargetSourceFilter = (value) => {
    if (value === targetSourceFilter) return;
    setTargetSourceFilter(value);
    loadHistory({ start: 0, targetSource: value });
  }

  const loadTargetSourceFilterList = ({ start = 0 }) => {
    axiosSolr
      .post(
        `/solr/impact_config/select`, {
        params: {
          q: "*",
          fq: `*`,
          rows: 0,
          'json.facet': {
            "target_object_source": {
              type: "terms",
              field: "target_object_source_srt",
              limit: 50,
              offset: start,
              sort: "index asc",
            }
          }
        }
      }
      )
      .then(async res => {
        let buckets = res.data.facets.target_object_source.buckets;
        let list = [];

        buckets.forEach(el => {
          list.push({
            text: el.val,
            value: el.val,
          })
        })

        setTargetSourceFilterList([...(targetSourceFilterList || []), ...list]);
      })
  }

  return (
    <KadaFilter
      placeholder='All'
      value={targetSourceFilter}
      isHidePlaceholderMenuItem
      isHideSearch
      valueDisplayPrefix='Target Source: '
      options={
        [
          { text: 'All', value: 'All' },
          ...(targetSourceFilterList || [])
        ].filter(el => el !== undefined)
      }
      onClick={() => {
        if (!targetSourceFilterList) {
          loadTargetSourceFilterList({ start: 0 });
        }
      }}
      onlyTriggerScrollOnBottom
      onScroll={() => {
        if (targetSourceFilterList?.length > 0 && targetSourceFilterList?.length % 50 === 0) {
          loadTargetSourceFilterList({ start: targetSourceFilterList.length });
        }
      }}
      onChange={e => {
        onChangeTargetSourceFilter(e.detail.value)
      }}
    />
  )
}

export default TargetSourceFilter;
