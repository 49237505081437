import React, { createContext, useEffect, useReducer } from 'react';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/ImpactAssessmentV2/Body/Body';
import { getUserRoles } from '../../utilities'
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { getAppNameByDirection } from '../../components/ImpactAssessmentV2/utils';
import { KadaBreadCrumb, KadaFilter, KadaLoadingBar, kLoadingBarVariants } from 'kada-component-library';
import axiosSolr from '../../axios-solr';

export const viewEnum = {
  history: 0,
  configuration: 1,
  result: 2
}

const initialState = {
  tabState: viewEnum.history,
  historyStates: {}
}

export const IAActionTypes = {
  SET_TAB_STATE: 'set_tab_state',
  SET_TARGET_OBJECT: 'set_target_object',
  SET_STARTING_OBJECTS: 'set_starting_objects',
  SET_STARTING_OBJECTS_SEARCH: 'set_starting_objects_search',
  SET_SELECTED_STARTING_OBJECT: 'set_selected_starting_object',
  SET_SELECTED_CONFIG: 'set_selected_config',
  SET_HISTORY_STATE: 'set_history_state',
};

function reducer(state, action) {
  switch (action.type) {
    case IAActionTypes.SET_TAB_STATE:
      return {
        ...state,
        tabState: action.tabState
      }
    case IAActionTypes.SET_TARGET_OBJECT:
      return {
        ...state,
        targetObject: action.targetObject,
        targetObjectLoading: action.targetObjectLoading,
        targetObjectError: action.targetObjectError
      }
    case IAActionTypes.SET_STARTING_OBJECTS:
      return {
        ...state,
        startingObjects: action.startingObjects,
        startingObjectsLoading: action.startingObjectsLoading,
        startingObjectsError: action.startingObjectsError
      }
    case IAActionTypes.SET_STARTING_OBJECTS_SEARCH:
      return {
        ...state,
        startingObjectsSearch: action.startingObjectsSearch
      }
    case IAActionTypes.SET_SELECTED_STARTING_OBJECT:
      return {
        ...state,
        selectedStartingObject: action.selectedStartingObject
      }
    case IAActionTypes.SET_SELECTED_CONFIG:
      return {
        ...state,
        selectedConfig: action.selectedConfig
      }
    case IAActionTypes.SET_HISTORY_STATE:
      return {
        ...state,
        historyStates: action.historyStates
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

export const IAContext = createContext()

function ImpactAssessmentV2(props) {
  const {
    history,
    sessionData,
    direction
  } = props;

  const roles = getUserRoles(sessionData.user_role)
  const [state, dispatch] = useReducer(reducer, initialState);

  const urlSearch = new URLSearchParams(window.location.search);
  const targetObjectId = urlSearch.get('targetObjectId');

  const loadTargetObject = () => {
    dispatch({
      type: IAActionTypes.SET_TARGET_OBJECT,
      targetObjectLoading: true,
    })
    axiosSolr
      .post(
        `/solr/search/select`, {
        params: {
          q: "*",
          fq: `id:${targetObjectId}`,
          fl: 'id,object_type_txt,name_txt,location_txt,description',
        }
      })
      .then((res) => {
        if (res.data.response.numFound > 0) {
          dispatch({
            type: IAActionTypes.SET_TARGET_OBJECT,
            targetObject: res.data.response.docs[0]
          })
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: IAActionTypes.SET_TARGET_OBJECT,
          targetObjectError: true,
        })
      })
  }


  const loadStartingObjects = ({ configId = state.selectedConfig?.id, start = 0, search = state.startingObjectsSearch }) => {
    axiosSolr
      .post(
        `/solr/search/select`, {
        params: {
          q: "*",
          fq: [
            "{!join fromIndex=impact from=impact_object_id to=id}{!parent which=$parent_filter}",
            search ? `name_srt:*${search}*` : '*:*',
          ],
          // parent_filter: `configuration_id:${configId} AND depth_nsrt:0 AND parent:true`,
          parent_filter: `configuration_id:${configId} AND impact_object_id:${state.targetObject?.id || state.selectedConfig?.target_object_id || '*'} AND parent:true`,
          fl: 'id, name_txt, location_txt, object_type_txt',
          rows: 50,
          start
        }
      })
      .then((res) => {
        let newData = [
          ...(start === 0 ? [] : (state.startingObjects || [])),
          ...(res.data.response.docs || [])
        ];
        if(!state.selectedStartingObject) {
          dispatch({
            type: IAActionTypes.SET_SELECTED_STARTING_OBJECT,
            selectedStartingObject: newData[0]
          })
        }
        dispatch({
          type: IAActionTypes.SET_STARTING_OBJECTS,
          startingObjects: newData
        })
      })
      .catch((err) => {
        dispatch({
          type: IAActionTypes.SET_STARTING_OBJECTS,
          startingObjectsError: true,
        })
        console.error(err);
      })
  }

  useEffect(() => {
    if (targetObjectId) {
      loadTargetObject();
    }
    window.history.pushState(null, null, window.location.href);
    // eslint-disable-next-line
  }, [])


  window.onpopstate = () => {
    if (state.tabState === viewEnum.result) {
      dispatch({
        type: IAActionTypes.SET_TAB_STATE,
        tabState: viewEnum.history
      })
      window.history.pushState(null, null, window.location.href);
    }
    if (state.tabState === viewEnum.history) {
      history.goBack();
    }
  }

  let buttons = [];
  let title = getAppNameByDirection(direction);
  if (state.tabState === viewEnum.result) {
    title = undefined;
  }
  let breadcrumbs = [
    { name: 'IMPACT ASSESSMENT', onClick: () => dispatch({ type: IAActionTypes.SET_TAB_STATE, tabState: viewEnum.history }) }
  ]
  let subTitle = (
    <KadaBreadCrumb
      breadcrumbs={breadcrumbs}
    />
  )

  if(state.tabState === viewEnum.result) {
    subTitle = (
      <div className="flex items-center gap-1 mb-3">
        <KadaBreadCrumb
          breadcrumbs={breadcrumbs}
        />
        {
          state.startingObjects &&
          <>
            <span>/</span>
            <KadaFilter
              value={state.selectedStartingObject?.id}
              placeholder='Select starting object'
              isHidePlaceholderMenuItem
              options={
                state.startingObjects.map((el) => {
                  return {
                    text: el.name_txt,
                    subText: el.location_txt,
                    icon: el.object_type_txt,
                    value: el.id,
                    object: el
                  }
                })
              }
              autoInputDebounce
              onInput={e=>{
                dispatch({
                  type: IAActionTypes.SET_STARTING_OBJECTS_SEARCH,
                  startingObjectsSearch: e.detail.value
                })
                loadStartingObjects({
                  search: e.detail.value
                })
              }}
              onlyTriggerScrollOnBottom
              onScroll={()=>{
                if(state.startingObjects?.length > 0 && state.startingObjects?.length % 50 === 0) {
                  loadStartingObjects({
                    start: state.startingObjects.length
                  })
                }
              }}
              onChange={e=>{
                dispatch({
                  type: IAActionTypes.SET_SELECTED_STARTING_OBJECT,
                  selectedStartingObject: state.startingObjects.find(item => item.id === e.detail.value)
                })
              }}
            />
          </>
        }
      </div>
    )
  }

  return (
    <IAContext.Provider
      value={{
        state,
        dispatch,
        history,
        direction,
        roles,
        loadStartingObjects,
      }}
    >
      <ProfileLayout
        noPadding
        disableHeaderAutoHeight={true}
        allowHeaderOverflow={state.tabState === viewEnum.result}
        header={
          state.tabState === viewEnum.result ?
          subTitle
          :
          <ProfileHeader
            title={title}
            history={history}
            subtitle={subTitle}
            buttons={buttons}
            // description={description}
            minHeight={30}
            iconLabel={`ia_${direction}`}
          />
        }
        body={
          state.targetObjectLoading ?
            <div className='w-full mt-20'>
              <KadaLoadingBar variant={kLoadingBarVariants.secondary} text="Loading" />
            </div>
            :
            <Body />
        }
      />
    </IAContext.Provider>
  )

}

export default ImpactAssessmentV2;
