import React, { useEffect, useReducer, useState } from 'react';
import { LinearProgress, MenuItem, Select, Typography, withStyles, } from '@material-ui/core';
import Body from '../../components/OwnerInsightsDashboard/Body/Body';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { getUserRoles, instanceIds, setInitialState, tabGroup, toTitleCase, validOwnerObjectNames } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import axiosSolr from '../../axios-solr';
import axiosCerebrum from '../../axios-cerebrum';
import theme from '../../theme';
import TabBar from '../../components/UI/TabBar/TabBar';
import { addHistory } from '../../HistoryManager';
import { globalListenerRef } from '../../GlobalListenerRef';
import SearchResultModal from '../../components/UI/SearchResultModal/SearchResultModal';
import { searchComponentNameMap } from '../../components/BasicSearch/MainSearch/Utils/Utils';


const styles = theme => ({
  userSelector: {
    ...theme.components.titleSelector,
    height: 32,
    marginLeft: 8,
    borderRadius: 3,
    '& div div': {
      fontSize: 13,
      height: 24,
      borderRadius: `12px !important`,
      padding: '0 24px 0 8px',
    },
  },
});


const initialState = {
  tabState: 0,
  // new grid
  //////old stats
  search: {},
  sort: {},
  listData: {},
  page: {},
  runningCode: '',
  subTabState: {},
  viewType: {},
  byDomainPeriod: 30,
  byDomainTabState: 0,
  byDomainTrendCache: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case "set_db_list":
      return {
        ...state,
        dbList: action.dbList,
        dbListError: action.dbListError,
        dbListLoading: action.dbListLoading,
      }
    case "set_usage_count_by_object":
      return {
        ...state,
        usageCountByObject: action.usageCountByObject
      }
    case 'set_tabs':
      return {
        ...state,
        tabs: action.tabs,
        tabsLoading: action.tabsLoading,
        tabsError: action.tabsError
      }
    case 'set_obejct_count_by_source':
      return {
        ...state,
        objectCountBySource: action.objectCountBySource
      }
    case 'set_view_type':
      return {
        ...state,
        viewType: action.viewType
      }
    case 'set_domain_states':
      return {
        ...state,
        domainStates: action.domainStates
      }
    case 'set_viewed_user':
      return {
        ...state,
        viewedUser: action.viewedUser
      }
    case 'set_data_role_users':
      return {
        ...state,
        dataRoleUsers: action.dataRoleUsers,
        dataRoleUsersLoading: action.dataRoleUsersLoading,
        dataRoleUsersError: action.dataRoleUsersError
      }
    ///domain
    case 'set_dq_by_domain':
      return {
        ...state,
        dqByDomain: action.dqByDomain,
        dqByDomainLoading: action.dqByDomainLoading,
        dqByDomainError: action.dqByDomainError,
      }
    case 'set_selected_domain':
      return {
        ...state,
        selectedDomain: action.selectedDomain,
      }
    case 'set_by_domain_tab_state':
      return {
        ...state,
        byDomainTabState: action.byDomainTabState,
      }
    case 'set_by_domain_period':
      return {
        ...state,
        byDomainPeriod: action.byDomainPeriod,
      }
    case 'set_by_domain_trend':
      return {
        ...state,
        byDomainTrend: action.byDomainTrend,
        byDomainTrendLoading: action.byDomainTrendLoading,
        byDomainTrendError: action.byDomainTrendError,
      }
    case 'set_by_domain_trend_cache':
      return {
        ...state,
        byDomainTrendCache: action.byDomainTrendCache,
      }
    case 'set_by_domain_selected_dimension':
      return {
        ...state,
        byDomainSelectedDimension: action.byDomainSelectedDimension,
      }
    case 'set_by_domain_collections':
      return {
        ...state,
        byDomainCollections: action.byDomainCollections,
      }
    case 'set_by_domain_instance_list':
      return {
        ...state,
        byDomainInstanceList: action.byDomainInstanceList,
        byDomainInstanceListLoading: action.byDomainInstanceListLoading,
        byDomainInstanceListError: action.byDomainInstanceListError,
      }
    case 'set_by_domain_sort': {
      return {
        ...state,
        byDomainSort: action.byDomainSort,
      }
    }
    case 'set_by_domain_instance_page_num':
      return {
        ...state,
        byDomainInstancePageNum: action.byDomainInstancePageNum,
      }
    case 'set_by_domain_selected_object':
      return {
        ...state,
        byDomainSelectedObject: action.byDomainSelectedObject,
      }
    /////////////////// old list stats
    case 'set_sub_tab_state':
      return {
        ...state,
        subTabState: action.subTabState
      }
    case 'set_list_data':
      return {
        ...state,
        listData: action.listData
      }
    case 'set_search':
      return {
        ...state,
        search: action.search
      }
    case 'set_sort':
      return {
        ...state,
        sort: action.sort
      }
    case 'set_page':
      return {
        ...state,
        page: action.page
      }
    case 'set_running_code':
      return {
        ...state,
        runningCode: action.runningCode
      }
    case 'reset_list':
      return {
        ...state,
        ...initialState
      }
    default:
      throw new Error("Action type not supported");
  }
}

const OwnerInsightsDashboard = props => {


  const {
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
    variant // owner or steward
  } = props;

  let storedInitialState = { ...setInitialState(pageCache, initialState) };
  const [state, dispatch] = useReducer(reducer, storedInitialState);
  const roles = getUserRoles(sessionData.user_role);
  const isDataGovUser = roles.includes('40')

  const [modalQuery, setModalQuery] = useState(undefined);
  const [modalFilter, setModalFilter] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalObjectType, setModalObjectType] = useState(undefined);
  const [modalColumn, setModalColumn] = useState(undefined);

  useEffect(() => {
    if (!state) return;
    storePageCache({ cacheID: window.location.href, ...state })
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    addHistory({
      url: window.location.pathname,
      iconLabel: 'dashboard',
      title: variant === 'owner' ? 'Data Owner Dashboard' : 'Data Steward Dashboard',
      subTitle: `Dashboard`,
      type: 'application',
    })
    // eslint-disable-next-line
  }, [])

  const {
    data: dbList,
    loading: dbListLoading,
    error: dbListError,
    fetchList: dbListFetch
  } = useGetCerebrum({
    url: '/api/sources',
    params: {
      per_page: 200,
      types: 'TOOL,DATABASE,INTERNAL',
      sort: 'ALPHABETICAL',
      active_flag: true
    },
    preventAuto: true
  })


  const loadObjectCountPerSource = () => {
    axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: '*:*',
          fq: `-source_id_srt:998`,
          rows: 0,
          'json.facet': {
            "sources": {
              "type": "terms",
              "field": "source_srt",
              "mincount": 1,
              "limit": 100,
              "facet": {
                "source_id": {
                  "type": "terms",
                  "field": "source_id_srt",
                  "mincount": 1,
                  "limit": 100,
                },
                "object_types": {
                  "type": "terms",
                  "field": "object_type_srt",
                  "mincount": 1,
                  "limit": 100,
                }
              }
            }
          }
        }
      }
      )
      .then(response => {
        let countObj = {};
        let defaultUsageID;
        // let shouldReplaceDefaultUsageId = true;
        response.data.facets?.sources?.buckets?.forEach(el => {
          let sourceId = el.source_id?.buckets[0]?.val;
          countObj[el.val] = { total: el.count, id: sourceId };
          countObj[el.val].objects = el.object_types?.buckets || [];
          if (!defaultUsageID) defaultUsageID = sourceId;
          // if(sourceId===state.usageSource)shouldReplaceDefaultUsageId = false;
        })
        dispatch({ type: 'set_obejct_count_by_source', objectCountBySource: countObj })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    let validList = dbList ? dbList.items.filter(el => el.id !== 995) : state.dbList
    dispatch({
      type: 'set_db_list',
      dbList: validList,
      dbListError: dbListError,
      dbListLoading: dbListLoading,
    })
    if (validList) {
      loadObjectCountPerSource()
    }
    // eslint-disable-next-line
  }, [dbList, dbListLoading, dbListError])


  useEffect(() => {
    const onMsgReceived = (msg) => {
      if (msg.data.modalQuery) {
        setModalQuery(msg.data.modalQuery)
      }
      if (msg.data.modalFilter) {
        setModalFilter(msg.data.modalFilter)
      }
      if (msg.data.modalObject) {
        setModalObjectType(msg.data.modalObject)
      }
      if (msg.data.modalColumn) {
        setModalColumn(msg.data.modalColumn)
      } else {
        setModalColumn(undefined)
      }
      if (msg.data.modalQuery || msg.data.modalFilter || msg.data.modalObject) setModalOpen(true)
    }
    window.removeEventListener('message', globalListenerRef.ownerDashboardMsgListener);
    globalListenerRef.ownerDashboardMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.ownerDashboardMsgListener);
    return (() => { window.removeEventListener('message', globalListenerRef.ownerDashboardMsgListener); })
    // eslint-disable-next-line
  }, [setModalQuery, setModalFilter, setModalOpen, setModalObjectType, setModalColumn])


  useEffect(() => {
    if (!state.dbList) dbListFetch()
    // eslint-disable-next-line
  }, [])

  const objectOrder = {
    'CONTENT': 10,
    'CONTENT_CHILD': 20,
    'TABLE': 30,
    'COLUMN': 40,
    'SCHEMA': 50,
    'DATA_PIPELINE': 60,
    'DATASET': 70,
  }

  const loadTabs = async ({ userID = sessionData.id, userName = sessionData.name }) => {
    dispatch({
      type: 'set_tabs',
      tabsLoading: true,
    })
    let objectTypes = [];
    let collections = [];
    let domainCount = 0;

    let filters = [`object_type_srt:COLLECTION_INSTANCE`, 'collection_srt:DOMAIN']

    let fieldName;
    if (variant === 'steward') fieldName = 'stewards_msrt'
    if (variant === 'owner') fieldName = 'owners_msrt'
    // filters.push(`${fieldName}:"${userName}"`)
    let domainPromise
    // if(isDataGovUser && userID===sessionData.id){
    domainPromise = axiosSolr
      .get(
        '/solr/search/select', {
        params: {
          q: '*:*',
          fq: filters.join(' AND ') + (isDataGovUser && userID === sessionData.id ? '' : ` AND ${fieldName}:"${userName}"`),
          rows: 0,
        }
      }
      )
      .then(response => {
        domainCount = response.data.response.numFound
      })

    let objectPromise = axiosCerebrum
      .get(
        `/api/users/${userID}/related/objects`, {
        params: {
          relationship: variant === 'owner' ? 'OWNER_OF' : 'STEWARD_OF',
          per_page: 30
        }
      }
      )
      .then(response => {
        objectTypes = response.data.items
          .map(el => el.name)
          .filter(el => validOwnerObjectNames.includes(el) && !['COLLECTION', 'COLLECTION_INSTANCE'].includes(el))
          .sort((a, b) => (objectOrder[a] || 80) - (objectOrder[b] || 80))
      })
    let collectionPromise = axiosCerebrum
      .get(
        `/api/users/${userID}/related/collections`, {
        params: {
          relationship: variant === 'owner' ? 'OWNER_OF' : 'STEWARD_OF',
          page: 1,
          parent_flag: true,
          per_page: 200,
        }
      }
      )
      .then(response => {
        collections = response.data.items
          .map(el => el.name)
      })
    await Promise.all([objectPromise, collectionPromise, domainPromise])
      .then(response => {
        let tabs = []
        if (domainCount > 0 || isDataGovUser) tabs.push({ name: 'DOMAIN' })
        JSON.parse(JSON.stringify(tabGroup)).forEach(t => {
          if (t.tabs.find(o => objectTypes.includes(o))) tabs.push(t);
        })
        tabs.forEach(t => {
          if (t.name === 'DOMAIN') return;
          t.tabs = t.tabs.filter(el => objectTypes.includes(el))
        })
        if (collections.length > 0) {
          tabs.push({ name: 'COLLECTION', tabs: collections })
        }
        dispatch({
          type: 'set_tabs',
          tabs
        })
      })
      .catch(error => {
        console.log(error)
        dispatch({
          type: 'set_tabs',
          tabsError: true,
        })
      })
  }

  useEffect(() => {
    if (!state.tabs) loadTabs({})
    // eslint-disable-next-line
  }, [])

  const loadDataRoleUsers = ({ page = 1 }) => {
    let url = `/api/collectioninstances/${variant === 'owner' ? instanceIds.dataOwner : instanceIds.dataSteward}/related`;
    let params = {
      object_name: 'USER',
      page: page,
      object_active_flag: true,
      sort: 'ALPHABETICAL',
      per_page: 10
    }
    dispatch({
      type: 'set_data_role_users',
      dataRoleUsers: state.dataRoleUsers,
      dataRoleUsersLoading: true
    })
    axiosCerebrum
      .get(
        url, { params }
      )
      .then(response => {
        let data = { ...response.data, items: [...(state.dataRoleUsers?.items || []), ...response.data.items] }
        dispatch({ type: 'set_data_role_users', dataRoleUsers: data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_data_role_users', dataRoleUsersError: true })
      })
  }

  if (state.dbListLoading || state.tabsLoading) {
    return (
      <div style={{ textAlign: 'center', width: '18.75rem', margin: '20vh auto' }}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.dbListError || state.tabsError) {
    return (
      <DeadEnd />
    )
  }

  if (!state.dbList || !state.tabs) {
    return <div></div>
  }

  let buttons = []
  if (isDataGovUser) {
    buttons.push(
      <div style={{ display: "flex", alignItems: 'flex-start', marginTop: state.viewedUser ? 52 : 32 }}>
        <Typography style={{ fontSize: 13, color: theme.palette.primaryText.main, marginTop: 6 }}>VIEW AS: </Typography>
        <div>
          <Select
            className={classes.userSelector}
            disableUnderline
            onChange={event => {
              dispatch({
                type: 'set_domain_states',
                domainStates: {}
              })
              ////// reset all by_domain states
              dispatch({
                type: 'set_dq_by_domain'
              })
              dispatch({
                type: 'set_selected_domain'
              })
              dispatch({
                type: 'set_by_domain_period',
                byDomainPeriod: 30
              })
              dispatch({
                type: 'set_by_domain_tab_state',
                byDomainTabState: 0
              })
              dispatch({
                type: 'set_by_domain_sort',
              })
              dispatch({
                type: 'set_by_domain_instance_page_num'
              })
              dispatch({
                type: 'set_by_domain_selected_object'
              })
              dispatch({
                type: 'set_by_domain_instance_list'
              })
              dispatch({
                type: 'set_by_domain_collections'
              })
              dispatch({
                type: 'set_by_domain_trend'
              })
              dispatch({
                type: 'set_by_domain_trend_cache',
                byDomainTrendCache: {}
              })
              /////////
              dispatch({
                type: 'reset_list'
              })
              /////////
              let viewedUser = state.dataRoleUsers.items.find(i => i.id === event.target.value)
              dispatch({ type: 'set_viewed_user', viewedUser })
              loadTabs({ userID: event.target.value, userName: viewedUser.name })
            }}
            onClick={() => {
              if (!state.dataRoleUsers && !state.dataRoleUsersLoading) {
                loadDataRoleUsers({ page: 1 })
              }
            }}
            value={state.viewedUser?.id || 'self'}
            renderValue={value => `${(value === 'self' ? sessionData.name : state.viewedUser.name).toUpperCase()}`}
            MenuProps={{
              className: classes.menu,
              onScroll: (event) => {
                if (event.target.scrollTop >= event.target.scrollHeight - event.target.clientHeight - 10) {
                  if (state.dataRoleUsers && state.dataRoleUsers.page < state.dataRoleUsers.pages && !state.dataRoleUsersLoading) {
                    loadDataRoleUsers({ page: state.dataRoleUsers.page + 1 })
                  }
                }
              }
            }}
          >
            {
              state.dataRoleUsers?.items?.map(el => (
                <MenuItem value={el.id}>
                  {el.name}
                </MenuItem>
              ))
            }
          </Select>
          <Typography
            onClick={() => {
              if (!state.viewedUser) return;
              dispatch({ type: 'set_viewed_user', viewedUser: undefined })
              dispatch({ type: 'reset_list' })
              dispatch({
                type: 'set_domain_states',
                domainStates: {}
              })
              loadTabs({})
            }}
            data-test-id='reset-user-button'
            style={{ marginLeft: 16, fontSize: 12, marginTop: 2, color: theme.palette.primaryText.light, cursor: 'pointer' }}
          >
            {state.viewedUser ? 'Reset' : ''}
          </Typography>
        </div>
      </div>
    )
  }

  const tabOptions = state.tabs || []

  return (
    <div>
      <SearchResultModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        propObjectType={modalObjectType}
        propQuery={modalQuery}
        propFilter={modalFilter}
        propColumn={modalColumn}
        resultItemVariant={"simplified"}
        hiddenComponents={[
          searchComponentNameMap.columnSelector,
          searchComponentNameMap.filterEdit,
          searchComponentNameMap.filter,
          searchComponentNameMap.listTitle,
          searchComponentNameMap.filterReset,
          searchComponentNameMap.searchBar,
          searchComponentNameMap.header,
          searchComponentNameMap.tab,
          searchComponentNameMap.cartButton,
          searchComponentNameMap.downloadButton
        ]}
      />
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={`${toTitleCase(variant)} Dashboard`}
              subtitle={' '}
              buttons={buttons}
              minHeight={140}
              description={'Data, Content and Collections that I manage'}
              label={'dashboard'}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions.map(el => el.name)}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          state.tabs.length === 0 ?
            <Typography>No item {variant === 'owner' ? 'owned' : 'stewarded'} by {state.viewedUser?.name || sessionData.name}</Typography>
            :
            <Body
              key={variant + (state.viewedUser?.id || 'self')}
              history={history}
              dispatch={dispatch}
              state={state}
              sessionData={sessionData}
              variant={variant}
              isDataGovUser={isDataGovUser}
              tabOptions={tabOptions}
            />
        }
      />
    </div>
  )
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OwnerInsightsDashboard));
