import React, { } from 'react';
import PropTypes from 'prop-types';
import { KadaPagination } from 'kada-component-library';



const PageController = props => {

  const {
    state,
    onChangePage,
    presetTotalPages,
    indexName
  } = props;

  let perPage = 10;
  if(indexName==='search'){
    try{
      let defaultParams = JSON.parse(JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===8000).value);
      perPage = defaultParams.rows || 10;
    }catch{}
  }
  let totalPages = presetTotalPages || Math.ceil(state.resultsTotal / perPage);

  return (
    <div style={{ width: '100%', textAlign: 'center', marginBottom: 16 }}>
      <KadaPagination
        currentPage={state.pageNum}
        maxPage={totalPages}
        onChange={e => {
          onChangePage(e.detail.value)
        }}
      />
    </div>
  )
}

PageController.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  presetTotalPages: PropTypes.number
};

export default PageController;
