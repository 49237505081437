import React, { useContext, useEffect, useState } from 'react';
import SearchBar from '../../SearchBar/SearchBar'
import { formatNumber, getIconComponent } from '../../../../utilities'
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar';
import FilterList from '../FilterList/FilterList'
import ResultList from '../ResultList/ResultList'
import PageController from '../PageController/PageController'
import { globalListenerRef } from '../../../../GlobalListenerRef'
import { getContentContainerStyle } from '../../../UI/ProfileLayoutNew/layoutUtils';
import SaveFilterModal from '../SaveFilterModal/SaveFilterModal';
import axiosCerebrum from '../../../../axios-cerebrum';
import { generateSearchFq, generateUrl, getDefaultColumn, getSearchFilterList, getSearchQuery, searchComponentNameMap } from '../Utils/Utils';
import useAlert from '../../../../hooks/useAlert';
import * as actions from '../../../../store/actions/actionTypes';
import { useDispatch } from 'react-redux';
import { BasicSearchContext } from '../../../../containers/BasicSearch/BasicSearch';
import { KadaBtn, KadaLoadingSpinner, kButtonVariants, kLoadingSpinnerVariants } from 'kada-component-library';
import ActionButtons from '../ActionButtons/ActionButtons';


const MainSearchBody = props => {

  const {
    state,
    sessionData,
    dispatch,
    isNotSearchPage,
    onChangeTab,
    onSearch,
    loadSearchResults,
    onChangePage,
    history,
    tabGrouping,
    hiddenComponents,
    removeContainerStyle,
    isCreateFilter,
    indexName,
    customEmptyMsg,
    forceGlobalFilterStr,
    joinFqs,
    joinVarParams,
  } = useContext(BasicSearchContext);

  // const reduxDispatch = useDispatch()
  const [rootStyle, setRootStyle] = useState(getContentContainerStyle())
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false)
  const reduxDispatch = useDispatch()

  const {
    sendAlert: sendAlertInstant
  } = useAlert({})

  useEffect(() => {
    if (state.selectedObjectType) {
      let group = tabGrouping.find(el => el.tabs.includes(state.selectedObjectType))
      if (group) {
        window.postMessage({ verticalTabBarExpand: group.name }, document.location.protocol + "//" + document.location.hostname + ':' + document.location.port)
      }
    }
    // eslint-disable-next-line
  }, [state.selectedObjectType, state.searchTabs])

  const getTabLabel = el => {
    let label = el.objectType;
    if (label === 'ALL') label = 'all_search'
    if (el.isCollection || el.collectionType) {
      switch (el.collectionType) {
        case 'GLOSSARY':
          label = 'glossary'
          break;
        case 'LIST':
          label = 'list'
          break;
        case 'CHANGE':
          label = 'change'
          break;
        case 'COLLABORATION':
          label = 'channel'
          break;
        case 'KNOWLEDGE':
          label = 'note'
          break;
        default:
          label = 'collection'
      }
    }
    return label
  }

  const onBulkActionFilter = () => {
    reduxDispatch({
      type: actions.SET_BULK_EDIT_PARAM,
      data: {
        url: `/solr/${indexName}/select`,
        type: 'solr',
        filterId: state.isSavedFilter.id,
        redirectUrl: window.location.pathname + window.location.search,
        fileName: `Assets from filter: ${state.isSavedFilter.name}`.trim(),
      }
    })

    history.push('/bulk_update')
  }

  const onBulkAction = ({ fileName }) => {
    if (state.isSavedFilter) {
      onBulkActionFilter()
      return
    }
    let params = {};
    params = {
      q: getSearchQuery({ queryInput: state.mainSearchQuery, searchMode: state.searchMode, indexName: indexName }),
      searchQuery: state.mainSearchQuery,
      fq: generateSearchFq({
        tabs: state.searchTabs,
        filters: state.mainSearchFilters,
        objectType: state.selectedObjectType,
        urlFilter: state.mainSearchUrlFilter,
        indexName: indexName,
        forceFq: forceGlobalFilterStr,
        joinFqs: joinFqs
      }),
      ...(joinVarParams || {}),
    }

    reduxDispatch({
      type: actions.SET_BULK_EDIT_PARAM,
      data: {
        url: `/solr/${indexName}/select`,
        params,
        redirectUrl: window.location.pathname + window.location.search,
        fileName: fileName || `${formatNumber(state.resultsTotal)} assets from search`.trim(),
      }
    })

    history.push('/bulk_update')
  }

  useEffect(() => {
    setRootStyle(getContentContainerStyle());
    window.removeEventListener('resize', globalListenerRef.serachMainBodyResieListener)
    window.removeEventListener('message', globalListenerRef.searchBodyMessageListener)
    globalListenerRef.serachMainBodyResieListener = () => {
      setRootStyle(getContentContainerStyle());
    }
    globalListenerRef.searchBodyMessageListener = (e) => {
      if (e.data && e.data.searchBulkAction) {
        onBulkAction({ fileName: e.data.fileName })
      }
    }
    window.addEventListener('message', globalListenerRef.searchBodyMessageListener)
    window.addEventListener('resize', globalListenerRef.serachMainBodyResieListener)

    return () => {
      window.removeEventListener('resize', globalListenerRef.serachMainBodyResieListener)
      window.removeEventListener('message', globalListenerRef.searchBodyMessageListener)
    }
    // eslint-disable-next-line
  }, [state.mainSearchQuery, state.mainSearchFilters, state.mainSearchUrlFilter, state.selectedObjectType, state.searchMode, state.searchTabs, state.resultsTotal])

  const onSaveFilter = () => {
    let searchConfig = generateUrl({
      mainSearchQuery: state.mainSearchQuery,
      searchFilters: getSearchFilterList({ filterStatus: state.filterStatus, negativeFilters: state.negativeFilters }),
      selectedObjectType: state.selectedObjectType,
      defaultColumns: getDefaultColumn({ objectType: state.selectedObjectType, tabs: state.searchTabs }),
      selectedColumns: state.selectedColumns,
      searchMode: state.searchMode,
      andFilters: state.andFilters,
    });

    let rawPrams = loadSearchResults({ isGenerateParamOnly: true })
    let solrQuery = {
      "q": searchConfig.query,
      "q_raw": rawPrams.q,
      "fq": searchConfig.presetFilter,
      "fq_raw": rawPrams.fq,
      "columns": searchConfig.presetColumn,
      "object_type": searchConfig.object,
      'and_filters': searchConfig.andFilters,
      'search_mode': searchConfig.searchMode
    }
    axiosCerebrum
      .put(
        `/api/filteredlists/${state.isSavedFilter.id}`, {
        // "name": state.isSavedFilter.name,
        // "description": state.isSavedFilter.description,
        // "public": permission==='public',
        "solr_query": solrQuery,
        // "filtered_list_type": "LIST"
      }
      )
      .then(response => {
        sendAlertInstant({ type: 'info', message: `Filter "${response.data.name}" successfully saved` })
        history.push('/filters')
      })
      .catch(error => {
        console.log(error)
        sendAlertInstant({ type: 'error', message: `Error occurred saving filter` })
      })
  }

  const isTabsShown = state.searchTabs && state.searchTabs.length > 0 && (!state.isSavedFilter || state.isSavedFilter.solr_query?.object_type === 'ALL') && !hiddenComponents.includes(searchComponentNameMap.tab);

  const listMinHeight = removeContainerStyle ?
    // isNaN(state.resultsTotal) ?
      undefined :
      // Math.min(10, state.resultsTotal) * 96 + (state.resultsTotal < 10 ? 200 : 0) :
    1550

  const isEmptyPage = !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError;

  return (
    <div className={'mb-10 pt-8 min-w-[800px]'} style={removeContainerStyle ? { minWidth: 0, padding: 0, margin: 0 } : rootStyle}>
      {
        !hiddenComponents.includes(searchComponentNameMap.header) &&
        <div className={"flex items-center pb-1 mb-4 pt-2 sticky top-0 bg-(--color-base-100) z-[1200]"}>
          <div className={"w-10 h-10 mr-4 ml-[-56px]"} style={{ display: window.innerWidth < 1350 ? 'none' : 'block' }}>
            {getIconComponent({ label: 'search', size: 40, colour: 'var(--color-header-text)' })}
          </div>
          <h1 className={'flex-grow text-(--color-header-text)'}>
            {
              isCreateFilter ?
                'Create Filter' :
                (state.isSavedFilter?.name || 'Search')
            }
          </h1>
          {
            state.isSavedFilter && state.isSavedFilter.created_by?.id === sessionData.id &&
            <KadaBtn
              variant={kButtonVariants.primaryOutlined}
              style={{ marginLeft: 24 }}
              disabled={!state.isFilterOrColumnChanged}
              onClick={() => {
                onSaveFilter()
              }}
              data-test-id="filter-save-button"
              text="SAVE"
            >
            </KadaBtn>
          }
          <KadaBtn
            variant={kButtonVariants.primaryOutlined}
            style={{ marginLeft: 24 }}
            onClick={() => {
              setSaveFilterModalOpen(true)
            }}
            disabled={isEmptyPage}
            data-test-id="filter-create-button"
            text={isCreateFilter ? 'CREATE FILTER' : 'SAVE AS'}
          >
          </KadaBtn>
          {
            !isCreateFilter && !state.isSavedFilter &&
            <KadaBtn
              variant={kButtonVariants.primaryOutlined}
              style={{ marginLeft: 24 }}
              onClick={onBulkAction}
              disabled={isEmptyPage || state.cartItems.length !== 0}
              data-test-id="bulk-action-button"
              text="BULK ACTION"
            >
            </KadaBtn>
          }
        </div>
      }
      {
        !hiddenComponents.includes(searchComponentNameMap.searchBar) && !isNotSearchPage &&
        <div
          className={"sticky top-0 z-[1280] max-w-full pb-1 pt-3 bg-(--color-base-100) transition-[width] duration-200 ease-in-out"}
          style={{ ...(isNotSearchPage ? { position: 'inherit' } : {}) }}
        >
          <SearchBar isNotSearchPage={isNotSearchPage} indexName={indexName} state={state} dispatch={dispatch} onSearch={onSearch} placeholder={isCreateFilter ? 'Search' : ''} hideLabel={!isCreateFilter || !isEmptyPage} />
        </div>
      }
      {
        isCreateFilter && !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError &&
        <h3 style={{ marginTop: 24, paddingLeft: 16 }}>Add search criteria to see your filter results</h3>
      }
      <div className="flex gap-2">
        <div className={"flex flex-wrap flex-grow pb-2 pt-[6px] min-h-[56px] gap-2"}>
          {
            !hiddenComponents.includes(searchComponentNameMap.filter) &&
            <>
              {
                !hiddenComponents.includes(searchComponentNameMap.searchBar) && isNotSearchPage &&
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: -3, width: isCreateFilter ? '100%' : 260 }}>
                  <SearchBar
                    isNotSearchPage={isNotSearchPage}
                    indexName={indexName}
                    state={state}
                    dispatch={dispatch}
                    onSearch={onSearch}
                    placeholder={isCreateFilter ? 'Search' : ''}
                    hideLabel={!isCreateFilter || !isEmptyPage}
                    hideActions
                  />
                </div>
              }
              {
                state.searchTabs && state.searchTabs.length > 0 &&
                <FilterList
                  rootWidth={rootStyle.width - 280 - rootStyle.paddingLeft * 2}
                />
              }
            </>
          }
        </div>
        <ActionButtons />
      </div>
      {
        isTabsShown &&
        <div className={'hidden-scroll-bar'} style={{ marginTop: 4, float: 'left', height: removeContainerStyle ? undefined : '100vh', minHeight: listMinHeight, overflow: 'auto' }}>
          <VerticalTabBar
            tabOptions={state.searchTabs.map(el => el.objectType)}
            renderOptions={value => `${state.searchTabs.find(el => el.objectType === value).displayName} (${formatNumber(state.searchTabs.find(el => el.objectType === value).count)})`}
            tabState={state.searchTabs.map(el => el.objectType).indexOf(state.selectedObjectType)}
            tabIcons={state.searchTabs.map(el => getTabLabel(el))}
            setTabState={value => {
              onChangeTab(state.searchTabs[value].objectType)
            }}
            grouping={tabGrouping}
            alwaysShowFullTab
            highlightOnSelected
            width={220}
            hideGroupTitleNames={['ALL']}
          />
          <div style={{ height: 24 }}></div>
        </div>
      }
      {
        !hiddenComponents.includes(searchComponentNameMap.resultList) &&
        <div
          style={{
            flexGrow: 1,
            minHeight: listMinHeight,
            marginLeft: isTabsShown ? 280 : 0,
            flexShrink: 1
          }}
        >
          {
            ((state.searchTabs && state.searchTabs.length === 0)) &&
            <div>
              <h3 className={'text-(--color-light-text) mt-10 mb-10'}>
                {
                  customEmptyMsg ||
                  `No results found for "${state.searchValue}"`
                }
              </h3>
            </div>

          }
          {
            (state.searchTabsError || (state.searchResults[state.pageNum] && state.searchResults[state.pageNum].error)) &&
            <p className='mt-8'>Error occurred loading results</p>
          }
          {
            (state.searchTabsLoading || (state.searchResults[state.pageNum] && state.searchResults[state.pageNum].loading)) &&
            <div>
              <div style={{ textAlign: 'center', position: 'relative', marginTop: 8, marginBottom: 8 }}>
                <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
              </div>
            </div>
          }
          {
            state.searchResults[state.pageNum] && state.searchResults[state.pageNum].data && state.searchTabs &&
            <div>
              <ResultList />
              {
                state.resultsTotal > 0 &&
                <PageController
                  state={state}
                  dispatch={dispatch}
                  onChangePage={onChangePage}
                  isNotSearchPage={isNotSearchPage}
                  indexName={indexName}
                />
              }
            </div>
          }
        </div>
      }
      <SaveFilterModal
        state={state}
        modalOpen={saveFilterModalOpen}
        setModalOpen={setSaveFilterModalOpen}
        isCreateFilter={isCreateFilter}
        history={history}
        loadSearchResults={loadSearchResults}
      />
    </div>
  )
}

export default MainSearchBody;
