import React, { useState, useRef, useEffect } from 'react';
import { Typography, withStyles, Select, MenuItem, InputBase, Button, Checkbox, Radio, CircularProgress, IconButton, Divider } from '@material-ui/core';
import { formatNumber, getIconComponent } from '../../../../utilities'
import theme from '../../../../theme';
import axiosSolr from '../../../../axios-solr'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import { getSearchQuery, processObjectType } from '../Utils/Utils';
import ToggleButton from '../../../UI/ToggleButton/ToggleButton';
import { formatFilterValue } from './utils';

const styles = theme => ({
  selector: {
    ...theme.components.selector,
    height: 28,
    background: `${theme.palette.background.main} !important`,
    '& div div': {
      paddingLeft: 12,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: 13.75,
      background: `${theme.palette.background.main} !important`,
    },
  },
  selectorRoot: {
    display: 'flex'
  },
  inputBase: {
    ...theme.components.inputBase,
    height: 44,
    flexGrow: 1,
    flexShrink: 0,
    '& input': {
      paddingLeft: 24
    }
  },
  selectMenu: {
    width: 450,
  },
  menu: {
    // marginTop:90,
    marginTop: 58,
    '& ul': {
      paddingTop: 0,
      minWidth: 450,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 500,
      maxWidth: '50vw',
    },
  },
  menuItem: {
    display: 'block',
    padding: '0px 16px',
    minHeight: 51,
    color: theme.palette.primaryText.main,
    '&:hover': {
      background: theme.palette.hovered.main
    },
  },
  checkbox: {
    paddingLeft: 0
  },
  valueList: {
    flexGrow: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0px'
    },
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  selectedMenuItem: {
    backgroundColor: `${theme.palette.background.main} !important`,
    '&:hover': {
      background: `${theme.palette.hovered.main} !important`
    },
  },
  negativeToggle: {
    width: 84,
    height: 24,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13,
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
});



const Filter = props => {

  const {
    classes,
    state,
    dispatch,
    fieldName,
    name,
    type,
    onUpdateFilter,
    isNegative,
    onSetNegative,
    isAnd,
    onSetAnd,
    width,
    bodyOnly,
    getDefaultParams,
    indexName = 'search',
    forceGlobalFilterStr,
    joinFqs,
    joinVarParams,
    forceGlobalQueryStr
  } = props;

  const [searchFilter, setSearchFilter] = useState('');
  const searchTimoutRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [forceLoadingScreen, setForceLoadingScreen] = useState(false);

  // state.hideEmptyFilterValue is a default global control. this state instead is for local control when empty filter is not hidden globally
  const [isShowZeroValues, setIsShowZeroValues] = useState(false);

  const isCollection = fieldName.includes('_kc_msrt')
  const data = state.filterStatus[fieldName];
  const setData = data => dispatch({ type: 'set_filter_status', filterStatus: { ...state.filterStatus, [fieldName]: data } })

  const loadStringFilter = async ({ offset, selected, searchStr, allFilterFq, facetPrefix, isInt }) => {
    let values = []
    let per_page = 100;
    if (offset === 0 && selected.length > 0) {
      let selectedResponse = await axiosSolr
        .post(
          `/solr/${indexName}/select`, {
          params: {
            q: forceGlobalQueryStr || getSearchQuery({ queryInput: searchStr, searchMode: state.searchMode }),
            ...getDefaultParams(),
            fq: allFilterFq,
            ...(joinVarParams || {}),
            rows: 0,
            facet: 'on',
            'facet.field': fieldName,
            'facet.contains': isInt ? undefined : facetPrefix.toUpperCase(),
            // 'facet.mincount':1,
            'facet.limit': per_page,
            'facet.missing': true,
            'facet.offset': offset,
          }
        }
        )
      if (selectedResponse.data.facet_counts && selectedResponse.data.facet_counts.facet_fields && selectedResponse.data.facet_counts.facet_fields[fieldName]) {
        selectedResponse.data.facet_counts.facet_fields[fieldName].forEach((el, index) => {
          if (el?.toUpperCase) el = el.toUpperCase()
          if (index % 2 === 0 && selected.includes(el)) {
            let count = selectedResponse.data.facet_counts.facet_fields[fieldName][index + 1]
            if (el === null && count === 0) return;
            if (state.hideEmptyFilterValue && count === 0) return;
            if (el === null) {
              if (offset === 0) {
                if (searchFilter.trim() === '' || formatNullValue(name).toLowerCase().includes(searchFilter.toLowerCase())) {
                  values.push({
                    value: el,
                    dispValue: formatNullValue(name),
                    count: count,
                    applied: true
                  })
                }
              }
            } else {
              values.push({
                value: el,
                dispValue: el,
                count: count,
                applied: true
              })
            }
          }
        })
      }
    }
    let allFilterResponse = await axiosSolr
      .post(
        `/solr/${indexName}/select`, {
        params: {
          q: forceGlobalQueryStr || getSearchQuery({ queryInput: searchStr, searchMode: state.searchMode }),
          ...getDefaultParams(),
          fq: allFilterFq,
          ...(joinVarParams || {}),
          rows: 0,
          facet: 'on',
          'facet.field': fieldName,
          'facet.contains': isInt ? undefined : facetPrefix.toUpperCase(),
          // 'facet.mincount':1,
          'facet.limit': per_page,
          'facet.offset': offset,
          'facet.missing': true,
        }
      }
      )
    if (allFilterResponse.data.facet_counts && allFilterResponse.data.facet_counts.facet_fields && allFilterResponse.data.facet_counts.facet_fields[fieldName]) {
      allFilterResponse.data.facet_counts.facet_fields[fieldName].forEach((el, index) => {
        if (el?.toUpperCase) el = el.toUpperCase()
        if (index % 2 === 0 && !values.find(v => v.value === el)) {
          let count = allFilterResponse.data.facet_counts.facet_fields[fieldName][index + 1]
          if (el === null && count === 0) return;
          if (state.hideEmptyFilterValue && count === 0) return;
          if (el === null) {
            if (offset === 0 && !selected.find(s => s === null) && (searchFilter.trim() === '' || formatNullValue(name).toLowerCase().includes(searchFilter.toLowerCase()))) {
              values.push({
                value: el,
                dispValue: formatNullValue(name),
                count: count,
                applied: false
              })
            }
          } else {
            values.push({
              value: el,
              dispValue: el,
              count: count,
              applied: false
            })
          }

        }
      })
    }

    const isContained = (t1, t2) => {
      if (!t1 || !t2) return false;
      if (typeof (t1) !== 'string' || typeof (t2) !== 'string') return false;
      if (t1.slice(0, t2.length).toLowerCase() === t2.toLowerCase()) return true;
    }

    let customValues = selected.filter(el => el && !values.find(v => v.value === el) && isContained(el, facetPrefix))

    customValues.forEach(el => {
      values.push({
        value: el,
        dispValue: el,
        count: 0,
        applied: true
      })
    })

    setForceLoadingScreen(false)
    setData({
      allLoaded: values.length < per_page,
      values: offset === 0 ? values : [...data.values, ...values],
      selected,
      type
    })
  }

  const loadDateFilter = async ({ offset, selected, searchStr, allFilterFq }) => {
    const queryMap = {
      'LAST DAY': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-1DAY TO NOW/DAY+1DAY]`
      },
      'LAST 7 DAYS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-7DAYS TO NOW/DAY+1DAY]`
      },
      'LAST 30 DAYS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-30DAYS TO NOW/DAY+1DAY]`
      },
      'LAST 90 DAYS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-90DAYS TO NOW/DAY+1DAY]`
      },
      'LAST 6 MONTHS': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-6MONTH TO NOW/DAY+1DAY]`
      },
      'LAST YEAR': {
        type: 'query',
        q: `${fieldName}:[NOW/DAY-1YEAR TO NOW/DAY+1DAY]`
      },
      'ALL TIME': {
        type: 'query',
        q: `${fieldName}:[* TO *]`
      },
    }
    let values = [];
    let allFilterResponse = await axiosSolr
      .post(
        `/solr/${indexName}/select`, {
        params: {
          q: forceGlobalQueryStr || getSearchQuery({ queryInput: searchStr, searchMode: state.searchMode }),
          ...getDefaultParams(),
          fq: allFilterFq,
          ...(joinVarParams || {}),
          rows: 0,
        },
        facet: queryMap
      }
      )
    let allFacets = allFilterResponse.data.facets
    if (allFacets) {
      ['LAST DAY', 'LAST 7 DAYS', 'LAST 30 DAYS', 'LAST 90 DAYS', 'LAST 6 MONTHS', 'LAST YEAR', 'ALL TIME'].forEach(el => {
        // if(values.find(v=>v.dispValue===el) || !allFacets[el] || allFacets[el].count===0)return;
        if (values.find(v => v.dispValue === el) || !allFacets[el]) return;
        if (state.hideEmptyFilterValue && allFacets[el].count === 0) return;
        values.push({
          value: queryMap[el].q.split(`${fieldName}:`)[1],
          dispValue: el,
          count: allFacets[el].count,
          applied: false
        })
      })
    }
    setForceLoadingScreen(false)
    setData({
      allLoaded: true,
      values: offset === 0 ? values : [...data.values, ...values],
      selected,
      type
    })
  }

  const loadIntFilter = async ({ offset, selected, searchStr, allFilterFq, selectedFilterFq, type, negative = isNegative }) => {
    let queryMap;
    let name = fieldName;
    // if(negative)name = `-${name}`
    if (type === 'pint') {
      queryMap = {
        'NONE': {
          type: 'query',
          q: `${name}:0`
        },
        'ONE': {
          type: 'query',
          q: `${name}:1`
        },
        'MANY': {
          type: 'query',
          q: `${name}:[2 TO *]`
        },
      }
    } else {
      for (let i = 0; i < 10; i++) {
        queryMap = {
          ...(queryMap || {}),
          [`${i * 10} TO ${i * 10 + 9}`]: {
            type: 'query',
            q: `${name}:[${i * 10} TO ${i * 10 + 9}]`
          },
        }
      }
      queryMap = {
        ...(queryMap || {}),
        [`100`]: {
          type: 'query',
          q: `${name}:100`
        },
      }
    }
    queryMap['UNKNOWN'] = {
      type: 'query',
      // q: `${negative?'':'-'}${name}:[* TO *]`
      q: `-${name}:[* TO *]`
    }
    let values = [];

    let selectedResponse = await axiosSolr
      .post(
        `/solr/${indexName}/select`, {
        params: {
          q: forceGlobalQueryStr || getSearchQuery({ queryInput: searchStr, searchMode: state.searchMode }),
          ...getDefaultParams(),
          fq: negative ? allFilterFq : selectedFilterFq,
          ...(joinVarParams || {}),
          rows: 0,
        },
        facet: queryMap
      }
      )

    let selectedFacets = selectedResponse.data.facets
    if (selectedFacets) {
      Object.keys(queryMap).forEach(el => {
        // if(values.find(v=>v.dispValue===el) || !selectedFacets[el] || selectedFacets[el].count===0 )return;
        if (values.find(v => v.dispValue === el) || !selectedFacets[el]) return;
        if (state.hideEmptyFilterValue && selectedFacets[el].count === 0) return;
        let value = el === 'UNKNOWN' ? null : queryMap[el].q.split(`${fieldName}:`)[1]
        if (!selected.includes(value)) return;
        values.push({
          value: value,
          dispValue: el,
          count: selectedFacets[el].count,
          applied: true
        })
      })
    }

    let allFilterResponse = await axiosSolr
      .post(
        `/solr/${indexName}/select`, {
        params: {
          q: forceGlobalQueryStr || getSearchQuery({ queryInput: searchStr, searchMode: state.searchMode }),
          ...getDefaultParams(),
          fq: allFilterFq,
          ...(joinVarParams || {}),
          rows: 0,
        },
        facet: queryMap
      }
      )
    let allFacets = allFilterResponse.data.facets
    if (allFacets) {
      Object.keys(queryMap).forEach(el => {
        if (values.find(v => v.dispValue === el) || !allFacets[el] || allFacets[el].count === 0) return;
        let count = allFacets[el].count;
        if (count === 0) return;
        // if(negative && count===allFilterResponse.data.response.numFound)return;
        values.push({
          value: el === 'UNKNOWN' ? null : queryMap[el].q.split(`${fieldName}:`)[1],
          dispValue: el,
          count: count,
          applied: false
        })
      })
    }

    setForceLoadingScreen(false)
    setData({
      allLoaded: true,
      values: offset === 0 ? values : [...data.values, ...values],
      selected,
      type
    })
  }

  const loadFilterData = async ({
    offset = 0,
    objectType = state.selectedObjectType,
    searchStr = state.mainSearchQuery,
    mainSearchFilters = state.mainSearchFilters,
    facetPrefix = searchFilter,
    tabs = state.searchTabs,
    negative = isNegative,
  }) => {
    let selected = data?.selected || [];
    let isCollection = tabs && tabs.find(el => el.objectType === objectType).isCollection;
    let isCollectionType = tabs && tabs.find(el => el.objectType === objectType).isCollectionType;
    let isCodeType = tabs && tabs.find(el => el.objectType === objectType).isCodeType;
    let collectionType;
    if (isCollection) {
      collectionType = tabs && tabs.find(el => el.objectType === objectType).collectionType;
    }
    if (isCollectionType) {
      collectionType = tabs && processObjectType(tabs.find(el => el.objectType === objectType).objectType);
    }
    setData({
      loading: true,
      values: offset === 0 ? undefined : data.values,
      selected,
      type
    })
    let fq = '';
    if (indexName === 'search') {
      fq = `object_type_srt:${processObjectType(objectType)}`;
      if (objectType === 'ALL') {
        fq = `object_type_srt:*`
      }
      if (isCollectionType) {
        fq = `collection_type_srt:${processObjectType(objectType)} AND object_type_srt:COLLECTION_INSTANCE`
      }
      if (isCollection) {
        fq = `collection_srt:"${processObjectType(objectType)}" AND collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION_INSTANCE`
      }
      if (isCodeType) {
        fq = `code_type_srt:"${objectType}"  AND object_type_srt:CODE`
      }
    }
    if (forceGlobalFilterStr) {
      fq += `${fq ? ' AND ' : ''}${forceGlobalFilterStr}`
    }

    let allFilterFq = fq;
    let selectedFilterFq = fq;

    if (mainSearchFilters.length > 0) selectedFilterFq += `${fq ? ' AND ' : ''}${mainSearchFilters.join(' AND ')}`

    let filters = mainSearchFilters.filter(el => el.match(new RegExp(`\\b[-(]?${fieldName}:`)) === null)
    if (filters.length > 0) allFilterFq += `${fq ? ' AND ' : ''}${filters.join(' AND ')}`;

    if (joinFqs) {
      allFilterFq = [
        ...joinFqs,
        allFilterFq
      ]
      selectedFilterFq = [
        ...joinFqs,
        selectedFilterFq
      ]
    }

    try {
      if (['pdate'].includes(type)) {
        await loadDateFilter({ offset, selected, searchStr, allFilterFq, facetPrefix })
      }
      else if (['pint', 'plong'].includes(type)) {
        if (fieldName === 'max_level_txt') {
          await loadStringFilter({ offset, selected, searchStr, selectedFilterFq, allFilterFq, facetPrefix, isInt: true })
        } else {
          await loadIntFilter({ offset, selected, searchStr, allFilterFq, selectedFilterFq, facetPrefix, type, negative })
        }
      }
      else {
        await loadStringFilter({ offset, selected, searchStr, selectedFilterFq, allFilterFq, facetPrefix })
      }
    } catch (error) {
      console.log(error)
      setData({
        error: true
      })
    }
  }

  const onSearchValueUpdated = value => {
    setSearchFilter(value)
    clearTimeout(searchTimoutRef.current);
    searchTimoutRef.current = setTimeout(() => {
      loadFilterData({ facetPrefix: value })
    }, 350)
  }

  const onListScroll = (event) => {
    if (event.target.scrollTop >= event.target.scrollHeight - event.target.clientHeight - 10) {
      if (data && !data.loading && !data.allLoaded && data.values) {
        loadFilterData({ offset: data.values.length });
      }
    }
  }

  const onClickValue = (value, isAddCustom) => {
    let selected = data ? [...data.selected] : [];
    if (type === 'pdate') {
      selected = [value]
    }
    else {
      if (selected.includes(value)) {
        selected = selected.filter(el => el !== value)
      } else {
        selected.push(value)
      }
    }
    let forcedAttributes;
    if (isAddCustom) {
      forcedAttributes = {
        values: [
          ...(data.values || []),
          {
            value,
            dispValue: value,
            count: 0,
            applied: true
          }
        ]
      }
    }
    onUpdateFilter({
      updatedFieldName: fieldName,
      updatedSelected: selected,
      // undefined,
      onFinish: isAddCustom ? () => {
        setForceLoadingScreen(true)
        setTimeout(() => {
          document.getElementById(`${fieldName}-filter-clear-search-button`).click()
        }, 500)
      } : undefined,
      forcedAttributes: forcedAttributes
    })
  }

  const onClear = () => {
    if (!data) return;
    if (data.selected.length === 0) return;
    onUpdateFilter({ updatedFieldName: fieldName, updatedSelected: [] });
  }

  const onToggleNegative = () => {
    let selected = data ? [...data.selected] : [];
    let newNegFiters = onSetNegative()
    onUpdateFilter({
      updatedFieldName: fieldName,
      updatedSelected: selected,
      negativeFilters: newNegFiters,
      onFinish: ({ filters }) => {
        loadFilterData({ offset: 0, negative: !isNegative, mainSearchFilters: filters });
      }
    })
  }

  const onToggleOrAnd = () => {
    let selected = data ? [...data.selected] : [];
    let newAndFilters = onSetAnd()
    onUpdateFilter({
      updatedFieldName: fieldName,
      updatedSelected: selected,
      andFilters: newAndFilters,
      onFinish: ({ filters }) => {
        loadFilterData({ offset: 0, mainSearchFilters: filters });
      }
    })
  }

  const formatNullValue = value => {
    if (fieldName === 'masked_txt') return 'UNKNOWN'
    return `NO ${value.toUpperCase()}`
  }

  const getBarPercentage = (values, count) => {
    const maxValue = Math.max(...values.map(el => el.count))
    if (count === 0) return '0%'
    return `${Math.floor(count * 100 / maxValue)}%`
  }

  const getParticalBar = percentage => {
    return (
      <div style={{ width: '100%', height: 3, borderRadius: 1, background: `${theme.palette.primary.main}50` }}>
        <div style={{ height: 3, width: percentage, backgroundColor: theme.palette.primary.main }}></div>
      </div>
    )
  }

  const isSearched = value => {
    if (!searchFilter || searchFilter.trim() === '') return true;
    if (value === null) value = formatNullValue(name)
    return value.toLowerCase().includes(searchFilter.toLowerCase());
  }

  useEffect(() => {
    if (!bodyOnly) return;
    if (data && data.loading) return;
    loadFilterData({ offset: 0 })
    // eslint-disable-next-line
  }, [])

  const onCheckAll = () => {
    if (!data?.selected || !data?.values) return;
    let selected = data.selected;
    let nonZeroValues = data.values.filter(el => el.count !== 0).map(el => el.value)
    if (selected.every(el => nonZeroValues.includes(el)) && selected.length === nonZeroValues.length) {
      onUpdateFilter({ updatedFieldName: fieldName, updatedSelected: [] });
    } else {
      onUpdateFilter({ updatedFieldName: fieldName, updatedSelected: nonZeroValues });
    }
  }

  const checkIsAllChecked = () => {
    if (!data?.selected || !data?.values) return false;
    let nonZeroValues = data.values.filter(el => el.count !== 0).map(el => el.value)
    return data.selected.every(el => nonZeroValues.includes(el)) && data.selected.length === nonZeroValues.length
  }

  let isSelected = data && data.selected && data.selected.length > 0

  const isNoResult = data && data.values && data.values.length === 0 && (data.selected.length === 0 || !data.values.some(v => isSearched(v.value)))

  const isString = ['istring', 'istrings'].includes(type)

  let dropdownBody = (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      {
        type !== 'pdate' &&
        <div style={{ display: 'flex', flexGrow: 0, width: '100%' }}>
          <InputBase
            className={classes.inputBase}
            value={searchFilter}
            onChange={event => onSearchValueUpdated(event.target.value)}
            placeholder={isString ? `Search or add a custom filter value` : `Search filter value`}
            endAdornment={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton id={`${fieldName}-filter-clear-search-button`} disabled={searchFilter === ''} onClick={() => onSearchValueUpdated('')} style={{ padding: 6, marginRight: 16 }}>
                  {getIconComponent({ label: searchFilter === '' ? 'search' : 'clear', colour: theme.palette.primaryText.light, size: 24 })}
                </IconButton>
                {
                  isString && !data?.loading && searchFilter.trim() && !data?.values?.find(v => v.value?.toLowerCase() === searchFilter.toLowerCase()) &&
                  <Button
                    color='primary'
                    style={{ position: 'relative', left: -8, padding: '2px 0px 0px', minWidth: 60 }}
                    onClick={() => {
                      onClickValue(searchFilter.toUpperCase(), true)
                    }}
                  >
                    SAVE
                  </Button>
                }
              </div>
            }
          />
        </div>
      }
      {
        data &&
        <div className={classes.valueList} onScroll={onListScroll}>
          {
            type !== 'pdate' &&
            <div style={{ marginTop: 20, marginBottom: 8, flexGrow: 0, display: 'flex', alignItems: 'center', paddingLeft: 18, paddingRight: 16 }}>
              <Typography color='primary' style={{ fontSize: 12, letterSpacing: 2, marginRight: 4 }}>FILTER:</Typography>
              <ToggleButton
                options={['INCLUDES', 'EXCLUDES']}
                selectedValue={isNegative ? 'EXCLUDES' : 'INCLUDES'}
                setSelectedValue={onToggleNegative}
                testID={`${fieldName}-filter-exclude-toggle`}
              />
              <div style={{ width: 8 }}>
              </div>
              {
                fieldName !== 'object_type_srt' &&
                <ToggleButton
                  options={['OR', 'AND']}
                  selectedValue={isAnd ? 'AND' : 'OR'}
                  setSelectedValue={onToggleOrAnd}
                  testID={`${fieldName}-filter-or-and-toggle`}
                />
              }
            </div>
          }
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}>
            <Checkbox className={classes.checkbox} onClick={() => onCheckAll()} checked={checkIsAllChecked()} color='primary' />
            <Typography color='primary' style={{ fontSize: 13.75, letterSpacing: 2, flexGrow: 1 }}>{data?.selected?.length || 0} SELECTED</Typography>
            <Button color='secondary' onClick={onClear} style={{ fontSize: 12, padding: 0, minWidth: 0 }}>CLEAR FILTER(S)</Button>
          </div>
          {
            data.error && <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.main, marginTop: 16, paddingLeft: 24 }}>Error occurred loading values</Typography>
          }
          {
            isNoResult &&
            <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.main, marginTop: 16, paddingLeft: 24 }}>
              No matching results.{isString ? ' Click save to use custom value.' : ''}
            </Typography>
          }
          {
            !forceLoadingScreen && type !== 'pdate' && data.values && (data.values.filter(el => el.applied).length !== 0 || (data.selected && data.selected.filter(el => data.values && !data.values.find(v => v.value === el)).length > 0)) && !isNoResult &&
            // type!=='pdate' && data.values && data.selected.length>0 &&
            <div>
              {
                data.selected && data.selected.filter(el => data.values && !data.values.find(v => v.value === el) && !state.hideEmptyFilterValue && isSearched(el)).map(el => (
                  <MenuItem data-test-id={`search-filter-menuitem-${el}`} onClick={() => { onClickValue(el) }} selected={data.selected.includes(el)} disableRipple classes={{ selected: classes.selectedMenuItem }} value={el} className={classes.menuItem}>
                    <KTooltip title={`${el} (0)`}>
                      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                        <Checkbox className={classes.checkbox} checked={data.selected.includes(el)} color='primary' />
                        <Typography className={classes.ellipsisText} style={{ fontSize: 16, color: theme.palette.primaryText.main }}>
                          {el === null ? formatNullValue(name) : el} (0)
                        </Typography>
                      </div>
                    </KTooltip>
                  </MenuItem>
                ))
              }
              {
                data.values && data.values.sort((a, b) => type === 'pdate' ? 0 : b.count - a.count).filter(el => el.applied).map(el => (
                  <MenuItem data-test-id={`search-filter-menuitem-${el.value}`} onClick={() => { onClickValue(el.value) }} selected={data.selected.includes(el.value)} disableRipple classes={{ selected: classes.selectedMenuItem }} value={el.value} className={classes.menuItem}>
                    <KTooltip title={`${el.dispValue || el.value} (${formatNumber(el.count).toUpperCase()})`}>
                      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                        <Checkbox className={classes.checkbox} checked={data.selected.includes(el.value)} color='primary' />
                        <Typography className={classes.ellipsisText} style={{ fontSize: 16, color: theme.palette.primaryText.main }}>{el.dispValue || el.value} ({formatNumber(el.count).toUpperCase()})</Typography>
                      </div>
                    </KTooltip>
                    {getParticalBar(getBarPercentage(data.values, el.count))}
                  </MenuItem>
                ))
              }
              {
                data.values && data.values.filter(el => !el.applied).length !== 0 &&
                <Divider style={{ backgroundColor: theme.palette.listItemDivider.main, marginTop: 16 }} />
              }
            </div>
          }
          {
            !forceLoadingScreen && data.values && (data.values.filter(el => !el.applied).length !== 0 || type === 'pdate') && !isNoResult &&
            <div>
              {
                data.values
                  .filter(el => (!el.applied && (el.count !== 0 || isShowZeroValues)) || type === 'pdate')
                  .sort((a, b) => type === 'pdate' ? 0 : b.count - a.count)
                  .map(el => (
                    <MenuItem data-test-id={`search-filter-menuitem-${el.value}`} onClick={() => { if (data.selected.includes(el.value) && type === 'pdate') return; onClickValue(el.value) }} disableRipple classes={{ selected: classes.selectedMenuItem }} selected={data.selected.includes(el.value)} value={el.value} className={classes.menuItem}>
                      <KTooltip title={`${el.dispValue || el.value} (${formatNumber(el.count).toUpperCase()})`}>
                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                          {
                            type === 'pdate' ?
                              <Radio className={classes.checkbox} checked={data.selected.includes(el.value)} color='primary' /> :
                              <Checkbox className={classes.checkbox} checked={data.selected.includes(el.value)} color='primary' />
                          }
                          <Typography className={classes.ellipsisText} style={{ fontSize: 16, color: theme.palette.primaryText.main }}>{el.dispValue || el.value} ({formatNumber(el.count).toUpperCase()})</Typography>
                        </div>
                      </KTooltip>
                      {getParticalBar(getBarPercentage(data.values, el.count))}
                    </MenuItem>
                  )
                  )
              }
              {
                data.values.filter(el => !el.applied && el.count === 0).length !== 0 && data.allLoaded && isString &&
                <>
                  <Divider style={{ backgroundColor: theme.palette.listItemDivider.main, marginTop: 16 }} />
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      onClick={() => setIsShowZeroValues(!isShowZeroValues)}
                      color='primary'
                      style={{ marginTop: 8, letterSpacing: 1 }}
                    >
                      {isShowZeroValues ? 'HIDE' : 'SHOW'} VALUES WITH 0 RESULTS
                    </Button>
                  </div>
                </>
              }
            </div>
          }
          <div style={{ textAlign: 'center', marginBottom: 16, marginTop: 8 }}>
            {
              (data.loading || forceLoadingScreen) &&
              <CircularProgress color='secondary' />
            }
          </div>
        </div>
      }
    </div>
  )

  if (bodyOnly) return dropdownBody;

  return (
    <div style={{ width }}>
      <Typography style={{ fontSize: 12, marginBottom: -4, marginLeft: 8, position: 'relative', zIndex: 20, width: 'max-content', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: width - 24, background: theme.palette.background.main, color: isSelected ? theme.palette.primary.main : theme.palette.primaryText.light, padding: '0 6px' }}>
        {`${name}${isCollection && fieldName !== 'category_kc_msrt' ? ' (Collection)' : ''}`}
      </Typography>
      <Select
        data-test-classname="search-filter"
        data-test-id={`search-filter-${name.toLowerCase()}`}
        className={classes.selector}
        style={{ width: width - 4, borderColor: isSelected ? theme.palette.primary.main : theme.palette.primaryText.light }}
        value={data && data.values && data.values.length !== 0 && data.selected && data.selected.length > 0 ? data.selected : ['all']}
        multiple
        classes={{ selectMenu: classes.selectMenu, root: classes.selectorRoot }}
        renderValue={() => {
          let prefix = isNegative ? 'Excl: ' : 'Incl: '
          if (!data || !data.selected || data.selected.length === 0) {
            return `All`
          } else {
            return `${prefix}` + data.selected.map(el => formatFilterValue({ value: el, type, fieldName, dispName: name })).join(', ')
          }
        }}
        disableUnderline
        open={menuOpen}
        onOpen={() => {
          setMenuOpen(true);
          if (!data || !data.values) loadFilterData({ offset: 0 })
        }}
        onClose={() => {
          setMenuOpen(false);
          setSearchFilter('')
          setData({ selected: data ? data.selected : undefined, type })
        }}
        MenuProps={{
          className: classes.menu,
          style: {
            marginTop: type === 'pdate' ? 58 : 80
          }
        }}
      >
        {dropdownBody}
      </Select>
    </div>
  )
}

export default withStyles(styles)(Filter);
