import React from 'react';
import { Typography, withStyles, Checkbox } from '@material-ui/core';
import { onClickResultItem, mapSearchObjectName, processCollection, getUserRoles, getItemChildType, getIconComponent, getTrustScoreColor, getLineageIcon, formatLineageText, getTestScoreColor, getDispFields, formatBusinessnName, formatNumber } from '../../../../utilities'
import theme from '../../../../theme';
import moment from 'moment';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../../UI/ContextMenu/ContextMenu'
import { useStore } from 'react-redux'
import { addBadgeToIcon, getDomainText, getIconLabel } from '../../../UI/SearchResults/utils'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import { checkCanAddToCart } from '../../../../permissionChecker';
import { getColumnWidth, getListWidth, getNameWidth, getTxtField, isColumnExtendable, searchComponentNameMap } from '../Utils/Utils';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '12px 16px',
    minHeight: 114,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.hovered.main,
    }
  },
  cartItem: {
    '& .select-checkbox': {
      width: 0,
      transition: 'width 0.2s ease-in-out',
    },
    '& .item-icon': {
      width: 24,
      transition: 'width 0.2s ease-in-out',
    },
    '&:hover': {
      '& .item-icon': {
        width: 0,
        overflow: "hidden",
      },
      '& .select-checkbox': {
        width: 24,
      }
    }
  },
  disabledCartItem: {
    '& .select-checkbox': {
      width: 0,
      transition: 'width 0.2s ease-in-out',
    },
    '& .item-icon': {
      width: 24,
      transition: 'width 0.2s ease-in-out',
    },
  },
  iconWrapper: {
    flex: '0 0 24px',
    height: 24,
    width: 24,
    marginRight: 24,
    display: 'flex',
    // overflow:'hidden'
  },
  mainContentContainer: {
    flexGrow: 0,
    flexShrink: 0,
    paddingRight: 40,
    overflow: 'hidden',
    // minWidth:220,
  },
  tags: {
    display: 'flex',
    marginTop: 2,
    flexWrap: 'wrap'
  },
  mainContent: {
    overflow: 'hidden'
  },
  title: {
    color: theme.palette.header.main,
    fontSize: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: 4
  },
  subTitle: {
    color: theme.palette.primaryText.light,
    fontSize: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'max-content',
    maxWidth: '100%',
    marginBottom: 8
  },
  description: {
    color: theme.palette.primaryText.light,
    fontSize: 13.75,
  },
  columnBoxes: {
    flexGrow: 0,
    flexShrink: 0,
    // flex:'0 0 110px',
    marginRight: 16,
    fontSize: 13.75,
    wordBreak: 'break-word',
    color: theme.palette.primaryText.main,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  highlightChipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    overflow: 'hidden'
  },
  highlightChip: {
    padding: '0px',
    background: '#D7D8D8',
    marginTop: 8,
    borderRadius: 3,
    marginRight: 6,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    overlfow: 'hidden',
    maxWidth: '100%',
  },
  highlightValueTxt: {
    fontSize: 13.75,
    color: '#282935',
    padding: '0px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  highlightText: {
    padding: '1px 4px 3px',
    marginLeft: 2,
    marginRight: 2,
    background: '#D7D8D8',
    borderRadius: 3,
    color: '#282935',
  },
  tag: {
    paddingTop: 1,
    marginTop: 6,
    letterSpacing: 1,
    fontWeight: 400,
    maxWidth: 300,
    // minWidth:30,
    flexShrink: 0,
    fontSize: 12,
    padding: '0 6px',
    marginRight: 6,
    borderRadius: 12,
    overflow: 'hidden !important',
    whiteSpace: 'nowrap !important',
    textOverflow: 'ellipsis !important',
    border: `2px solid transparent`,
    color: theme.palette.primaryText.main,
  }
});



const ResultItem = props => {

  const {
    classes,
    history,
    object,
    columnFileds,
    highlights,
    state,
    dispatch,
    selectingAll,
    hiddenComponents,
    resultItemVariant,
    alwaysOpenNewTab,
    indexName,
    forceOnItemClick
  } = props;

  const store = useStore();
  let sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  // const isCancelledRef = useRef(false)
  const objectTypeFieldName = indexName === 'search' ? 'object_type_txt' : 'object_type_srt'

  // const {
  //   sendAlert
  // } = useAlert({
  //   id:`basic-search-result-item`,
  //   isCancelledRef
  // })

  const generateName = () => {
    if (indexName === 'search') {
      let name = object.name_txt
      if (object[objectTypeFieldName] === 'COLLECTION_INSTANCE' && object.collection_txt === 'TABLE CLUSTER') {
        return getDispFields(object, 'dispTitle')
      }
      if (object[objectTypeFieldName] === 'SOURCE') {
        return `${getDispFields(object, 'dispTitle')} (${getDispFields(object, 'dispSubtitle')})`
      }
      let businessName = object.alternate_name_txt && object.alternate_name_txt.trim() !== '' ? object.alternate_name_txt : undefined
      if (!businessName) return name
      if (!getUserRoles(sessionData.user_role).every(el => el === '90')) return `${name} (${businessName})`
      else { return `${businessName} (${name})` }
    }
    else if (indexName === 'data_quality_test_result') {
      let { primaryName } = formatBusinessnName({ roles, dispTitle: object.data_quality_test_name_txt, businessName: object.data_quality_alternate_name_txt, item: object, returnCustomPayload: true })
      return primaryName
    }
    else if (indexName === 'impact') {
      return object.user_name_srt
    }
  }

  const formatColumnText = (value, fieldName) => {
    if (value === 0) return value;
    if (!value || value.length === 0) return ''
    if (Array.isArray(value)) return value.join(', ')
    let fieldType = state.allFilters.find(el => el.field === fieldName)?.type
    if (fieldType === 'pdate') {
      return moment(value).fromNow()
    }
    switch (fieldName) {
      case 'row_count_srt':
        return formatNumber(value)
      case 'last_used_srt':
      case 'created_at_srt':
      case 'last_updated_srt':
        return moment(value).fromNow();
      case 'size_mb_srt':
        return formatNumber(value)
      default:
        return value;
    }
  }

  const formatHighilghtField = (txt) => {
    if (txt.includes('_kc_txt')) return txt.split('_kc_txt')[0];
    return txt.split('_txt')[0]
  }

  let objectLabel = mapSearchObjectName(object[objectTypeFieldName]);
  if (object[objectTypeFieldName] === 'CODE') objectLabel = object.code_type_txt;

  let highlightArr = [];
  let highlightObj = highlights?.[object.id]
  if (highlightObj) {
    Object.keys(highlightObj).forEach(el => {
      highlightArr.push({ name: `${formatHighilghtField(el)}`, originalValues: highlightObj[el], values: highlightObj[el].map(el => el.replaceAll('<em>', '').replaceAll('</em>', '')) })
    })
  }

  const processValues = (values, field) => {
    return values.join(', ');
  }

  const getLabel = object => {
    if (indexName === 'impact') return 'user'
    return getIconLabel({ label: object[objectTypeFieldName], item: object })
  }

  const ellipsisStr = (start = '', end = '', max) => {
    if (start.length < max && end.length < max) return [start, end];
    if (start.length < max) {
      if (end.length > max + max - start.length) return [start, end.slice(0, max + max - start.length) + '...']
      return [start, end]
    }
    if (end.length < max) {
      if (start.length > max + max - end.length) return ['...' + start.slice(-(max + max - end.length)), end]
      return [start, end]
    }
    let formattedStart = start.length > max ? '...' + start.slice(-max) : start;
    let formattedEnd = end.length > max ? end.slice(0, max) + '...' : end;
    return [formattedStart, formattedEnd]
  }

  const constructHighlightStr = highlightObj => {

    let highlightTxt = highlightObj.originalValues[0];
    let highlightStrArr = []
    const processHighightStr = str => {
      let splitted = str.split('<em>')
      highlightStrArr.push({ isHighlight: false, value: splitted[0] })
      if (splitted.length === 1) return;
      highlightStrArr.push({ isHighlight: true, value: splitted[1].split('</em>')[0] })
      let newStr = str.split('</em>').slice(1).join('</em>')
      processHighightStr(newStr)
    }
    processHighightStr(highlightTxt)

    let start = '';
    let highlight = '';
    let end = ''

    const constructStart = index => {
      if (index > highlightStrArr.length - 1) return;
      if (highlightStrArr[index].isHighlight && highlightStrArr[index].value.trim() !== '') return;
      start += highlightStrArr[index].value
      constructStart(index + 1)
    }
    constructStart(0)

    const constructHighlight = (index, hasFound) => {
      if (index > highlightStrArr.length - 1) return;
      if (!highlightStrArr[index].isHighlight && highlightStrArr[index].value.trim() !== '' && hasFound) {
        end = highlightStrArr.slice(index).map(el => el.value).join('')
        return;
      }
      if (highlightStrArr[index].isHighlight || highlightStrArr[index].value.trim() === '') {
        highlight += highlightStrArr[index].value;
        constructHighlight(index + 1, true)
      } else {
        constructHighlight(index + 1, false)
      }
    }
    constructHighlight(0, false);

    let nameWidth = getNameWidth(columnFileds)
    let maxChar = 40;
    if (nameWidth > 250) maxChar = 90;
    if (nameWidth > 450) maxChar = 160;
    if (nameWidth > 600) maxChar = 230;
    [start, end] = ellipsisStr(start, end, maxChar)

    return { start, end, highlight }
  }

  const generateDescription = () => {
    if (indexName === 'data_quality_test_result') {
      let { secondaryName } = formatBusinessnName({ roles, dispTitle: object.data_quality_test_name_txt, businessName: object.data_quality_alternate_name_txt, item: object, returnCustomPayload: true })
      if (secondaryName) {
        <Typography className={classes.description}>
          {secondaryName}
        </Typography>
      }
      return;
    }
    let description = object.description;
    if (!description || description === '') return <Typography className={classes.description}>No description provided</Typography>
    let descriptionHighlight = highlightArr.find(el => el.name === 'description');
    if (descriptionHighlight) {
      let { start, end, highlight } = constructHighlightStr(descriptionHighlight)
      return (
        <Typography className={classes.description}><span style={{ fontWeight: 700 }}>Description:</span> {start}<span className={classes.highlightText}>{highlight}</span>{end}</Typography>
      )
    }
    let nameWidth = getNameWidth(columnFileds)
    let maxChar = 172;
    if (nameWidth > 250) maxChar = 250;
    if (nameWidth > 450) maxChar = 350;
    if (nameWidth > 600) maxChar = 450;
    if (description.length > maxChar) description = description.slice(0, maxChar) + '...'
    return <Typography className={classes.description}><span style={{ fontWeight: 700 }}>Description:</span> {description}</Typography>
  }

  const generateOtherDescription = () => {
    let otherDescription = object.other_descriptions_txt;
    if (!otherDescription || otherDescription === '') return <></>
    let otherDescriptionHighlight = highlightArr.find(el => el.name === "other_descriptions");
    if (otherDescriptionHighlight) {
      let { start, end, highlight } = constructHighlightStr(otherDescriptionHighlight)
      return (
        <Typography className={classes.description}><span style={{ fontWeight: 700 }}>Other details:</span> {start}<span className={classes.highlightText}>{highlight}</span>{end}</Typography>
      )
    }
    return <></>
  }

  const generateSubtitle = () => {
    let locationHighlight = highlightArr.find(el => el.name.includes('location'));
    if (locationHighlight) {
      let highlightTxt = locationHighlight.originalValues[0];
      let highlightStrArr = []
      const processHighightStr = str => {
        let splitted = str.split('<em>')
        highlightStrArr.push({ isHighlight: false, value: splitted[0] })
        if (splitted.length === 1) return;
        highlightStrArr.push({ isHighlight: true, value: splitted[1].split('</em>')[0] })
        let newStr = str.split('</em>').slice(1).join('</em>')
        processHighightStr(newStr)
      }
      processHighightStr(highlightTxt)

      let start = '';
      let highlight = '';
      let end = ''

      const constructStart = index => {
        if (index > highlightStrArr.length - 1) return;
        if (highlightStrArr[index].isHighlight && highlightStrArr[index].value.trim() !== '') return;
        start += highlightStrArr[index].value
        constructStart(index + 1)
      }
      constructStart(0)

      const constructHighlight = (index, hasFound) => {
        if (index > highlightStrArr.length - 1) return;
        if (!highlightStrArr[index].isHighlight && highlightStrArr[index].value.trim() !== '' && hasFound) {
          end = highlightStrArr.slice(index).map(el => el.value).join('')
          return;
        }
        if (highlightStrArr[index].isHighlight || highlightStrArr[index].value.trim() === '') {
          highlight += highlightStrArr[index].value;
          constructHighlight(index + 1, true)
        } else {
          constructHighlight(index + 1, false)
        }
      }
      constructHighlight(0, false);

      [start, end] = ellipsisStr(start, end, 16)
      return <KTooltip title={object.location_txt}><Typography className={classes.subTitle}>{start}<span className={classes.highlightText}>{highlight}</span>{end}</Typography></KTooltip>
    }
    if (!object.location_txt) return <div style={{ height: 12, marginBottom: 4 }}></div>
    let text = object.location_txt;
    if (['TABLE', 'COLUMN'].includes(object.object_type_txt)) text = getDispFields(object, 'dispSubtitle')
    return <KTooltip title={text}><Typography className={classes.subTitle}>{text}</Typography></KTooltip>
  }


  const generateChildCountChip = item => {
    if (!['DATABASE', 'SCHEMA', 'TABLE', 'SOURCE', 'CONTENT_APP', 'TOOL', 'REPORT', 'DATASET', 'DATASET_TABLE', 'WORKSPACE', 'DATA_PIPELINE'].includes(item[objectTypeFieldName])) return <></>
    if (isNaN(item.child_object_count_srt)) return <></>
    if (item.child_object_count_srt === 0) return <></>
    return (
      <div className={classes.tag} style={{ borderColor: theme.palette.primary.dark }}>
        {item.child_object_count_srt} {getItemChildType(item, true).toUpperCase()}(S)
      </div>
    )
  }

  const getSimplifiedText = ({ colour, text }) => {
    return (
      <span style={{ marginLeft: 4, fontSize: 12, color: colour, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{text}</span>
    )
  }

  const getSimplifiedChip = ({ fontColour, icon, backgroundColour, text }) => {
    if (!icon) return <span style={{ marginLeft: 4, fontSize: 12, color: fontColour, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{text}</span>
    return (
      <div style={{ display: 'flex', border: `1px solid ${theme.palette.border.main}`, overflow: 'hidden', height: 24, alignItems: 'center', borderRadius: 12, margin: '0px 8px 0px 4px', background: backgroundColour, }}>
        {icon}
        <span style={{ marginLeft: 4, fontSize: 12, color: fontColour, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{text}</span>
      </div>
    )
  }

  const getColumnContent = el => {
    let width = getColumnWidth(el)
    let flexGrow = isColumnExtendable(el) ? 1 : 0
    el = getTxtField({ object, field: el, indexName })
    if (['active_lineage_inc_reference_txt', 'active_lineage_txt', 'lineage_inc_reference_txt', 'lineage_txt'].includes(el)) {
      return (
        <div className={classes.columnBoxes} style={{ width, flexGrow }}>
          <KTooltip title={formatColumnText(object[el], el)}>
            <div className='flex w-max max-w-[100%]'>
              {
                getSimplifiedChip({
                  colour: theme.palette.primaryText.main,
                  backgroundColour: theme.palette.chip.main,
                  text: object[el] && object[el] !== 'NONE' ? formatLineageText(object[el]) : '',
                  icon: object[el] && object[el] !== 'NONE' ?
                    <div style={{ flexShrink: 0, width: 16, height: 16, marginRight: 0 }}>{getLineageIcon({ item: object, colour: theme.palette.primaryText.main, size: 16, forceField: el })}</div>
                    : undefined,
                })
              }
            </div>
          </KTooltip>
        </div>
      )
    }

    let additionalStyle = {}
    if (resultItemVariant === 'simplified') {
      additionalStyle.fontSize = 12;
      if (el === 'trust') {
        return (
          <div className={classes.columnBoxes} style={{ width, flexGrow }}>
            <KTooltip title={formatColumnText(object[el], el)}>
              <div className='flex w-max max-w-[100%]'>
                {
                  getSimplifiedText({
                    colour: getTrustScoreColor(object.trust_srt),
                    text: object.trust
                  })
                }
              </div>
            </KTooltip>
          </div>
        )
      }
      if (el === 'dq_score_overall_srt') {
        return (
          <div className={classes.columnBoxes} style={{ width, flexGrow }}>
            <KTooltip title={formatColumnText(object[el], el)}>
              <div className='flex w-max max-w-[100%]'>
                {
                  getSimplifiedText({
                    colour: getTestScoreColor(object.dq_score_overall_srt, true),
                    text: object.dq_score_overall_srt
                  })
                }
              </div>
            </KTooltip>
          </div>
        )
      }
      if (el === 'last_data_quality_score_txt') {
        return (
          <div className={classes.columnBoxes} style={{ width, flexGrow }}>
            <KTooltip title={formatColumnText(object[el], el)}>
              <div className='flex w-max max-w-[100%]'>
                {
                  getSimplifiedText({
                    colour: getTestScoreColor(object.last_data_quality_score_txt, true),
                    text: object.last_data_quality_score_txt
                  })
                }
              </div>
            </KTooltip>
          </div>
        )
      }
      if (el === 'verified_kc_txts') {
        return (
          <div className={classes.columnBoxes} style={{ width, flexGrow }}>
            <KTooltip title={formatColumnText(object[el], el)}>
              <div className='flex w-max max-w-[100%]'>
                {
                  getSimplifiedText({
                    colour: object[el] ? '#00D46A' : '#D0021B',
                    text: object[el] ? 'YES' : 'NO'
                  })
                }
              </div>
            </KTooltip>
          </div>
        )
      }
      if (el === 'documented_txt') {
        return (
          <div className={classes.columnBoxes} style={{ width, flexGrow }}>
            <KTooltip title={formatColumnText(object[el], el)}>
              <div className='flex w-max max-w-[100%]'>
                {
                  getSimplifiedText({
                    colour: object[el] === 'IS_DOCUMENTED' ? '#00D46A' : '#D0021B',
                    text: object[el] === 'IS_DOCUMENTED' ? 'YES' : 'NO'
                  })
                }
              </div>
            </KTooltip>
          </div>
        )
      }
    }
    if (el === 'trust') {
      additionalStyle = { color: getTrustScoreColor(object.trust_srt) }
    }
    if (el === 'documented_txt') {
      additionalStyle = { color: object[el] === 'IS_DOCUMENTED' ? '#00D46A' : '#D0021B' }
    }
    if (el === 'dq_score_overall_srt') {
      additionalStyle = { color: getTestScoreColor(object.dq_score_overall_srt, true) }
    }
    if (isColumnExtendable(el)) {
      additionalStyle.flexGrow = 1
    }
    return (
      <div className={classes.columnBoxes} style={{ width, flexGrow, ...additionalStyle }}>
        <KTooltip title={formatColumnText(object[el], el)}>
          <div className='flex w-max max-w-[100%]'>
            {formatColumnText(object[el], el).length > 300 ? formatColumnText(object[el], el).slice(0, 300) + '...' : formatColumnText(object[el], el)}
          </div>
        </KTooltip>
      </div>
    )
  }

  return (
    <div style={{ minWidth: '100%', width: getListWidth(columnFileds) }}>
      <ContextMenuTrigger id={object.id}>
        <div
          data-test-classname="search-result-item"
          className={classes.root + ' ' + (checkCanAddToCart({ sessionData, objectType: state.selectedObjectType }) && !hiddenComponents.includes(searchComponentNameMap.cartButton) ? selectingAll ? classes.disabledCartItem : classes.cartItem : '')}
          onClick={() => {
            if (forceOnItemClick) {
              forceOnItemClick(object)
              return;
            }
            if (indexName === 'search') onClickResultItem({ label: objectLabel, id: object.id, history, item: object, newWindow: alwaysOpenNewTab ? true : false })
            if (indexName === 'data_quality_test_result') onClickResultItem({ item: object, id: object.data_quality_test_id_txt, label: 'data_quality_test', newWindow: alwaysOpenNewTab ? true : false })
          }}
          style={{
            ...(resultItemVariant === 'simplified') ? { minHeight: 36 } : {}
          }}
        >
          <div className={classes.iconWrapper}>
            {
              checkCanAddToCart({ sessionData, objectType: state.selectedObjectType }) && !hiddenComponents.includes(searchComponentNameMap.cartButton) &&
              <Checkbox
                data-test-classname="select-checkbox"
                className='select-checkbox'
                style={{
                  width: state.cartItems.includes(object.id) ? 24 : undefined,
                  height: 24,
                  padding: 0,
                  color: state.cartItems.includes(object.id) ? theme.palette.secondary.main : theme.palette.primaryText.light,
                  float: 'left',
                  overflow: 'hidden'
                }}
                disabled={selectingAll}
                checked={state.cartItems.includes(object.id)}
                onClick={event => {
                  event.stopPropagation();
                  if (state.cartItems.includes(object.id)) {
                    dispatch({ type: 'set_cart_items', cartItems: state.cartItems.filter(el => el !== object.id) })
                    dispatch({ type: 'set_is_select_all', isSelectAll: false })
                  } else {
                    // if(state.cartItems.length>=1000){
                    //   sendAlert({type:'info',message:`A limit of 1000 can be selected at a time. You can not select more items`})
                    //   return;
                    // }
                    dispatch({ type: 'set_cart_items', cartItems: [...state.cartItems, object.id] })
                  }
                }}
              />
            }
            <div
              style={{
                width: state.cartItems.includes(object.id) ? 0 : undefined,
                height: 24,
                overflow: state.cartItems.includes(object.id) ? 'hidden' : undefined,
              }}
              className='item-icon'
            >
              {
                addBadgeToIcon({
                  icon: getIconComponent({ label: getLabel(object), size: 24, colour: theme.palette.primary.main }),
                  modification_badge: object.modification_badge_txt,
                  active: object.active_txt || object.active_flag,
                  obj: object
                })
              }
            </div>
          </div>
          <div className={classes.mainContentContainer} style={{ width: getNameWidth(columnFileds) }}>
            <div className={classes.mainContent}>
              <div className={classes.title} data-test-classname="search-result-name">
                {generateName()}
              </div>
              {generateSubtitle()}
              {
                resultItemVariant !== 'simplified' &&
                <>
                  {generateDescription()}
                  {generateOtherDescription()}
                  <div>
                    {
                      highlightArr.length > 0 &&
                      <div className={classes.highlightChipsContainer}>
                        {
                          highlightArr.filter(el => !['location', 'description'].some(f => el.name.includes(f))).length > 0 &&
                          <span style={{ marginTop: 8, fontSize: 13.75, color: theme.palette.primaryText.main, marginRight: 6 }}>Matches:</span>
                        }
                        {
                          highlightArr.filter(el => !['location', 'description'].some(f => el.name.includes(f))).map(el => (
                            <div className={classes.highlightChip}>
                              <KTooltip title={`${el.name}: ${processValues(el.values, el.name)}`}>
                                <Typography className={classes.highlightValueTxt}>{el.name}: {processValues(el.values, el.name)}</Typography>
                              </KTooltip>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </div>
                  <div className={classes.tags}>
                    {
                      generateChildCountChip(object)
                    }
                    {
                      object.warning_notice_kc_txts?.length > 0 &&
                      <div className={classes.tag} style={{ borderColor: theme.palette.warning.main }}>
                        WARNING
                      </div>
                    }
                    {
                      processCollection({ category_kc_txts: object.category_kc_txts }, 'manual', 'resultItem', 'solr').tags.map(el => (
                        <div className={classes.tag} style={{ borderColor: el.colour }}>
                          {el.name}
                        </div>
                      ))
                    }
                    {
                      object.primary_key_txt === 'PRIMARY_KEY' &&
                      <div className={classes.tag} style={{ borderColor: '#b8d2ff' }}>PRIMARY KEY</div>
                    }
                    {
                      object.foreign_key_txt === 'FOREIGN_KEY' &&
                      <div className={classes.tag} style={{ borderColor: '#b8d2ff' }}>FOREIGN KEY</div>
                    }
                    {
                      object.masked_txt === 'YES' &&
                      <div className={classes.tag} style={{ borderColor: '#D8D8D8' }}>MASKED</div>
                    }
                    {
                      !isNaN(object.number_of_dq_tests_srt) && object.number_of_dq_tests_srt > 0 && object[objectTypeFieldName] !== 'DATA_QUALITY_TEST' &&
                      <div className={classes.tag} style={{ borderColor: '#00D46A' }}>DQ TESTED</div>
                    }
                    {
                      object.data_quality_failed_txt === 'YES' &&
                      <div className={classes.tag} style={{ borderColor: '#F44137' }}>DQ TEST FAILED</div>
                    }
                    {
                      object.domain_kc_txts &&
                      <div className={classes.tag} style={{ borderColor: 'var(--color-primary-dark)' }}>DOMAIN:{` ${getDomainText(object.domain_kc_txts)}`}</div>
                    }
                    {
                      object.verified_kc_txts &&
                      <div className={classes.tag} style={{ borderColor: '#00D46A' }}>{'VERIFIED'}</div>
                    }
                    {
                      processCollection(object, 'auto', 'resultItem', 'solr').tags.filter(el => el.label !== 'usage').slice(0, 4).map(el => (
                        <div className={classes.tag} style={{ borderColor: el.colour }}>
                          {el.name}
                        </div>
                      ))
                    }
                    {
                      processCollection(object, 'auto', 'resultItem', 'solr').tags.length > 4 &&
                      <div className={classes.tag} style={{ borderColor: '#FFAB00' }}>
                        +{processCollection(object, 'auto', 'resultItem', 'solr').tags.length - 4}
                      </div>
                    }
                    {
                      processCollection({ ...object, category_kc_txts: undefined }, 'manual', 'resultItem', 'solr').tags.slice(0, 4).map(el => (
                        <div className={classes.tag} style={{ borderColor: el.colour }}>
                          {el.name}
                        </div>
                      ))
                    }
                    {
                      object.tags_txts && object.tags_txts.sort().slice(0, 4).map(el => (
                        <div className={classes.tag} style={{ borderColor: theme.palette.primary.light }}>
                          {el.length > 16 ? el.slice(0, 16) + '...' : el}
                        </div>
                      ))
                    }
                    {
                      object.tags_txts && object.tags_txts.length > 4 &&
                      <div className={classes.tag} style={{ borderColor: theme.palette.primary.light }}>
                        +{object.tags_txts.length - 2}
                      </div>
                    }
                  </div>
                </>
              }
            </div>
          </div>
          {
            columnFileds.map(getColumnContent)
          }
        </div>
      </ContextMenuTrigger>
      {
        indexName === 'search' &&
        <ContextMenu id={object.id}>
          <CustomMenu
            item={{ ...object, labels: objectLabel, id: object.id }}
            actions={[
              'open_new_tab',
              'bookmark'
            ]}
          />
        </ContextMenu>
      }
      {
        indexName === 'impact' &&
        <ContextMenu id={object.id}>
          <CustomMenu
            item={{ ...object, labels: object[objectTypeFieldName], id: object.object_id }}
            actions={[
              'open_new_tab',
              'bookmark'
            ]}
          />
        </ContextMenu>
      }
    </div>
  )
}

export default withStyles(styles)(ResultItem);
