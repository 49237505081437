import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, withStyles, Paper, InputBase, Modal, IconButton, CircularProgress } from '@material-ui/core';
import { getIconComponent } from '../../../../utilities'
import theme from '../../../../theme';
import Filter from '../Filter/Filter';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import RangeFilter from '../Filter/RangeFilter';
import { rangeFilterFileds, searchComponentNameMap } from '../Utils/Utils';
import DateFilter from '../Filter/DateFilter';
import { BasicSearchContext } from '../../../../containers/BasicSearch/BasicSearch';
import { KadaBtn, KadaIconBtn, kButtonVariants } from 'kada-component-library';

const styles = theme => ({
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputBase: {
    ...theme.components.inputBase,
    // width:'100%',
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: 16,
    '& input': {
      paddingLeft: 24
    }
  },
  modalHeader: {
    fontSize: 20,
    color: theme.palette.header.main
  },
  sectionHeader: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginBottom: 8,
    whiteSpace: 'pre'
  },
  customScroll: {
    ...theme.components.hideScroll,
    // '&::-webkit-scrollbar':{
    //   width: '0px'
    // },
  },
  filterListItem: {
    width: 200,
    height: 48,
    fontSize: 13.75,
    paddingLeft: 16,
    paddingRight: 8,
    borderRight: `3px solid ${theme.palette.primaryText.light}60`,
    color: theme.palette.primaryText.main,
    display: 'flex',
    alignItems: 'center',
    // justifyContent:'space-between',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.hovered.main,
    }
  },
  selectedListItem: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  listItemText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  filterIcon: {
    padding: 4,
    marginRight: 4,
    marginLeft: -12,
    '& .filter_check_icon': {
      display: 'flex'
    },
    '& .filter_clear_icon': {
      display: 'none'
    },
    '&:hover': {
      '& .filter_check_icon': {
        display: 'none'
      },
      '& .filter_clear_icon': {
        display: 'flex'
      }
    }
  }
});



const FilterList = props => {

  const {
    classes,
  } = props;

  const {
    state,
    dispatch,
    onUpdateFilter,
    onResetAllFilters,
    getDefaultParams,
    hiddenComponents,
    addtionalComponents,
    indexName,
    forceGlobalFilterStr,
    forceGlobalQueryStr,
    loadSearchResults,
    joinFqs,
    joinVarParams
  } = useContext(BasicSearchContext);

  // const [anchor, setAnchor] = useState();
  const [addFilterPopperOpen, setAddFilterPopperopen] = useState(false);
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [addFilterSelected, setAddFilterSelected] = useState(-1)
  // const [selectedFilters, setSelectedFilters] = useState(state.allFilters.map(el=>el.field).slice(0,5))
  const selectedFilters = state.selectedFilters;
  const setSelectedFilters = filters => dispatch({ type: 'set_selected_filters', selectedFilters: filters })

  useEffect(() => {
    if (addFilterPopperOpen) setFilterSearchValue('');
    else { setAddFilterSelected(-1) }
  }, [addFilterPopperOpen])

  const onClickAddFilter = event => {
    // setAnchor(event.currentTarget);
    if (!addFilterPopperOpen) setAddFilterPopperopen(true);
  }

  const onSelectFilter = fieldName => {
    if (selectedFilters.includes(fieldName)) {
      setSelectedFilters(selectedFilters.filter(f => f !== fieldName))
      setAddFilterSelected();
      onUpdateFilter({ updatedFieldName: fieldName, updatedSelected: [] })
    } else {
      setSelectedFilters([...selectedFilters, fieldName])
      setAddFilterSelected(fieldName);
    }
  }

  const onClickFilterListItem = el => {
    onSelectFilter(el.field)
  }

  let filterWidth = 180;
  // if(rootWidth>=1720)filterWidth = 200;
  // if(rootWidth>=2360)filterWidth = 270;

  const onSetNegativeFilter = filterName => {
    let newNegFiters = []
    if (state.negativeFilters.includes(filterName)) {
      newNegFiters = state.negativeFilters.filter(f => f !== filterName)
      dispatch({ type: 'set_negative_filters', negativeFilters: newNegFiters })
    }
    else {
      newNegFiters = [...state.negativeFilters, filterName]
      dispatch({ type: 'set_negative_filters', negativeFilters: newNegFiters })
    }
    return newNegFiters;
  }

  const onSetAndFilter = filterName => {
    let newAndFilters = []
    if (state.andFilters.includes(filterName)) {
      newAndFilters = state.andFilters.filter(f => f !== filterName)
      dispatch({ type: 'set_and_filters', andFilters: newAndFilters })
    }
    else {
      newAndFilters = [...state.andFilters, filterName]
      dispatch({ type: 'set_and_filters', andFilters: newAndFilters })
    }
    return newAndFilters;
  }

  const getFilterComponent = (fieldName, props) => {
    if (rangeFilterFileds.includes(fieldName)) {
      return <RangeFilter {...props} />
    }
    // if field type is pdate
    if (state.allFilters.find(f => f.field === fieldName).type === 'pdate') {
      return <DateFilter {...props} />
    }
    return <Filter {...props} />
  }

  const onAddColumn = fieldName => {
    if (!state.selectedColumns.includes(fieldName)) {
      let columns = [fieldName, ...state.selectedColumns]
      dispatch({ type: 'set_selected_columns', selectedColumns: columns })
      loadSearchResults({ page: 1, isReload: true, selectedColumns: columns })
    }
  }

  return (
    <>
      <>
        {
          !state.hideFilter && selectedFilters.map(el => state.allFilters.find(f => f.field === el)).filter(el => el).map(el => {
            return getFilterComponent(
              el.field,
              {
                state,
                dispatch,
                isNegative: state.negativeFilters.includes(el.field),
                onSetNegative: () => onSetNegativeFilter(el.field),
                isAnd: state.andFilters.includes(el.field),
                onSetAnd: () => onSetAndFilter(el.field),
                searchPlaceholder: el.placeholder,
                fieldName: el.field,
                type: el.type,
                name: el.name,
                onUpdateFilter,
                width: filterWidth,
                getDefaultParams,
                indexName,
                forceGlobalFilterStr,
                joinFqs,
                joinVarParams,
                forceGlobalQueryStr
              }
            )
          })
        }
        <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 12 }}>
          {
            !state.hideFilter && !hiddenComponents.includes(searchComponentNameMap.filterEdit) &&
            <KadaIconBtn
              iconName='filter_list'
              iconColor='var(--color-light-text)'
              size={32}
              onClick={onClickAddFilter}
            />
          }
          {
            !state.hideFilter && !hiddenComponents.includes(searchComponentNameMap.filterReset) && !state.hideFilter &&
            <KadaBtn
              id="search-reset-filter-button"
              text="RESET"
              variant={kButtonVariants.secondaryBorderless}
              onClick={onResetAllFilters}
              size='s'
            />
          }
          {
            addtionalComponents.includes('filterHide') &&
            <KadaBtn
              id="search-reset-filter-button"
              text={`${state.hideFilter ? 'SHOW' : 'HIDE'} FILTERS`}
              variant={kButtonVariants.primaryBorderless}
              size='s'
              onClick={() => dispatch({ type: 'set_hide_filter', hideFilter: !state.hideFilter })}
            />
          }
        </div>
        <Modal open={addFilterPopperOpen} disableBackdropClick>
          <Paper style={{ padding: 24, width: 'max-content', border: `1px solid ${theme.palette.border.main}`, background: theme.palette.background.main, margin: 'auto', marginTop: '15vh' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={classes.modalHeader}>Add Filters</Typography>
              <Button variant="outlined" color='primary' onClick={() => setAddFilterPopperopen(false)}>CLOSE</Button>
            </div>

            <div style={{ height: '45vh', width: 700, marginTop: 16, display: 'flex', overflow: 'auto' }}>
              <div style={{ flex: '0 0 200px', marginRight: 24, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <Typography className={classes.sectionHeader}>Filters</Typography>
                <InputBase
                  className={classes.inputBase}
                  value={filterSearchValue}
                  onChange={event => setFilterSearchValue(event.target.value)}
                  placeholder="Search Filters"
                  endAdornment={
                    <IconButton style={{ padding: 8, marginRight: 4 }} disabled={filterSearchValue === ''} onClick={() => { setFilterSearchValue('') }}>
                      {
                        filterSearchValue === '' ?
                          getIconComponent({ label: 'search', colour: theme.palette.primaryText.light, size: 24 }) :
                          getIconComponent({ label: 'clear', colour: theme.palette.primaryText.light, size: 24 })
                      }
                    </IconButton>
                  }
                />
                <div style={{ overflow: 'auto', flexGrow: 1 }} className={classes.customScroll}>
                  {
                    (state.allFiltersLoading || state.availableFiltersLoading) &&
                    <CircularProgress color='secondary' />
                  }
                  {
                    state.allFilters.filter(el => el.field !== 'name_txt').filter(el => (state.availableFilters && state.availableFilters.includes(el.field)) && el.name.toLowerCase().includes(filterSearchValue.toLowerCase())).map(el => (
                      <div
                        data-test-classname="search-edit-filter-button"
                        className={classes.filterListItem + (selectedFilters.includes(el.field) ? ' ' + classes.selectedListItem : '')}
                        onClick={() => {
                          selectedFilters.includes(el.field) ? setAddFilterSelected(el.field) : onClickFilterListItem(el);
                          dispatch({ type: 'set_is_filter_or_column_changed', isFilterOrColumnChanged: true })
                          onAddColumn(el.field);
                        }}
                      >
                        {
                          selectedFilters.includes(el.field) &&
                          <KTooltip title="Unselect filter">
                            <IconButton
                              className={classes.filterIcon}
                              onClick={(event) => {
                                event.stopPropagation();
                                onUpdateFilter({ updatedFieldName: el.field, updatedSelected: [] });
                                setSelectedFilters(selectedFilters.filter(f => f !== el.field));
                                setAddFilterSelected(-1);
                              }}
                            >
                              <div className="filter_check_icon" style={{ height: 24, width: 24, alignItems: 'center' }}>{getIconComponent({ label: 'checked_contained', size: 20, colour: theme.palette.primary.main })}</div>
                              <div className="filter_clear_icon" style={{ height: 24, width: 24, alignItems: 'center' }}>{getIconComponent({ label: 'clear_circle', size: 20, colour: theme.palette.error.main })}</div>
                            </IconButton>
                          </KTooltip>
                        }
                        <KTooltip title={`${el.name}`}>
                          <span className={classes.listItemText} style={{ flexGrow: 1 }}>
                            {el.name}
                          </span>
                        </KTooltip>
                        {el.field.includes('_kc_txts') && <span style={{ display: 'inline-block', padding: '4px 8px', marginLeft: 8, fontSize: 12, borderRadius: 13, background: theme.palette.chip.main, color: theme.palette.primaryText.main, border: `1px solid ${theme.palette.border.main}` }}>Collection</span>}

                      </div>
                    ))
                  }
                  {
                    !(state.allFiltersLoading || state.availableFiltersLoading) && state.allFilters.filter(el => (state.availableFilters && state.availableFilters.includes(el.field)) && el.name.toLowerCase().includes(filterSearchValue.toLowerCase())).length === 0 &&
                    <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.main }}>No filter found</Typography>
                  }
                </div>
              </div>
              <div key={addFilterSelected} style={{ flex: '1 1', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <Typography className={classes.sectionHeader}>
                  {
                    addFilterSelected && state.allFilters.find(f => f.field === addFilterSelected) ?
                      `${state.allFilters.find(f => f.field === addFilterSelected).name}${state.allFilters.find(f => f.field === addFilterSelected).field.includes('_kc_txts') ? ' (Collection)' : ""}`
                      :
                      'Select a filter to add'
                  }
                </Typography>
                {
                  addFilterSelected && state.allFilters.find(f => f.field === addFilterSelected) &&
                  getFilterComponent(
                    state.allFilters.find(f => f.field === addFilterSelected).field,
                    {
                      state,
                      dispatch,
                      isNegative: state.negativeFilters.includes(state.allFilters.find(f => f.field === addFilterSelected).field),
                      onSetNegative: () => onSetNegativeFilter(state.allFilters.find(f => f.field === addFilterSelected).field),
                      isAnd: state.andFilters.includes(state.allFilters.find(f => f.field === addFilterSelected).field),
                      onSetAnd: () => onSetAndFilter(state.allFilters.find(f => f.field === addFilterSelected).field),
                      searchPlaceholder: state.allFilters.find(f => f.field === addFilterSelected).placeholder,
                      fieldName: state.allFilters.find(f => f.field === addFilterSelected).field,
                      type: state.allFilters.find(f => f.field === addFilterSelected).type,
                      name: state.allFilters.find(f => f.field === addFilterSelected).name,
                      onUpdateFilter,
                      width: filterWidth,
                      getDefaultParams,
                      bodyOnly: true,
                      indexName,
                      forceGlobalFilterStr,
                      joinFqs,
                      joinVarParams,
                      forceGlobalQueryStr
                    }
                  )
                }
              </div>
            </div>
          </Paper>
        </Modal>
      </>
    </>
  )
}

export default withStyles(styles)(FilterList);
