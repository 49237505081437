import React from 'react';
import { KadaInput } from 'kada-component-library';
import 'url-search-params-polyfill';
const SetDetails = props => {

  const {
    config,
    setConfig,
  } = props;

  return (
    <table>
      <tbody>
        <tr style={{ verticalAlign: 'top', height: '100px' }}>
          <td className="w-50">
            <span className="mt-3 inline-block">
              Depth Limit
            </span>
          </td>
          <td className="w-100">
            <KadaInput
              value={config.assessment_limit || ''}
              onInput={e=>{
                setConfig({
                  ...config,
                  assessment_limit: e.detail.value
                })
              }}
              placeholder='Enter depth limit'
              required
              type="number"
            />
          </td>
        </tr>
        <tr style={{ verticalAlign: 'top', height: '100px' }}>
          <td className="w-50">
            <span className="mt-3 inline-block">
              Expansion Limit
            </span>
          </td>
          <td className="w-100">
            <KadaInput
              value={config.expansion_limit || ''}
              onInput={e=>{
                setConfig({
                  ...config,
                  expansion_limit: e.detail.value
                })
              }}
              placeholder='Enter depth limit'
              required
              type="number"
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default SetDetails;
